import React, {useState,useEffect} from 'react';
import axios from "axios";
import dayjs from "dayjs";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom';
import Header from '../../component/layout/header';
import Sidebar from '../../component/layout/sidebar';
import Footer from '../../component/layout/footer';
import Spinner from 'react-bootstrap/Spinner';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";

function Profile (){
  if (!localStorage.getItem('sl_med_tokenize')) {
    console.log('no auth token set');
    window.location.href="/";
  } else {
        console.log('good token. Log in.')
    }
  const history = useHistory();
  const MySwal = withReactContent(Swal)
  const [my_info, setinfo] = useState([]);
  const [my_invoices, setinvoices] = useState([]);
  const [my_consultations, setconsultation] = useState([]);
  
  const token = localStorage.getItem('sl_med_tokenize');
  const first_name = localStorage.getItem('sl_med_fname');
  // const myURL = "http://localhost:9800/api/fetchbyid";
  const myURL = "https://remitaid.starlightsoftware.co.bw/api/fetchbyid";
  const deleteURL = "https://remitaid.starlightsoftware.co.bw/api/deleteConsultationById";
  // const deleteURL = "http://localhost:9550/api/deleteConsultationById";

  const headers2 = { 
    headers: {
      'AccessToken': JSON.parse(token),
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': "*",
    }
  };
  const urlParams = new URLSearchParams(window.location.search);
  const myidd = urlParams.get('myidd');
  const business_name = localStorage.getItem('sl_med_businessname');
  console.log(business_name);
  const doctor_id = localStorage.getItem("sl_med_id");
  const goback = (e) => {
    window.location.href = 'allpatient'
  }

    const body = {
      'doctor_id':doctor_id,
      'accesstoken': JSON.parse(token),
      'id': myidd,
    }

  const getmyinfo = async (e) => {
    await axios.post(myURL, body, headers2)
    .then((response) => {
      console.log("response received: ", response);
      if(!response){
        setinfo(0);
      }else{
      console.log("response received: ", response);
        console.log(response.data.data.consultations);
        setinfo(response.data.data.patient_data[0]);
        setinfo(response.data.data.patient_data[0]);
        setconsultation(response.data.data.consultations);
        setconsultation(response.data.data.consultations);
        setinvoices(response.data.data.invoices);
        setinvoices(response.data.data.invoices);
      }
    });
  };

  const viewInvoice = (selectedId) => {
    const selectedData = data.find((item) => item.id === selectedId);
    if (selectedData) {
      const selectedDataString = JSON.stringify(selectedData);
      localStorage.setItem('selectedData', selectedDataString);
      const nextPageUrl = '/viewinvoice2'; // Specify the URL of the next page
      window.location.href = nextPageUrl;
    } else {
      console.error(`Data for ID ${selectedId} not found`);
    }
}

  const gotoconsultation = (e) => {
    if(!e.target.id){
      // console.log('failed')
      return MySwal.fire('No ID for this request, please refresh your browser or log out.').then(() => {
      })
      }else{
    const newid = e.target.id
    // console.log(newid);
    const patients_name = my_info.firstname;
    const patients_number = my_info.patients_number;
    const patients_id = my_info.id;
    window.location.href = "consultation?myidd=" + newid + "&patients_name=" + patients_name + "&patients_number=" + patients_number+ "&patients_id=" + patients_id;
  }
}

const editpatient = (e) => {
  if(!myidd){
    // console.log('failed')
    return MySwal.fire('No ID for this request, please refresh your browser or log out.').then(() => {
    })
    }else{
  
  window.location.href = "editpatient?myidd=" + myidd;
}
}


  const handleconsultation = (e) => {
    if(!e.target.id){
      console.log('failed')
      return MySwal.fire('No ID for this request, please refresh your browser or log out.').then(() => {
      })
      }else{
    const myconsultation = e.target.id
    console.log(myconsultation);
  
    window.location.href = "viewconsultation?id=" + myconsultation;
  }
  }

  
  const handledelete = async (e) => {
    const deleteid = e.target.id;
    if (!deleteid) {
      console.log('failed');
      return MySwal.fire('No ID for this request, please refresh your browser or log out.');
    }
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    });
  
    if (result.isConfirmed) {
      // Proceed with deletion if user confirms
      const deletebody = {
      'consultationId': deleteid,
      'accessToken': JSON.parse(token),

      };
  
      try {
        MySwal.showLoading()
        const response = await axios.post(deleteURL, deletebody, headers2);
        console.log("response received: ", response);
        if (response) {
          Swal.close()
          Swal.fire({
            title: 'Successful',
            text: 'Consultation deleted successfully',
            icon: 'success',
            confirmButtonText: 'Okay',
          }).then(() => {
            window.location.reload(false);
          });
        } else {
          setinfo(0);
        }
      } catch (error) {
        console.error('Error deleting consultation:', error);
        Swal.fire({
          title: 'Error',
          text: 'Failed to delete consultation',
          icon: 'error',
          confirmButtonText: 'Okay',
        });
      }
    } else {
      // User canceled the deletion
      Swal.fire({
        title: 'Cancelled',
        text: 'Your consultation is safe',
        icon: 'info',
        confirmButtonText: 'Okay',
      });
    }
  };
  
  
    useEffect(() => {
      getmyinfo();
    }, []); 



  const columns = [
      {
        name: 'INVOICE DATE',
        selector: (row) => dayjs(row.created_at).format("DD MMM YY"),
        sortable: true,
        width: "12rem" ,
          cellExport: (row) => dayjs(row.created_at).format("DD MMM YY")
    },
    {
      name: 'INVOICE NUMBER',
      selector: (row) => row.invoice_number,
      sortable: true,
      width: '12rem',
      cellExport: row => row.invoice_number 

    },
  {
    name: 'INVOICE AMOUNT',
    selector: (row) => row.total_amount.toLocaleString('en-BW', { style: 'currency', currency: 'BWP' }),  
    sortable: true,
    width: "10rem" ,
   cellExport: row => row.total_amount 
},
    {
      name: 'ACTIONS',
      button: true,
      cell: (row) => <button onClick={() => viewInvoice(row.id)} id={row.id} data-patientid={row.patient_id} className="btn btn-sm btn-primary btn-active-color-primary">View</button>

    },
  
  ];
    
  const data=my_invoices;
  const tableData = {
    columns,
    data
  };





  const conscolumns = [
    {
      name: ' DATE',
      selector: (row) => dayjs(row.created_at).format("DD MMM YY"),
      sortable: true,
      width: "12rem",
      cellExport: (row) => dayjs(row.created_at).format("DD MMM YY")
    },
    {
      name: 'NOTES',
      selector: (row) => row.notes,
      sortable: true,
      width: '15rem',
      cellExport: (row) => row.notes
    },
    {
      name: 'PRESCRIPTION',
      selector: (row) => row.prescription_drug_1,
      sortable: true,
      width: "10rem",
      cellExport: (row) => row.prescription_drug_1
    },
    {
      name: 'ACTIONS',
      button: true,
      cell: (row) => <button onClick={handleconsultation} id={row.id} className="btn btn-primary">View</button>
    },
    {
      name: '',
      button: true,
      cell: (row) => <button onClick={handledelete} id={row.id} data-patientid={row.patient_id} className="btn btn-sm btn-danger btn-active-color-primary">Delete</button>

    }
  ];
  

  const dataC = my_consultations;
  const tableCData = {
    columns: conscolumns,
    data: dataC
  };



      return (
<div>
        <div className="header-container fixed-top">
        <Header/>
        </div>
        <div className="main-container" id="container">
        <Sidebar/>



          <div id="content" className="main-content">
        <div className="layout-px-spacing">
          <div className="page-header">
            <div className="page-title">
              <h3>Profile </h3> 
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<p ><button onClick={goback} style={{float: 'right !important'}} className="btn btn-small btn-danger"><i className='fa fa-chevron-left'></i> Go Back</button></p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<p ><button onClick={getmyinfo} style={{float: 'right !important'}} className="btn btn-small btn-info">COMPUTE TABLE</button></p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<p ><button onClick={gotoconsultation} style={{float: 'right !important'}} id={my_info.id} className="btn btn-small btn-primary">Start new Consultation</button></p>
          </div>
          <div className="row layout-spacing">
            {/* Content */}
            <div className="col-xl-4 col-lg-6 col-md-5 col-sm-12 layout-top-spacing">
              <div className="user-profile layout-spacing">
                <div className="widget-content widget-content-area">
                  <div className="d-flex justify-content-between">
                    <h3 className>Patient Information</h3>
                    <button onClick={editpatient} title='edit' className="mt-2 edit-profile"> <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit-3"><path d="M12 20h9" /><path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z" /></svg></button>
                  </div>
                  <br/>
                  <div className="text-center ">
                  <img src='assets/img/info.png' width={100}/>
                    <b><p>{my_info.firstname}</p></b>
                  </div>
                  <div className="user-info-list">
                    <div className>
                      <ul className="contacts-block list-unstyled">
                        <li className="contacts-block__item">
                          Patient Number:  <b>{my_info.patients_number}</b>
                        </li>
                        <li className="contacts-block__item">
                          DOB: <b>{my_info.dob}</b>
                        </li>
                        <li className="contacts-block__item">
                        Email: <b>{my_info.email}</b>
                        </li>
                        <li className="contacts-block__item">
                        Phone: <b>{my_info.phone}</b>
                        </li>
                        <li className="contacts-block__item">
                        Medical Aid: <b>
                          {
                          my_info.provider == 1 ? 
                          <span class="badge badge-danger fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">BOMAID</span>
                          : my_info.provider == 2 
                          ? <span class="badge badge-success fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">BPOMAS</span>
                          : my_info.provider == 3
                          ? <span class="badge badge-warning fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">PULA</span>
                          : my_info.provider == 4
                          ? <span class="badge badge-info fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">BOTSOGO</span>
                          : my_info.provider == 5 
                          ? <span class="badge badge-primary fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">BDF</span>
                          : my_info.provider == 6
                          ? <span class="badge badge-success fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">BPOMAS HIGH</span>
                          : my_info.provider == 7 
                          ? <span class="badge badge-success fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">BPOMAS STANDARD</span>
                          : my_info.provider == 8 
                          ? <span class="badge badge-warning fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">PULA DELUXE</span>
                          : my_info.provider == 9
                          ? <span class="badge badge-warning fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">PULA STANDARD</span>
                          : <span class="badge badge-success fw-semibold fs-9 px-2 ms-2 cursor-default ms-2"> </span>
                          }</b>
                        </li>
                        {/* <li className="contacts-block__item">
                          Principal Member: <b>{my_info.principal_member}</b>
                        </li> */}
                        <li className="contacts-block__item">
                          Membership Number: <b>{my_info.member_number}</b>
                        </li>
                        <li className="contacts-block__item">
                          Occupation: <b>{my_info.occupation}</b>
                        </li>
                       
                      </ul>
                    </div>                                    
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-8 col-lg-6 col-md-7 col-sm-12 layout-top-spacing">
          
               <div className="widget-content widget-content-area br-6">
               <div className="page-title">
              <h3>Past Invoices</h3>
            </div>
                <div className="table-responsive mb-4 mt-4">
                <table id="zero-config" className="table table-hover" style={{width: '100%'}}>
                  {my_invoices == null ? <Spinner animation="border" /> : ""}
                  <DataTableExtensions {...tableData}>
                                <DataTable className=""
                                  columns={columns}
                                  data={data}
                                  noHeader
                                  defaultSortField="id"
                                  defaultSortAsc={false}
                                  pagination
                                  exportHeaders= {true}
                                  highlightOnHover
                                />
                              </DataTableExtensions>
                  </table>
                </div>

                <div className="page-title">
              <h3>Past Consultations</h3>
            </div>
                <div className="table-responsive mb-4 mt-4">
                  {/* <table id="zero-config" className="table table-hover" style={{width: '100%'}}>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Notes</th>
                        <th>Drugs Prescribed</th>
                        <th className="no-content">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                    {Array.isArray(my_consultations) && my_consultations.length > 0 ? (
                        my_consultations.map((info, index) => (
                      <tr>
                       <td>{dayjs(info.created_at).format("DD MMM YY")}</td>
                        <td>{info.notes}</td>
                        <td>{info.prescription_drug_1}</td>
                        <td>
                     <button onClick={handleconsultation} id={info.id} className="btn btn-primary">View
                        </button>
                        </td>
                      </tr>
                      ))
                      ): (
                        <tr>
                        <td colSpan="8">No data available, click the "COMPUTE TABLE" button to refresh.</td>
                      </tr>
                      )
                       }
                     
                    </tbody>
                  </table> */}
                    <table id="zero-config" className="table table-hover" style={{ width: '100%' }}>
                  {my_consultations == null ? <Spinner animation="border" /> : ""}
                  <DataTableExtensions {...tableCData}>
                    <DataTable
                      className=""
                      noHeader
                      defaultSortField="id"
                      defaultSortAsc={false}
                      pagination
                      exportHeaders={true}
                      highlightOnHover
                    />
                  </DataTableExtensions>
                </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      <Footer/>
      </div>
        </div>
        </div>
        
      
               
        );
 
  };

export default Profile;
