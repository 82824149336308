import React, {useState,useEffect} from 'react';
import axios from "axios";
import dayjs from "dayjs";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Header from '../../component/layout/header';
import Sidebar from '../../component/layout/sidebar';
import Footer from '../../component/layout/footer';

function Allpatient (){
  if (!localStorage.getItem('sl_med_tokenize')) {
    console.log('no auth token set');
    window.location.href="/";
  } else {
        console.log('good token. Log in.')
    }
  const history = useHistory();
  const MySwal = withReactContent(Swal)
  const [my_info, setinfo] = useState([]);
  const [my_count, setcount] = useState();
  
  const token = localStorage.getItem('sl_med_tokenize');
  const myURL = "https://remitaid.starlightsoftware.co.bw/api/fetchpatient";
  // const myURL = "http://localhost:9550/api/fetchpatient";

  const first_name = localStorage.getItem('sl_med_fname');

  // console.log(token)
  const headers2 = { 
    headers: {
      'AccessToken': JSON.parse(token),
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': "*",
      // 'Access-Control-Allow-Headers': "https://instavoicing.herokuapp.com"
    }
  };
  const [state , setState] = useState({
    numberoflines: 20,
  })
  
  const business_name = localStorage.getItem('sl_med_businessname');
  const doctor_id = localStorage.getItem("sl_med_id");

    const body = {
      'doctor_id':doctor_id,
      'accesstoken': JSON.parse(token),
      'numberoflines': state.numberoflines
    }

    const handleChange = (e) => {
      const {id , value} = e.target   
      setState(prevState => ({
          ...prevState,
          [id] : value
      }))
    } 
    
  
    const getmyinfo = async (e) => {
      axios.post(myURL, body, headers2)
      .then((response) => {
        // console.log("response received: ", response);
        if(!response){
          setinfo(0);
          console.log(my_info);
        }else{
        console.log("response received: ", response);
          const sortedArray = response.data.data;
          const numDescending = [...sortedArray].sort((a, b) => b.id - a.id);
          console.log(my_info);

          // console.log(numDescending);
          setinfo(numDescending);
          // setcount(response.data.data.length);
          // console.log(response.data.data.patients.length);
        }
      });
    };

    const handleLoginSubmit = (e) => {
      if(!e.target.id){
        console.log('failed')
        return MySwal.fire('No ID for this payment').then(() => {
        })
        }else{
      const myidd = e.target.id
      // console.log(myidd);
      window.location.href = "profile?myidd=" + myidd;
    }
  }  
    useEffect(() => {
      getmyinfo();
    }, []); 

    const columns = [
      {
        name: 'DATE',
        selector: (row) => dayjs(row.created_at).format("DD MMM YY"),
        sortable: true,
        width: "10rem" ,
          cellExport: (row) => dayjs(row.created_at).format("DD MMM YY")
    },
    {
      name: 'PATIENT NUMBER ',
      selector: (row) => row.patients_number,
      sortable: true,
      width: "10rem",
     cellExport: row => row.patients_number 
  },

    {
      name: 'PATIENT NAME',
      selector: (row) => row.firstname,
      sortable: true,
      width: '15rem',
      cellExport: row => row.firstname 

    },
    {
      name: 'MEDICAL PROVIDER',
      selector: (row) => row.provider == 1 ? 
      <span class="badge badge-danger fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">BOMAID</span>
      : row.provider == 2 
      ? <span class="badge badge-success fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">BPOMAS</span>
      : row.provider == 3
      ? <span class="badge badge-warning fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">PULA</span>
      : row.provider == 4
      ? <span class="badge badge-info fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">BOTSOGO</span>
      : row.provider == 5 
      ? <span class="badge badge-primary fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">BDF</span>
      : row.provider == 6
      ? <span class="badge badge-success fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">BPOMAS HIGH</span>
      : row.provider == 7 
      ? <span class="badge badge-success fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">BPOMAS STANDARD</span>
      : row.provider == 8 
      ? <span class="badge badge-warning fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">PULA DELUXE</span>
      : row.provider == 9
      ? <span class="badge badge-warning fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">PULA STANDARD</span>
      : <span class="badge badge-success fw-semibold fs-9 px-2 ms-2 cursor-default ms-2"> </span>,
      sortable: true,
      width: "15rem" ,
     cellExport: row => row.provider 
  },
    {
      name: 'MEMBERSHIP NUMBER',
      selector: (row) => row.member_number? row.member_number : <span class="badge badge-info fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">NIL </span>,
      sortable: true,
      width: "13rem" ,
    cellExport: row => row.member_number 
  },
    {
      name: 'ACTIONS',
      button: true,
      cell: (row) =>    <button onClick={handleLoginSubmit} id={row.id} className="btn btn-sm btn-primary btn-active-color-primary">View</button>

    },
   
  ];
    
  const data=my_info;

  const tableData = {
    columns,
    data
  };



      return (
<div>
        <div className="header-container fixed-top">
        <Header/>
        </div>
        <div className="main-container" id="container">
        <Sidebar/>



          <div id="content" className="main-content">
        <div className="layout-px-spacing">
          <div className="page-header">
            <div className="page-title">
              <h3>All Patients </h3>
            </div>
          </div>
          <div className="row layout-top-spacing" id="cancel-row">
            <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
            
              <div className="widget-content widget-content-area br-6">
              {/* <a href='profile' className="btn btn-small btn-info">Download patients list</a> */}

              <div className='row'  style={{float: 'right'}}>
              <div className="form-group col-6">
                {/* <label>Filter Number</label> */}
              <input type="text" placeholder='Filter number' className="form-control" value={state.numberoflines} onChange={handleChange} id="numberoflines" name="numberoflines" /><span>
              </span>
            </div>
              <div className='col-6'>
              <button onClick={getmyinfo} className="btn btn-small btn-info">filter</button>
              </div>
            </div>
            

                <div className="table-responsive mb-4 mt-4">
                  <table id="zero-config" className="table table-hover" style={{width: '100%'}}>
                  {/* {my_info == 0 ? <Spinner animation="border" /> : ""} */}

                        <DataTableExtensions {...tableData}>
                                <DataTable className=""
                                  columns={columns}
                                  data={data}
                                  noHeader
                                  defaultSortField="id"
                                  defaultSortAsc={false}
                                  pagination
                                  exportHeaders= {true}
                                  highlightOnHover
                                />
                              </DataTableExtensions>

                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
       <Footer/>
      </div>
        </div>
        </div>
        
      
               
        );
 
  };

export default Allpatient;
