// Layout.js
import React, { useState, useEffect } from 'react';

const Layout = ({ children }) => {
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(true);
  const [logoutTimer, setLogoutTimer] = useState(null);

  const goLogin = (e) => {
    e.preventDefault();
    window.location.href='/';
      localStorage.removeItem('sl_med_tokenize');
  }

  const sessionTimeout = 5800000; // 2 minutes in milliseconds

  useEffect(() => {
    // Start the session timeout countdown when the component mounts
    const timer = setTimeout(() => {
      setUserIsLoggedIn(false);
      window.location.href='/';
      localStorage.removeItem('sl_med_tokenize');
    }, sessionTimeout);

    // Set the timer in the state to allow cleanup
    setLogoutTimer(timer);

    // Clear the timer when the component unmounts to prevent memory leaks
    return () => clearTimeout(timer);
  }, []);

  // Reset the session timeout when there is user activity
  const resetSessionTimeout = () => {
    clearTimeout(logoutTimer); // Clear the previous timer
    const timer = setTimeout(() => {
      setUserIsLoggedIn(false);
    }, sessionTimeout);
    setLogoutTimer(timer); // Set the new timer
  };

  return (
    <div>
      {/* Header, navigation, and other common components can go here */}
      {userIsLoggedIn ? (
        <div onClick={resetSessionTimeout}>
          {children} {/* Render the main application content */}
        </div>
      ) : (
        <div>
          <p>You have been logged out due to inactivity.</p>
          <br/>
          <br/>
          <br/>
          <button className='btn btn-sm btn-primary' onClick={goLogin}>Back to Login</button>
          {/* You can provide a link to the login page here */}
        </div>
      )}
      {/* Footer or other common elements */}
    </div>
  );
};

export default Layout;
