import React, {useEffect} from 'react';
import { useLocation } from 'react-router-dom';


function Sidebar() {

    const location = useLocation();


    return (
    <div>
         <div className="overlay" />
          <div className="search-overlay" />
          <div className="sidebar-wrapper sidebar-theme">
            <nav id="compactSidebar">
              <ul className="menu-categories">
              <li className={`menu ${location.pathname === '/dashboard' ? 'active' : ''}`}>
                  <a href="#index" data-active="false" className="menu-toggle">
                    <div className="base-menu">
                      <div className="base-icons">
                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" /><polyline points="9 22 9 12 15 12 15 22" /></svg>
                      </div>
                      <span>Dashboard</span>
                    </div>
                  </a>
                  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-left"><polyline points="15 18 9 12 15 6" /></svg>
                </li>
                <li className={`menu ${location.pathname === '/newpatient' || location.pathname === '/allpatient' || location.pathname === '/consultation' ||location.pathname === '/profile' || location.pathname === '/editpatient' || location.pathname === '/uploadpatient' ? 'active' : ''}`}>
                  <a href="#uiKit" data-active="false" className="menu-toggle">
                    <div className="base-menu">
                      <div className="base-icons">
                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" /><circle cx={9} cy={7} r={4} /><path d="M23 21v-2a4 4 0 0 0-3-3.87" /><path d="M16 3.13a4 4 0 0 1 0 7.75" /></svg>
                      </div>
                      <span>Patients</span>
                    </div>
                  </a>
                  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-left"><polyline points="15 18 9 12 15 6" /></svg>
                </li>
                <li className={`menu ${location.pathname === '/newinvoice' || location.pathname === '/allinvoice' || location.pathname === '/newinvoice2' || location.pathname === '/newquote' || location.pathname === '/viewinvoice' || location.pathname === '/viewinvoice2' || location.pathname === '/viewquote' ? 'active' : ''}`}>
                  <a href="#app" data-active="false" className="menu-toggle">
                    <div className="base-menu">
                      <div className="base-icons">
                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-clipboard"><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2" /><rect x={8} y={2} width={8} height={4} rx={1} ry={1} /></svg>
                      </div>
                      <span>Invoices</span>
                    </div>
                  </a>
                  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-left"><polyline points="15 18 9 12 15 6" /></svg>
                </li>
                
                <li className={`menu ${location.pathname === '/updateprofile' || location.pathname === '/newpassword' || location.pathname === '/viewconsultation' ||location.pathname === '/report' || location.pathname === '/newsubuser' || location.pathname === '/updatebusiness' || location.pathname === '/updatebusiness' || location.pathname === '/reporttable' ? 'active' : ''}`}>
                  <a href="#components" data-active="true" className="menu-toggle">
                    <div className="base-menu">
                      <div className="base-icons">
                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-box"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z" /><polyline points="3.27 6.96 12 12.01 20.73 6.96" /><line x1={12} y1="22.08" x2={12} y2={12} /></svg>
                      </div>
                      <span>Settings</span>
                    </div>
                  </a>
                  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-left"><polyline points="15 18 9 12 15 6" /></svg>
                </li>
              </ul>
            </nav>
            <div id="compact_submenuSidebar" className="submenu-sidebar">
              <div className="submenu" id="index">
                <ul className="submenu-list" data-parent-element="#app"> 
                  <li>
                    <a href="dashboard"><span className="icon"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-circle"><circle cx={12} cy={12} r={10} /></svg></span> Home </a>
                  </li>
                </ul>
              </div>
              <div className="submenu" id="app">
                <ul className="submenu-list" data-parent-element="#app"> 
                  <li>
                    <a href="newinvoice"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg> Create Invoice </a>
                  </li>
                  <li>
                    <a href="newquote"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg> Create Quote</a>
                  </li>
                  <li>
                    <a href="allinvoice"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" /><polyline points="14 2 14 8 20 8" /><line x1={16} y1={13} x2={8} y2={13} /><line x1={16} y1={17} x2={8} y2={17} /><polyline points="10 9 9 9 8 9" /></svg> View All Invoices</a>
                  </li>
                </ul>
              </div>
              <div className="submenu" id="uiKit">
              <ul className="submenu-list" data-parent-element="#uiKit"> 
                <li>
                    <a href="newpatient"> <span className="icon"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-circle"><circle cx={12} cy={12} r={10} /></svg></span> Add New Patient </a>
                  </li>
                   {/* <li>
                    <a href="consultation"> <span className="icon"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-circle"><circle cx={12} cy={12} r={10} /></svg></span> New Consultation </a>
                  </li> */}
                  <li>
                    <a href="allpatient"> <span className="icon"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-circle"><circle cx={12} cy={12} r={10} /></svg></span> View all Patients </a>
                  </li>
                  <li>
                    <a href="uploadpatient"> <span className="icon"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-circle"><circle cx={12} cy={12} r={10} /></svg></span> Upload Patient Info </a>
                  </li>
                </ul>
              </div>
              <div className="submenu" id="components">
                <ul className="submenu-list" data-parent-element="#components"> 
                  <li>
                    <a href="updateprofile"><span className="icon"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-circle"><circle cx={12} cy={12} r={10} /></svg></span> Update Profile </a>
                  </li>
                  <li>
                    <a href="newpassword"><span className="icon"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-circle"><circle cx={12} cy={12} r={10} /></svg></span> Change password</a>
                  </li>
                  <li>
                    <a href="newsubuser"><span className="icon"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-circle"><circle cx={12} cy={12} r={10} /></svg></span> Create Sub User</a>
                  </li>
                  <li>
                    <a href="updatebusiness"><span className="icon"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-circle"><circle cx={12} cy={12} r={10} /></svg></span> Update Business information </a>
                  </li> 
                  <li>
                  <a href="report"><span className="icon"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-circle"><circle cx={12} cy={12} r={10} /></svg></span>Reports</a>
                  </li>                           
                </ul>
              </div>
            </div>
          </div>
  </div>
    )
}
export default Sidebar;
