import React, {useState} from 'react';
import Chart from 'react-apexcharts'
import ReactApexChart from 'react-apexcharts';
import axios from "axios";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom';


  function Register(){
    if (!localStorage.getItem('sl_med_tokenize')) {
        console.log('no auth token set');
        window.location.href="/";
      } else {
            console.log('good token. Log in.')
        }

  const MySwal = withReactContent(Swal)
  let history = useHistory();
  const baseUrl = "https://remitaid.starlightsoftware.co.bw/api/register";
  // const baseUrl = "http://localhost:9550/api/register";
  const [state , setState] = useState({
    email: '',
    password: '',
    fullname: '',
    phonenumber: '',
    practise_number: '',
    business_email: '',
    business_address: '',
    business_name: '',
    business_paddress: '',
    vat_id: '',
    fax: '',
})
  var postData = {
      "email": state.business_email,
      "password": state.password,
      "firstname": state.fullname,
      "phone": state.phonenumber,
      "phonenumber": state.phonenumber,
      "lastname": state.fullname,
      "business_name": state.business_name,
      "practise_number": state.practise_number,
      "business_email": state.business_email,
      "business_address": state.business_address,
      "postal_address": state.business_paddress,
      "vat_id": state.vat_id,
      "fax": state.fax,
      "role": 2,
  };
  let axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }
  };


const handleChange = (e) => {
  const {id , value} = e.target   
  setState(prevState => ({
      ...prevState,
      [id] : value
  }))
}

const handleRegister = (e) => {
  e.preventDefault();
  console.log(postData)
  if (!state.business_email || !state.password|| !state.fullname|| !state.phonenumber|| !state.business_address|| !state.business_email|| !state.business_name|| !state.practise_number) {
    MySwal.fire({
      title: <p>Please fill all fields</p>,
      didOpen: () => {
      },
    }).then(() => {
    });
  } else {
    MySwal.fire({
        // title: <p>please wait...</p>,
        didOpen: () => {
          MySwal.showLoading()
          axios.post(baseUrl, postData, axiosConfig)
          .then((response) => {
            console.log("response received: ", response);
            Swal.close()
            MySwal.fire({
              title: <p>Account created successfully</p>,
              didOpen: () => {},
            }).then(() => {
            //   window.location.href = "dashboard?myidd=" + myidd;
            });
              
            })
            .catch((err) => {
              Swal.close()
              console.log("AXIOS ERROR: ", err);
              return MySwal.fire(err.response.data.message)
            })
        },
      }).then(() => {
        // Swal.close()
      })
    }
};


      return ( 

        
        <div id="" className="main-content" style={{padding: '10%'}}>
        <div className="layout-px-spacing">
          <div className="page-header">
            <div className="page-title">
              <h3>New Doctor</h3>
            </div>
          </div>
          <div className="card row layout-top-spacing">
            <div id="flFormsGrid" className="col-lg-12 layout-spacing">
              <div className="widget-content widget-content-area">
                <div className="row">
                  <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                    <h4>Create New</h4>
                  </div>                                                                        
                </div>
                <form>
                  <div className="form-row mb-12">
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Enter Doctor Name.</label>
                      <input type="text" className="form-control" value={state.fullname} onChange={handleChange} id="fullname" name="fullname" />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Enter Business Name</label>
                      <input type="text" className="form-control" value={state.business_name} onChange={handleChange} id="business_name" name="business_name" />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Enter Business Email</label>
                      <input type="text" className="form-control" value={state.business_email} onChange={handleChange} id="business_email" name="business_email" />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Business Phone</label>
                      <input type="text" className="form-control" value={state.phonenumber} onChange={handleChange} id="phonenumber" name="phonenumber" />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Business Physical Address</label>
                      <input type="text" className="form-control" value={state.business_address} onChange={handleChange} id="business_address" name="business_address" />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Business Postal Address</label>
                      <input type="text" className="form-control" value={state.business_paddress} onChange={handleChange} id="business_paddress" name="business_paddress" />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Business VAT ID</label>
                      <input type="text" className="form-control" value={state.vat_id} onChange={handleChange} id="vat_id" name="vat_id" />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Practise Number</label>
                      <input type="text" className="form-control" value={state.practise_number} onChange={handleChange} id="practise_number" name="practise_number" />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Fax</label>
                      <input type="text" className="form-control" value={state.fax} onChange={handleChange} id="fax" name="fax" />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Create Password</label>
                      <input type="password" className="form-control" value={state.password} onChange={handleChange} id="password" name="password" />
                    </div>
                  </div>
                  </form>
                  <button onClick={handleRegister}  className="btn btn-primary">Submit </button>
              </div>
            </div>
          </div>
          <div className="footer-wrapper">
            <div className="footer-section f-section-1">
              <p className>Copyright © 2023 <a target="_blank" href="https://starlightsoftware.co.bw/">All rights reserved. Developed by Starlight Software Solutions (Pty) Ltd.</a></p>
            </div>
          </div>
        </div>
      </div>
      );

  };

export default Register;
