import React, {useState,useEffect} from 'react';
import axios from "axios";
import dayjs from "dayjs";
import Swal from 'sweetalert2';
import Select from 'react-select';
import withReactContent from 'sweetalert2-react-content';
import icd10Codes from './icd10Codes';
import Codes from './Codes';
import { useHistory } from 'react-router-dom';
import Header from '../../component/layout/header';
import Sidebar from '../../component/layout/sidebar';
import Footer from '../../component/layout/footer';

function Newquote (){
  if (!localStorage.getItem('sl_med_tokenize')) {
    console.log('no auth token set');
    window.location.href="/";
  } else {
        console.log('good token. Log in.')
    }
  const history = useHistory();
  const MySwal = withReactContent(Swal)
  const first_name = localStorage.getItem('sl_med_fname');

  const [state , setState] = useState({
    code: '',
    code1: '',
    code2: '',
    code3: '',
    code4: '',
    code5: '',
    icd: '',
    icd1: '',
    icd2: '',
    icd3: '',
    icd4: '',
    icd5: '',
    teeth: '',
    teeth1: '',
    teeth2: '',
    teeth3: '',
    teeth4: '',
    teeth5: '',
    qty: '',
    qty1: '',
    qty2: '',
    qty3: '',
    qty4: '',
    qty5: '',
    provider: ''
})

localStorage.setItem('slmed_quote_provider', state.provider);

const handleChange2 = (fieldName) => (selectedOption) => {
  setState((prevState) => ({
    ...prevState,
    [fieldName]: selectedOption.value
  }));
};



  const handleChange = (e) => {
    const {id , value} = e.target   
    setState(prevState => ({
        ...prevState,
        [id] : value
    }))
  }

  const [isHidden, setIsHidden] = useState(true);
  const handleClick = () => {
    setIsHidden(false);
  };

const business_name = localStorage.getItem('sl_med_businessname');

  
  const handlenewInvoice = (e) => {
    if(!state.code || !state.icd || !state.qty|| !state.teeth || !state.provider){
        Swal.fire({
            title: "Error",
            text: "Please enter all fields in the first line and provider",
            // icon: "success",
            showCancelButton: true,
            closeOnCancel: true,
            cancelButtonText: "Cancel"
          }).then(result => {
          })
    }else{
      localStorage.setItem('remitaid_code', state.code);
      localStorage.setItem('remitaid_icd', state.icd);
      localStorage.setItem('remitaid_qty', state.qty);
      localStorage.setItem('remitaid_teeth', state.teeth);
      localStorage.setItem('remitaid_code1', state.code1);
      localStorage.setItem('remitaid_icd1', state.icd1);
      localStorage.setItem('remitaid_qty1', state.qty1);
      localStorage.setItem('remitaid_teeth1', state.teeth1);
      localStorage.setItem('remitaid_code2', state.code2);
      localStorage.setItem('remitaid_icd2', state.icd2);
      localStorage.setItem('remitaid_qty2', state.qty2);
      localStorage.setItem('remitaid_teeth2', state.teeth2);
      localStorage.setItem('remitaid_code3', state.code3);
      localStorage.setItem('remitaid_icd3', state.icd3);
      localStorage.setItem('remitaid_qty3', state.qty3);
      localStorage.setItem('remitaid_teeth3', state.teeth3);
      localStorage.setItem('remitaid_code4', state.code4);
      localStorage.setItem('remitaid_icd4', state.icd4);  
      localStorage.setItem('remitaid_qty4', state.qty4);
      localStorage.setItem('remitaid_teeth4', state.teeth4);
      localStorage.setItem('remitaid_code5', state.code5);
      localStorage.setItem('remitaid_icd5', state.icd5);
      localStorage.setItem('remitaid_qty5', state.qty5);
      localStorage.setItem('remitaid_teeth5', state.teeth5);
      localStorage.setItem('remitaid_quote_medicalaid', state.provider);
        // history.push('viewinvoice');
        window.location.href="viewquote";
    }
}


const unhide = (e) => {

}
  


      return (
<div>
        <div className="header-container fixed-top">
        <Header/>
        </div>
        <div className="main-container" id="container">
        <Sidebar/>



          <div id="content" className="main-content">
        <div className="layout-px-spacing">
          <div className="page-header">
            <div className="page-title">
              <h3>New Quote</h3>
            </div>
          </div>
          <div className="card row layout-top-spacing">
            <div id="flFormsGrid" className="col-lg-12 layout-spacing">
              <div className="widget-content widget-content-area">
                <div className="row">
                  <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                    <h4>Enter quote lines<span>
                        <button type="submit" onClick={handleClick} className="btn btn-primary mt-3" style={{float: 'right'}}>Add more lines</button>
                      </span></h4>
                    <p style={{color: 'red'}}>Please enter  quote information as applicable below, leave bottom lines empty if not required.</p>
                  </div>                                                                        
                </div>
                <form>
                  <br />
                  <br />

                 
                  <div className="form-row mb-12">
                  <div className="form-group col-md-5">
                      <label htmlFor="inputState">Select Provider</label>
                      <select className="form-control" value={state.provider} onChange={handleChange} id="provider" name="provider">
                        <option selected>Choose...</option>
                        <option value={''}>None</option>
                        <option value={'1'}>BOMAID</option>
                        <option value={'2'}>BPOMAS</option>
                        <option value={'3'}>PULA</option>
                        <option value={'4'}>BOTSOGO</option>
                        <option value={'5'}>BDF</option>
                        <option value={'6'}>BPOMAS HIGH</option>
                        <option value={'7'}>BPOMAS STANDARD</option>
                        <option value={'8'}>PULA DELUXE</option>
                        <option value={'9'}>PULA STANDARD</option>
                      </select>
                    </div>
                    </div>
                    <br/>
                    <br/>
                  <div className="form-row mb-12">
                    <div className="form-group col-md-4">
                      <label htmlFor="inputPassword4">Code</label>
                          <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.code, label: state.code }}  onChange={handleChange2('code')}  
                           options={Codes}
                          />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="inputPassword4">ICD-10</label>
                          <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.icd, label: state.icd }}  onChange={handleChange2('icd')}  
                           options={icd10Codes}
                          />
                    </div>
                    <div className="form-group col-md-2">
                      <label htmlFor="inputPassword4">Qty</label>
                      <input type="number"  value={state.qty} id="qty" name="qty" onChange={handleChange} className="form-control"  />

                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="inputPassword4">Teeth</label>
                      <input type="text" value={state.teeth} id="teeth" name="teeth" onChange={handleChange} className="form-control"  />
                    </div>
                  </div>
                  <div className="form-row mb-12">
                  <div className="form-group col-md-4">
                  <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.code1, label: state.code1 }}  onChange={handleChange2('code1')}  
                           options={Codes}
                          />
                        </div>
                    <div className="form-group col-md-3">
                          <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.icd1, label: state.icd1 }}  onChange={handleChange2('icd1')}  
                           options={icd10Codes}
                          />
                    </div>
                    <div className="form-group col-md-2">
                      <input type="number"  value={state.qty1} id="qty1" name="qty1" onChange={handleChange} className="form-control"  />
                    </div>
                    <div className="form-group col-md-3">
                      <input type="text" value={state.teeth1} id="teeth1" name="teeth1" onChange={handleChange} className="form-control"  />
                    </div>
                  </div>
                 
                  <div className="form-row mb-12">
                  <div className="form-group col-md-4">
                  <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.code2, label: state.code2 }}  onChange={handleChange2('code2')}  
                           options={Codes}
                          />
                        </div>
                        <div className="form-group col-md-3">
                          <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.icd2, label: state.icd2 }}  onChange={handleChange2('icd2')}  
                           options={icd10Codes}
                          />
                    </div>
                    <div className="form-group col-md-2">
                      <input type="number"  value={state.qty2} id="qty2" name="qty2" onChange={handleChange} className="form-control"  />
                    </div>
                    <div className="form-group col-md-3">
                      <input type="text" value={state.teeth2} id="teeth2" name="teeth2" onChange={handleChange} className="form-control"  />
                    </div>
                  </div>
                  <div className="form-row mb-12">
                  <div className="form-group col-md-4">
                  <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.code3, label: state.code3 }}  onChange={handleChange2('code3')}  
                           options={Codes}
                          />
                        </div>
                    <div className="form-group col-md-3">
                    <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.icd3, label: state.icd3 }}  onChange={handleChange2('icd3')}  
                           options={icd10Codes}
                          />
                    </div>
                    <div className="form-group col-md-2">
                      <input type="number"  value={state.qty3} id="qty3" name="qty3" onChange={handleChange} className="form-control"  />
                    </div>
                    <div className="form-group col-md-3">
                      <input type="text" value={state.teeth3} id="teeth3" name="teeth3" onChange={handleChange} className="form-control"  />
                    </div>
                  </div>

                  {!isHidden &&
                  <div className="form-row mb-12">
                  <div className="form-group col-md-4">
                  <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.code4, label: state.code4 }}  onChange={handleChange2('code4')}  
                           options={Codes}
                          />
                        </div>
                    <div className="form-group col-md-3">
                    <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.icd4, label: state.icd4 }}  onChange={handleChange2('icd4')}  
                           options={icd10Codes}
                          />
                    </div>
                    <div className="form-group col-md-2">
                      <input type="number"  value={state.qty4} id="qty4" name="qty4" onChange={handleChange} className="form-control"  />
                    </div>
                    <div className="form-group col-md-3">
                      <input type="text" value={state.teeth4} id="teeth4" name="teeth4" onChange={handleChange} className="form-control"  />
                    </div>
                  </div>
                      }
                      {!isHidden &&
                  <div className="form-row mb-12">
                  <div className="form-group col-md-4">
                  <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.code5, label: state.code5 }}  onChange={handleChange2('code5')}  
                           options={Codes}
                          />
                        </div>
                    <div className="form-group col-md-3">
                    <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.icd5, label: state.icd5 }}  onChange={handleChange2('icd5')}  
                           options={icd10Codes}
                          />
                      
                    </div>
                    <div className="form-group col-md-2">
                      <input type="number"  value={state.qty5} id="qty5" name="qty5" onChange={handleChange} className="form-control"  />
                    </div>
                    <div className="form-group col-md-3">
                      <input type="text" value={state.teeth5} id="teeth5" name="teeth5" onChange={handleChange} className="form-control"  />
                    </div>
                  </div>
}
                </form>
              </div>
              {/* <button type="submit" className="btn btn-danger mt-3">Back</button> */}
              <button  onClick={handlenewInvoice} className="btn btn-primary mt-3" style={{float: 'right'}}>Complete</button>
            </div>
          </div>
        </div>
       <Footer/>
      </div>
        </div>
        </div>
        
      
               
        );
 
  };

export default Newquote;
