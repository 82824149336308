import React, {useState,useEffect} from 'react';
import Select from 'react-select';
import axios from "axios";
import dayjs from "dayjs";
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { Button } from 'react-bootstrap';
import Header from '../../component/layout/header';
import Sidebar from '../../component/layout/sidebar';
import Footer from '../../component/layout/footer';
import html2pdf from 'html2pdf.js';



function Viewconsultation(){
  if (!localStorage.getItem('sl_med_tokenize')) {
    console.log('no auth token set');
    window.location.href="/";
  } else {
        console.log('good token. Log in.')
    }
    const [myConsultations, setMyConsultations] = useState({ notes: '' });
  const urlParams = new URLSearchParams(window.location.search);
  var domToPdf = require('react-dom-to-pdf-byaasif');
  const fname = localStorage.getItem('sl_med_fname');
  const business_address = localStorage.getItem('sl_med_businessaddress');
  const practise_number = localStorage.getItem('sl_med_practise_number');
  const myidd = urlParams.get('id');
  console.log(myidd)

  const [state , setState] = useState({
    patientName: '',
    patientNo: '',
    drug1: '',
    drug2: '',
    drug3: '',
    drug4: '',
    drug5: '',
    drug6: '',
    drugweight1: '',
    drugweight2: '',
    drugweight3: '',
    drugweight4: '',
    drugweight5: '',
    drugweight6: '',
    qty1: '',
    qty2: '',
    qty3: '',
    qty4: '',
    qty5: '',
    qty6: '',
    notes: ''
})




  // function printDiv() {
  //   const element = document.getElementById('divToPrint');
  //   const opt = {
  //     margin: 0.5,
  //     filename: 'Consultation.pdf',
  //     image: { type: 'jpeg', quality: 0.98 },
  //     html2canvas: { scale: 2 },
  //     jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
  //   };
  //   html2pdf().set(opt).from(element).save();
  // }



  function printDiv2() {
    const element = document.getElementById('prescription');
    const opt = {
      margin: 0.5,
      filename: 'Prescription.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    html2pdf().set(opt).from(element).save();
  }

  const [showPrintDiv, setShowPrintDiv] = useState(false);

  // const printDiv = () => { 
  //   setShowPrintDiv(true);
  //   const element = document.getElementById('divToPrint');
  //   // hiddenDiv.style.display = 'block';
  //   const opt = {
  //     margin: 0.5,
  //     filename: 'Consultation.pdf',
  //     image: { type: 'jpeg', quality: 0.98 },
  //     html2canvas: { scale: 2 },
  //     jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
  //   };
  //   html2pdf().set(opt).from(element).save().then(() => {
  //     // hiddenDiv.style.display = 'none';
  //   });
  //   setShowPrintDiv(false);
  // };


  const printDiv = () => {
    const element = document.getElementById('divToPrint');
    const printOnlyDiv = document.getElementById('print-only');

    // Temporarily add the div to the document
    printOnlyDiv.style.display = 'block';

    // Define PDF options
    const opt = {
      margin: 0.5,
      filename: 'Consultation.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };

    // Generate the PDF
    html2pdf().set(opt).from(element).save().then(() => {
      // Revert the changes after saving the PDF
      printOnlyDiv.style.display = 'none';
    });
  };
  




  const handleChange = (e) => {
    const {id , value} = e.target   
    setState(prevState => ({
        ...prevState,
        [id] : value
    }))
    
  }  
   
  


  const history = useHistory();
  const MySwal = withReactContent(Swal)
    const [my_consultations, setconsultation] = useState([]);
    const token = localStorage.getItem('sl_med_tokenize');
    const myURL = "https://remitaid.starlightsoftware.co.bw/api/getConsultationById";
    const headers2 = { 
    headers: {
        'AccessToken': JSON.parse(token),
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': "*",
    }
    };

    const goBack = (e) => {
      history.goBack()
      }

const business_name = localStorage.getItem('sl_med_businessname');
const doctor_id = localStorage.getItem("sl_med_id");
console.log(myidd)

  const body = {
    'consultationId': myidd,
    'accessToken': JSON.parse(token),
  }

const getmyinfo = async (e) => {
  await axios.post(myURL, body, headers2)
  .then((response) => {
    console.log("response received: ", response);
    if(!response){
        setconsultation(0);
    }else{
    console.log("response received: ", response);
      console.log(response.data.data);
      setconsultation(response.data.data);
      setconsultation(response.data.data);
      console.log(my_consultations);
    }
  });
};

useEffect(() => {
    getmyinfo();
  }, []); 


  const my_consultation = {
    notes: '<p style="font-family: Arial; font-size: 12px; margin: 25px; line-height: 20px">{my_consultations.notes}</p>'
  };



    
  
  
      return (
        <div>
        <div className="header-container fixed-top">
        <Header/>
        </div>
        <div className="main-container" id="container">
        <Sidebar/>



          <div id="content" className="main-content">
        <div className="layout-px-spacing">
          <div className="page-header">
            <div className="page-title">
              <h3>View Consultation</h3>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<p ><button onClick={goBack} style={{float: 'right !important'}} className="btn btn-small btn-danger">Go back</button></p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<p ><button onClick={getmyinfo} style={{float: 'right !important'}} className="btn btn-small btn-info">Refresh</button></p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<p ><button onClick={() => printDiv('printable-content')} style={{float: 'right !important'}} className="btn btn-small btn-info">Print Page</button></p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<p ><button onClick={() => printDiv2('prescription')} style={{float: 'right !important'}} className="btn btn-small btn-info">Print Prescription</button></p>
          </div>
          <div className="card row layout-top-spacing">
            <div  className="col-lg-12 layout-spacing">
              <div className="widget-content widget-content-area">
                <div className="row">
                </div>

                <div id='divToPrint'>
                  <h6>Consultation for {my_consultations.patient_name} on {dayjs(my_consultations.created_at).format("DD MMM YY")}</h6>
                  <br/>
                  <br/>
                  <div className="form-row mb-12">
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Patient Number</label>
                      <input type="text" disabled className="form-control"  value={my_consultations.patients_number}  id="patientNo" name="patientNo" />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputState">Patient Name</label>
                      <input type="text" disabled className="form-control"  value={my_consultations.patient_name}  id="patientName" name="patientName" />
                    </div>
                    <br/>
                    <br/>
                    <br/>
                  {/* <div className={`print-only ${showPrintDiv ? 'print-show' : 'print-hide'}`}> */}

                    <div className="form-group col-md-12">
                     <h4 className='text-primary'>Notes</h4>
                     <div dangerouslySetInnerHTML={{ __html: my_consultations.notes }} />
                     {/* <div > */}
                     <div id="print-only" className="form-group col-md-12" style={{ display: 'none' }}>
                            <br></br>
                            <br></br>
                            <div className='col-6' style={{marginLeft: '-30px'}}>
                            <b>Dr. {fname} <br/><br/>{business_name}<br/><br/> Practise No: {practise_number}</b>
                            </div>
                            <div className='col-12'>
                            <br/>
                            <br/>
                            <br/>
                            <small style={{  bottom: "100", width: "100%", marginLeft: '-40px'}}>
                            &nbsp;&nbsp;&nbsp;Powered by Starlight Software Solutions (PTY) LTD - info@starlightsoftware.co.bw
                            </small>
                            </div>
                            </div>
                    </div>

                    
                 
                    </div>
                    </div>


              <div id='prescription'>
                <div className="form-row mb-12">
                    <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                      <br/>
                      <br/>
                  <h6>Prescription for {my_consultations.patient_name} on {dayjs(my_consultations.created_at).format("DD MMM YY")}</h6>
                  <br/>
                      <br/>
                      </div> 
                    
                    {my_consultations.prescription_drug_1?
                        <div className="form-group col-md-4">
                          <label htmlFor="inputState"> Drug name</label>
                          <input type="text" className="form-control" disabled value={my_consultations.prescription_drug_1}  id="drug1" name="drug1" />
                        </div>
                        : ''}
                    {my_consultations.prescription_drug_1?
                        <div className="form-group col-md-4">
                        <label for="drugWeight">Drug Weight (g/mg)</label>
                          <input type="text" className="form-control" disabled value={my_consultations.prescription_weight_1}  id="drugweight1" name="drugweight1" />
                        </div>
                        : ''}
                    {my_consultations.prescription_drug_1?
                        <div className="form-group col-md-4">
                          <label htmlFor="inputState">Quantity</label>
                          <input type="text" className="form-control" disabled value={my_consultations.qty1}  id="qty1" name="qty1" />
                        </div>
                        : ''}
                    
                    {my_consultations.prescription_drug_2?
                    <div className="form-group col-md-4">
                      <label htmlFor="inputState"> Drug name</label>
                      <input type="text" className="form-control" disabled value={my_consultations.prescription_drug_2}  id="drug2" name="drug2" />
                    </div>
                        : ''}
                    {my_consultations.prescription_drug_2?
                    <div className="form-group col-md-4">
                    <label for="drugWeight">Drug Weight  (g/mg)</label>
                      <input type="text" className="form-control" disabled value={my_consultations.prescription_weight_2}  id="drugweight2" name="drugweight2" />
                    </div>
                    : ''}
                    {my_consultations.prescription_drug_2?
                    <div className="form-group col-md-4">
                      <label htmlFor="inputState">Quantity</label>
                      <input type="text" className="form-control" disabled value={my_consultations.qty2}  id="qty2" name="qty2" />
                    </div>
                     : ''}

                  {my_consultations.prescription_drug_3?
                    <div className="form-group col-md-4">
                      <label htmlFor="inputState"> Drug name</label>
                      <input type="text" className="form-control"  disabled value={my_consultations.prescription_drug_3}  id="drug3" name="drug3" />
                    </div>
                    : ''}
                  {my_consultations.prescription_drug_3?
                    <div className="form-group col-md-4">
                    <label for="drugWeight">Drug Weight  (g/mg)</label>
                      <input type="text" className="form-control" disabled value={my_consultations.prescription_weight_3}  id="drugweight3" name="drugweight3" />
                    </div>
                    : ''}
                  {my_consultations.prescription_drug_3?
                    <div className="form-group col-md-4">
                      <label htmlFor="inputState">Quantity</label>
                      <input type="text" className="form-control" disabled value={my_consultations.qty3}  id="qty3" name="qty3" />
                    </div>
                    : ''}
                  {my_consultations.prescription_drug_4?
                    <div className="form-group col-md-4">
                      <label htmlFor="inputState"> Drug name</label>
                      <input type="text" className="form-control" disabled value={my_consultations.drug4} onChange={handleChange} id="drug4" name="drug4" />
                    </div>
                    : ''}
                  {my_consultations.prescription_drug_4?
                    <div className="form-group col-md-4">
                    <label for="drugWeight">Drug Weight  (g/mg)</label>
                      <input type="text" className="form-control" disabled value={my_consultations.drugweight4} onChange={handleChange} id="drugweight4" name="drugweight4" />
                    </div>
                    : ''}
                  {my_consultations.prescription_drug_4?
                    <div className="form-group col-md-4">
                      <label htmlFor="inputState">Quantity</label>
                      <input type="text" className="form-control" disabled value={my_consultations.qty4} onChange={handleChange} id="qty4" name="qty4" />
                    </div>
                    : ''}
                  {my_consultations.prescription_drug_5?
                    <div className="form-group col-md-4">
                      <label htmlFor="inputState"> Drug name</label>
                      <input type="text" className="form-control" disabled value={my_consultations.drug5} onChange={handleChange} id="drug5" name="drug5" />
                    </div>
                    : ''}

                  {my_consultations.prescription_drug_5?
                    <div className="form-group col-md-4">
                    <label for="drugWeight">Drug Weight  (g/mg)</label>
                      <input type="text" className="form-control" disabled value={my_consultations.drugweight5} onChange={handleChange} id="drugweight5" name="drugweight5" />
                    </div>
                    : ''}
                  {my_consultations.prescription_drug_5?
                    <div className="form-group col-md-4">
                      <label htmlFor="inputState">Quantity</label>
                      <input type="text" className="form-control" disabled value={my_consultations.qty5} onChange={handleChange} id="qty5" name="qty5" />
                    </div>
                    : ''}
                  {my_consultations.prescription_drug_6?
                    <div className="form-group col-md-4">
                      <label htmlFor="inputState"> Drug name</label>
                      <input type="text" className="form-control" disabled value={my_consultations.drug6} onChange={handleChange} id="drug6" name="drug6" />
                    </div>
                    : ''}

                  {my_consultations.prescription_drug_6?
                    <div className="form-group col-md-4">
                    <label for="drugWeight">Drug Weight  (g/mg)</label>
                      <input type="text" className="form-control" disabled value={my_consultations.drugweight6} onChange={handleChange} id="drugweight6" name="drugweight6" />
                    </div>
                    : ''}

                  {my_consultations.prescription_drug_6?
                    <div className="form-group col-md-4">
                      <label htmlFor="inputState">Quantity</label>
                      <input type="text" className="form-control" disabled value={my_consultations.qty6} onChange={handleChange} id="qty6" name="qty6" />
                    </div>
                    : ''}
                  {/* </div> */}
                  <br />
                          <br />
                          <br/>
                          <br/>
                          <br/>
                          <br/>
                          <br/>
                          <br/>
                          
                  <div className="row">
                            <div className="col-6">
                              <div className="col-sm-12 align-self-center">
                                <p className="inv-to">Patient Signature: ___________</p>
                              </div>
                              <div className="col-sm-12 col-7">
                                <p className>Date: ____________ </p>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="col-sm-12 align-self-center">
                                <p className="inv-to">Doctor's Signature: ___________</p>
                              </div>
                              <div className="col-sm-12 col-7">
                                <p className>Date: ____________ </p>
                              </div>
                            </div>
                            <br></br>
                            <br></br>
                            <br></br>
                            <div className='col-6'>
                          <b className="col-sm-7 align-self-center">Dr. {fname} Practise No: {practise_number}</b>
                            </div>
                            <div className='col-12'>
                            <br/>
                            <br/>
                            <br/>
                            <small style={{  bottom: "10", width: "100%", textAlign: "left" }}>
                            &nbsp;&nbsp;&nbsp;Powered by Starlight Software Solutions (PTY) LTD - info@starlightsoftware.co.bw
                            </small>
                            </div>
                            </div>
                            
                          </div>
                  </div>
                  {/* <button onClick={handlenewConsultation} className="btn btn-primary">Submit </button> */}
              </div>
            </div>
          </div>
          <Footer/>
        </div>
      </div>
        </div>
        // </div>
        );
 
  };

export default Viewconsultation;
