import React, {useState,useEffect} from 'react';
import axios from "axios";
import dayjs from "dayjs";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { CSVLink, CSVDownload } from "react-csv";
import Viewinvoice2 from './viewinvoice2';
import html2pdf from 'html2pdf.js';
import Header from '../../component/layout/header';
import Sidebar from '../../component/layout/sidebar';
import Footer from '../../component/layout/footer';
const objectToXML = require('object-to-xml');



function Allpatient (){
  const practise_number = localStorage.getItem('sl_med_practise_number');
  // const { js2xml } = require('xml-js');
  if (!localStorage.getItem('sl_med_tokenize')) {
    console.log('no auth token set');
    window.location.href="/";
  } else {
        console.log('good token. Log in.')
    }
  const history = useHistory();
  const MySwal = withReactContent(Swal)
  const [showxmlButton, setShowxmlButton] = useState(false);
  const business_name = localStorage.getItem('sl_med_businessname');
  const fname = localStorage.getItem('sl_med_fname');
  const token = localStorage.getItem('sl_med_tokenize');
  const doctor_id = localStorage.getItem("sl_med_id");
  const [selectedRow, setSelectedRow] = useState(null);
  const [invoices_info, setinfo] = useState([]);
  // const myURL = "http://localhost:9500/api/fetchinvoices";
  const myURL = "https://remitaid.starlightsoftware.co.bw/api/fetchinvoices";
  
  // const searchURL = "http://localhost:9500/api/fetchinvoicebydate";
  const searchURL = "https://remitaid.starlightsoftware.co.bw/api/fetchinvoicebydate";
  const csvFileName = 'SLMED_invoice.csv'; // Set your custom CSV file name
  const csvTitle = 'New CSV invoice'; // Set your custom title
  const [state , setState] = useState({
    date1: '',
    date2: '',
    provider: '',
    patient_name: '',
    status: ''
})



const headers2 = { 
  headers: {
    'AccessToken': JSON.parse(token),
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': "*",
  }
};



const formatDate = (inputDate) => {
  const dateObj = new Date(inputDate); // Convert inputDate to a Date object
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Add 1 because months are zero-indexed
  const day = String(dateObj.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

  const body = {
    'date1':formatDate(state.date1),
    'date2':formatDate(state.date2),
    'accesstoken': JSON.parse(token),
    'doctor_id': doctor_id,
    'provider': state.provider,
    'patient_name': state.patient_name
  }


  const getinvoicesinfo = async (e) => {
    axios.post(myURL, body, headers2)
    .then((response) => {
      console.log("response received: ", response);
      if(!response){
        setinfo(0);
      }else{
        const sortedArray = response.data.data.invoices;
        const numDescending = [...sortedArray].sort((a, b) => b.id - a.id);
        // console.log(numDescending);
        setinfo(numDescending);
        setinfo(numDescending);
      // console.log(response.data.data);
      }
    });
  };

  const csvData = [
    ["Invoice_Date", "Doctor_Name", "Clinic_Name", "patient_name", "Medical_Aid", "Total_Amount"],
  ];
  
  // Loop through the invoices_info array and generate rows for each item
  invoices_info.forEach((invoice) => {
    let medicalAid = ""; // Initialize the medicalAid variable
  
    // Determine the value for the "Medical_Aid" column based on invoices_info.provider
    if (invoice.provider == 1) {
      medicalAid = "BOMAID";
    console.log(medicalAid)
    } else if (invoice.provider == 2) {
      medicalAid = "BPOMAS";
    } else if (invoice.provider == 3) {
      medicalAid = "PULA";
    } else if (invoice.provider == 4) {
      medicalAid = "BOTSOGO";
    } else if (invoice.provider == 5) {
      medicalAid = "BDF";
    }else if (invoice.provider==='6'){
      medicalAid = "BPOMAS HIGH"
    }else if(invoice.provider==='7'){
      medicalAid = "BPOMAS STANDARD"
    }else if(invoice.provider==='8'){
      medicalAid = "PULA DELUXE"
    }else if(invoice.provider==='9'){
      medicalAid = "PULA STANDARD"
    }
    const row = [
      invoice.created_at,
      fname,
      business_name,
      invoice.patient_name,
      medicalAid,
      invoice.total_amount,
    ];
  
    csvData.push(row); // Add the row to the CSV data array
  });
  
  

  const handleChange = (e) => {
    const {id , value} = e.target   
    setState(prevState => ({
        ...prevState,
        [id] : value
    }))
  }


  useEffect(() => {
    getinvoicesinfo();
  }, []);

  const handleRowClick = (row) => {
    setSelectedRow(row);
  };


  const viewInvoice = (selectedId) => {
    const selectedData = data.find((item) => item.id === selectedId);
    if (selectedData) {
      const selectedDataString = JSON.stringify(selectedData);
      localStorage.setItem('selectedData', selectedDataString);
      // console.log(selectedDataString)
      const nextPageUrl = '/viewinvoice2'; // Specify the URL of the next page
      window.location.href = nextPageUrl;
    } else {
      console.error(`Data for ID ${selectedId} not found`);
    }
}

const mark = (selectedId) => {
  const selectedData = data.find((item) => item.id === selectedId);
  if (selectedData) {
    Swal.fire({
      text: "Are you sure you want to update?",
      icon: "info",
      showCancelButton: false,
      confirmButtonText: "Okay",
      closeOnCancel: true,
    }).then(result => {
      console.log(selectedId)
      const dashURL = "https://remitaid.starlightsoftware.co.bw/api/updatemedaidfee";
      const doctor_id = localStorage.getItem("sl_med_id"); 
      const postmyData = {
        'accesstoken': JSON.parse(token),
        'invoiceID': selectedId, 
        'doctorId': doctor_id,
      };
      console.log(postmyData);
      MySwal.fire({
        title: <p>please wait...</p>,
        didOpen: () => {
          MySwal.showLoading();
          axios.post(dashURL, postmyData, headers2)
            .then((response) => {
              console.log("response received: ", response);
              Swal.close();
              Swal.fire({
                title: "Successful",
                text: "Invoice updated successfully",
                icon: "success",
                showCancelButton: false,
                confirmButtonText: "Okay",
                closeOnCancel: true,
              }).then(result => {
                window.location.reload(false);
              });
            })
            .catch((err) => {
              Swal.close();
              console.log("AXIOS ERROR: ", err);
              if (!err.response) {
                return MySwal.fire(err.message);
              } else {
                return MySwal.fire(err.response.data.message);
              }
            });
        }
      });
    });
  } else {
    console.error(`Data for ID ${selectedId} not found`);
  }
};


const markpatient = (selectedId) => {
  const selectedData = data.find((item) => item.id === selectedId);
  if (selectedData) {
    Swal.fire({
      text: "Are you sure you want to update?",
      icon: "info",
      showCancelButton: false,
      confirmButtonText: "Okay",
      closeOnCancel: true,
    }).then(result => {
      console.log(selectedId)
      const dashURL = "https://remitaid.starlightsoftware.co.bw/api/updateclientfee";
      const doctor_id = localStorage.getItem("sl_med_id"); 
      const postmyData = {
        'accesstoken': JSON.parse(token),
        'invoiceID': selectedId, 
        'doctorId': doctor_id,
      };
      console.log(postmyData);
      MySwal.fire({
        title: <p>please wait...</p>,
        didOpen: () => {
          MySwal.showLoading();
          axios.post(dashURL, postmyData, headers2)
            .then((response) => {
              console.log("response received: ", response);
              Swal.close();
              Swal.fire({
                title: "Successful",
                text: "Invoice updated successfully",
                icon: "success",
                showCancelButton: false,
                confirmButtonText: "Okay",
                closeOnCancel: true,
              }).then(result => {
                window.location.reload(false);
              });
            })
            .catch((err) => {
              Swal.close();
              console.log("AXIOS ERROR: ", err);
              if (!err.response) {
                return MySwal.fire(err.message);
              } else {
                return MySwal.fire(err.response.data.message);
              }
            });
        }
      });
    });
  } else {
    console.error(`Data for ID ${selectedId} not found`);
  }
};




  
  
  const searchInvoice = (e) => {
      if(!state.date1 || !state.date2){
          Swal.fire({
              title: "Error",
              text: "Please choose dates",
              showCancelButton: true,
              closeOnCancel: true,
              cancelButtonText: "Cancel"
            }).then(result => {
            })
      }else{
        console.log(body)
            MySwal.fire({
              didOpen: () => {
                MySwal.showLoading()
                axios.post(searchURL, body, headers2)
                .then((response) => {
                Swal.close()
                  console.log("response received: ", response.data.data[0].patient_information);
                  if(!response){
                  }else{
                  console.log("response received: ", response);
                    setinfo(response.data.data);
                    setinfo(response.data.data);
                    setShowxmlButton(true);
                    console.log(response.data.data);
                  }
                });
              },
            }).then(() => {
              Swal.close()
            })
          }
        };

        const columns = [
          {
            name: 'DATE',
            selector: (row) => dayjs(row.created_at).format("DD MMM YY"),
            sortable: true,
            width: "8rem" ,
              cellExport: (row) => dayjs(row.created_at).format("DD MMM YY")
        },
        {
          name: 'INVOICE NO.',
          selector: (row) => row.invoice_number,
          sortable: true,
          width: "8rem" ,
            cellExport: (row) => row.invoice_number
      },
        {
          name: 'PATIENT NAME',
          selector: (row) => row.patient_name,
          sortable: true,
          width: '12rem',
          cellExport: row => row.patient_name 
    
        },
        {
          name: 'MEDICAL PROVIDER',
          selector: (row) => row.provider == 1 ? 
      <span class="badge badge-danger fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">BOMAID</span>
      : row.provider == 2 
      ? <span class="badge badge-success fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">BPOMAS</span>
      : row.provider == 3
      ? <span class="badge badge-warning fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">PULA</span>
      : row.provider == 4
      ? <span class="badge badge-info fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">BOTSOGO</span>
      : row.provider == 5 
      ? <span class="badge badge-primary fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">BDF</span>
      : row.provider == 6
      ? <span class="badge badge-success fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">BPOMAS HIGH</span>
      : row.provider == 7 
      ? <span class="badge badge-success fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">BPOMAS STANDARD</span>
      : row.provider == 8 
      ? <span class="badge badge-warning fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">PULA DELUXE</span>
      : row.provider == 9
      ? <span class="badge badge-warning fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">PULA STANDARD</span>
      : <span class="badge badge-success fw-semibold fs-9 px-2 ms-2 cursor-default ms-2"> </span>,
          sortable: true,
          width: "12rem" ,
         cellExport: row => row.provider 
      },
      {
        name: 'INVOICE AMOUNT',
        selector: (row) => row.total_amount.toLocaleString('en-BW', { style: 'currency', currency: 'BWP' }),  
        sortable: true,
        width: "10rem" ,
       cellExport: row => row.total_amount 
    },
        {
          name: 'ACTIONS',
          button: true,
          cell: (row) => <button onClick={() => viewInvoice(row.id)} id={row.id} data-patientid={row.patient_id} className="btn btn-sm btn-primary btn-active-color-primary">View</button>
    
        },
        {
          name: 'PATIENT STATUS',
          button: true,
          width: "8rem" ,
     
      cell: (row) => row.client_paid==0? <button onClick={() => markpatient(row.id)} title={'Click here if patient has paid'} id={row.id} data-patientid={row.patient_id} className="btn btn-sm btn-info btn-active-color-info">Patient Paid</button>:  <span class="badge badge-danger fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">PAID</span>
    
        },
        {
          name: 'MEDAID STATUS',
          button: true,
          width: "8rem" ,
          cell: (row) => row.medaid_paid==0? <button onClick={() => mark(row.id)} id={row.id} title={'Click here if medical aid has paid'} data-patientid={row.patient_id} className="btn btn-sm btn-info btn-active-color-danger">Med-Aid Paid</button>: <span class="badge badge-danger fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">PAID</span>
    
        },
       
      ];
        
      const data=invoices_info;
      const tableData = {
        columns,
        data
      };





const printAndDownloadXML = async (e) => {
  e.preventDefault();
const xmlDataArray = invoices_info.map(invoiceData => {
  const patientInfo = JSON.parse(invoiceData.patient_information);
  let myprovider = '';

  if (invoiceData.provider === '1') {
    myprovider = 'BOMAID';
  } else if (invoiceData.provider === '2') {
    myprovider = 'BPOMAS';
  } else if (invoiceData.provider === '3') {
    myprovider = 'PULA';
  } else if (invoiceData.provider === '4') {
    myprovider = 'BOTSOGO';
  } else if (invoiceData.provider === '5') {
    myprovider = 'BDF';
  }else if (invoiceData.provider==='6'){
    myprovider = "BPOMAS HIGH"
  }else if(invoiceData.provider==='7'){
    myprovider = "BPOMAS STANDARD"
  }else if(invoiceData.provider==='8'){
    myprovider = "PULA DELUXE"
  }else if(invoiceData.provider==='9'){
    myprovider = "PULA STANDARD"
  }



  const fullNameArray = invoiceData.patient_name.split(" ");
  const firstName = fullNameArray[0];
  const lastName = fullNameArray[1];
  
  const claimDetails = [
    {
      uniqueSeq: '1',
      lineType: 'S',
      tariffCode: invoiceData.code,
      tariffDesc: invoiceData.service,
      serviceDate: invoiceData.created_at, 
      units: invoiceData.qty,
      claimed: (
        Number(invoiceData.patient_fee1) +
        Number(invoiceData.scheme_fee1)
      ).toFixed(2),
      benefit: Number(invoiceData.scheme_fee1).toFixed(2),
      mouthpartid: invoiceData.teeth,
      diagnosis: {
        primSec: 'P',
        diagType: 'Diagnosis',
        diagCode: invoiceData.ICD_10.split(' ')[0],
      },
    },
    invoiceData.ICD_10_1
      ? {
          uniqueSeq: '2',
          lineType: 'S',
          tariffCode: invoiceData.code_1,
          tariffDesc: invoiceData.service1,
          serviceDate: invoiceData.created_at,
          units: invoiceData.qty_1,
          claimed: (
            Number(invoiceData.patient_fee2) +
            Number(invoiceData.scheme_fee2)
          ).toFixed(2),
          benefit: Number(invoiceData.scheme_fee2).toFixed(2),
          mouthpartid: invoiceData.teeth_1,
          diagnosis: {
            primSec: 'P',
            diagType: 'Diagnosis',
            diagCode: invoiceData.ICD_10_1.split(' ')[0],
          },
        }: null,
        invoiceData.ICD_10_2
      ? {
          uniqueSeq: '3',
          lineType: 'S',
          tariffCode: invoiceData.code_2,
          tariffDesc: invoiceData.service2,
          serviceDate: invoiceData.created_at,
          units: invoiceData.qty_2,
          claimed: (
            Number(invoiceData.patient_fee3) +
            Number(invoiceData.scheme_fee3)
          ).toFixed(2),
          benefit: Number(invoiceData.scheme_fee3).toFixed(2),
          mouthpartid: invoiceData.teeth_2,
          diagnosis: {
            primSec: 'P',
            diagType: 'Diagnosis',
            diagCode: invoiceData.ICD_10_2.split(' ')[0],
          },
        }: null,
        invoiceData.ICD_10_3
      ? {
          uniqueSeq: '4',
          lineType: 'S',
          tariffCode: invoiceData.code_3,
          tariffDesc: invoiceData.service3,
          serviceDate: invoiceData.created_at,
          units: invoiceData.qty_3,
          claimed: (
            Number(invoiceData.patient_fee4) +
            Number(invoiceData.scheme_fee4)
          ).toFixed(2),
          benefit: Number(invoiceData.scheme_fee4).toFixed(2),
          mouthpartid: invoiceData.teeth_3,
          diagnosis: {
            primSec: 'P',
            diagType: 'Diagnosis',
            diagCode: invoiceData.ICD_10_3.split(' ')[0],
          },
        }:null,
        invoiceData.ICD_10_4
        ? {
            uniqueSeq: '5',
            lineType: 'S',
            tariffCode: invoiceData.code_4,
            tariffDesc: invoiceData.service4,
            serviceDate: invoiceData.created_at,
            units: invoiceData.qty_4,
            claimed: (
              Number(invoiceData.patient_fee5) +
              Number(invoiceData.scheme_fee5)
            ).toFixed(2),
            benefit: Number(invoiceData.scheme_fee5).toFixed(2),
            mouthpartid: invoiceData.teeth_4,
            diagnosis: {
              primSec: 'P',
              diagType: 'Diagnosis',
              diagCode: invoiceData.ICD_10_4.split(' ')[0],
            },
          }:
          invoiceData.ICD_10_5
          ? {
              uniqueSeq: '6',
              lineType: 'S',
              tariffCode: invoiceData.code_5,
              tariffDesc: invoiceData.service5,
              serviceDate: invoiceData.created_at,
              units: invoiceData.qty_5,
              claimed: (
                Number(invoiceData.patient_fee6) +
                Number(invoiceData.scheme_fee6)
              ).toFixed(2),
              benefit: Number(invoiceData.scheme_fee6).toFixed(2),
              mouthpartid: invoiceData.teeth_5,
              diagnosis: {
                primSec: 'P',
                diagType: 'Diagnosis',
                diagCode: invoiceData.ICD_10_5.split(' ')[0],
              },
            }
      : null,
  ].filter(detail => detail !== null); // Remove null elements

  const totalDrfee =
        (Number(invoiceData.doctor_fee1) || 0) +
        (Number(invoiceData.doctor_fee2) || 0) +
        (Number(invoiceData.doctor_fee3) || 0) +
        (Number(invoiceData.doctor_fee4) || 0) +
        (Number(invoiceData.doctor_fee5) || 0) +
        (Number(invoiceData.doctor_fee6) || 0) ;

      const totalScheme =  
      (Number(invoiceData.scheme_fee1) || 0) +
      (Number(invoiceData.scheme_fee2) || 0) +
      (Number(invoiceData.scheme_fee3) || 0) +
      (Number(invoiceData.scheme_fee4) || 0) +
      (Number(invoiceData.scheme_fee5) || 0) +
      (Number(invoiceData.scheme_fee6) || 0);

      const totalpatient =  
      (Number(invoiceData.patient_fee1) || 0) +
      (Number(invoiceData.patient_fee2) || 0) +
      (Number(invoiceData.patient_fee3) || 0) +
      (Number(invoiceData.patient_fee4) || 0) +
      (Number(invoiceData.patient_fee5) || 0) +
      (Number(invoiceData.patient_fee6) || 0);

  const xmlData = {
    claimHeader: {
      scheme: myprovider,
      tradepart: practise_number,
      batchNo: invoiceData.invoice_number,
      createDate: invoiceData.created_at,
      transactionType: 'Request',
      numTrans: claimDetails.length,
      totClaim: (Number(totalDrfee) + Number(totalpatient) + Number(totalScheme)) / 2,
      claims: {
        claim: {
          scriptNum: invoiceData.invoice_number,
          extUniqueNum: patientInfo.invpatient_number,
          medAid: patientInfo.membership_no,
          depBirthDate: patientInfo.invDOB,
          depInitials: '',
          depName: firstName,
          depSurname: lastName,
          claimDetail: claimDetails,
        },
      },
    },
  };

  return xmlData;
});
console.log(xmlDataArray)


const { js2xml } = require('xml-js');

const xmlDataToDownload = invoices_info.length > 0 ? xmlDataArray : [];

if (xmlDataToDownload.length > 0) {
    const xmlString = js2xml({ data: xmlDataToDownload }, { compact: true, spaces: 4 });
    console.log(xmlString);

    // Create a Blob with the XML content
    const blob = new Blob([xmlString], { type: 'text/xml' });

    // Create a link element
    const link = document.createElement('a');

    // Set link attributes
    link.href = window.URL.createObjectURL(blob);
    link.download = 'output.xml';

    // Append the link to the document
    document.body.appendChild(link);

    // Trigger a click event on the link to start the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
} else {
    console.log('No XML data to download');
}


  

  console.log(objectToXML(xmlDataArray));
};
       


      return (
<div>
        <div className="header-container fixed-top">
        <Header/>
        </div>
        <div className="main-container" id="container">
        <Sidebar/>
          <div id="content" className="main-content">
        <div className="layout-px-spacing">
          <div className="page-header">
            <div className="page-title">
              <h3>All Invoice</h3>
            </div>
          </div>
          <div className="row layout-top-spacing" id="cancel-row">
            <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
             
              <div className="widget-content widget-content-area br-6">
              <CSVLink data={csvData} filename={csvFileName} target={csvTitle}> <a className="btn btn-small btn-info">Download CSV</a>&nbsp;</CSVLink>
              <button onClick={getinvoicesinfo} className="btn btn-small btn-info">COMPUTE TABLE</button>&nbsp;
              {/* <button className="btn btn-small btn-danger">DEBTORS LIST</button>&nbsp; */}
              <br />
              <br />
              <br />
             <div>
              <div className='row'>
              <div className="form-group col-md-2" >
                <label>Filter From Date</label>
                    <input type="date" placeholder='search' className="form-control" value={state.date1} id="date1" name="date1" onChange={handleChange}/>
                  </div>
                  <div className="form-group col-md-2" >
                  <label>Filter to Date</label>
                    <input type="date" placeholder='search' className="form-control" value={state.date2} id="date2" name="date2" onChange={handleChange} />
                  </div>
                 
                    <div className="form-group col-md-2">
                      <label htmlFor="inputState">Filter by Provider</label>
                      <select className="form-control" value={state.provider} onChange={handleChange} id="provider" name="provider">
                      <option selected>Choose...</option>
                        <option value={''}>None</option>
                        <option value={'1'}>BOMAID</option>
                        <option value={'2'}>BPOMAS</option>
                        <option value={'3'}>PULA</option>
                        <option value={'4'}>BOTSOGO</option>
                        <option value={'5'}>BDF</option>
                        <option value={'6'}>BPOMAS HIGH</option>
                        <option value={'7'}>BPOMAS STANDARD</option>
                        <option value={'8'}>PULA DELUXE</option>
                        <option value={'9'}>PULA STANDARD</option>
                      </select>
                    </div>

                <div className="form-group col-md-3" >
                  <label>Click here to Filter</label>
                  <button onClick={searchInvoice} className="btn btn-small btn-info">Search Table</button>
                </div>
                </div>
                {showxmlButton && (
                  <button onClick={printAndDownloadXML}  className="btn btn-small btn-info">Download XML for selected</button>
                )}
                </div>
                <div className="table-responsive mb-4 mt-4">
                
                  <table id="zero-config" className="table table-hover" style={{width: '100%'}}>
                  {invoices_info == null ? <Spinner animation="border" /> : ""}
                  <DataTableExtensions {...tableData}>
                                <DataTable className=""
                                  columns={columns}
                                  data={data}
                                  noHeader
                                  defaultSortField="id"
                                  defaultSortAsc={false}
                                  pagination
                                  exportHeaders= {true}
                                  highlightOnHover
                                />
                              </DataTableExtensions>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      <Footer/>
      </div>
        </div>
        </div>
        
      
               
        );
 
  };

export default Allpatient;
