import React, {useState,useEffect} from 'react';
import Select from 'react-select';
import axios from "axios";
import dayjs from "dayjs";
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { Button } from 'react-bootstrap';
import Header from '../../component/layout/header';
import Sidebar from '../../component/layout/sidebar';
import Footer from '../../component/layout/footer';
import { Editor } from '@tinymce/tinymce-react';


function Consultation(){
  if (!localStorage.getItem('sl_med_tokenize')) {
    console.log('no auth token set');
    window.location.href="/";
  } else {
        console.log('good token. Log in.')
    }
  const urlParams = new URLSearchParams(window.location.search);
  const myidd = urlParams.get('myidd');
  const patients_name = urlParams.get('patients_name');
  const patients_number = urlParams.get('patients_number');
  const patients_id = urlParams.get('patients_id');
  console.log(myidd);
  console.log(patients_name);
  console.log(patients_number);
  console.log(patients_id);

  const history = useHistory();
  const [notes, setNotes] = useState('');

  const handleEditorChange = (content, editor) => {
      setNotes(content);
  };


  const MySwal = withReactContent(Swal)
  const [state , setState] = useState({
    patientName: patients_name,
    patientNo: patients_number,
    drug1: '',
    drug2: '',
    drug3: '',
    drug4: '',
    drug5: '',
    drug6: '',
    drugweight1: '',
    drugweight2: '',
    drugweight3: '',
    drugweight4: '',
    drugweight5: '',
    drugweight6: '',
    qty1: '',
    qty2: '',
    qty3: '',
    qty4: '',
    qty5: '',
    qty6: '',
    notes: ''
})

const handleChange = (e) => {
  const {id , value} = e.target   
  setState(prevState => ({
      ...prevState,
      [id] : value
  }))
} 
 

const token = localStorage.getItem('sl_med_tokenize');
const headers2 = { 
  headers: {
    'AccessToken': JSON.parse(token),
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': "*",
  }
};

const business_name = localStorage.getItem('sl_med_businessname')
const first_name = localStorage.getItem('sl_med_firstname')

const goBack = (e) => {
  history.goBack();
  // window.location.href='profile'
}

const [isVisible, setIsVisible] = useState(false);

  // Toggle function
  const toggleVisibility = (e) => {
    e.preventDefault()
    setIsVisible(!isVisible);
  };




const handlenewConsultation = (e) => {
  e.preventDefault();
  if (!state.patientName || !state.patientNo) {
    MySwal.fire({
      title: <p>Please enter patient name and Patient Number</p>,
      didOpen: () => {
      },
    }).then(() => {
    });
  }else{
    //  const dashURL = "http://localhost:9550/api/consultation";
     const dashURL = "https://remitaid.starlightsoftware.co.bw/api/consultation";
     const doctor_id = localStorage.getItem("sl_med_id"); 
     const postData = {
      'patient_id': patients_id, 
      'patient_number': patients_number,
      'patient_name': patients_name,
      'doctor_id': doctor_id,
      'notes': notes.level.content,
      'prescription_drug_1': state.drug1,
      'prescription_drug_2': state.drug2,
      'prescription_drug_3': state.drug3,
      'prescription_drug_4': state.drug4,
      'prescription_drug_5': state.drug5,
      'prescription_drug_6': state.drug6,
      'prescription_weight_1': state.drugweight1,
      'prescription_weight_2': state.drugweight2,
      'prescription_weight_3': state.drugweight3,
      'prescription_weight_4': state.drugweight4,
      'prescription_weight_5': state.drugweight5,
      'prescription_weight_6': state.drugweight6,
      'is_active': 1,
      'qty1': state.qty1, 
      'qty2': state.qty2,
      'qty3': state.qty3,
      'qty4': state.qty4,
      'qty5': state.qty5,
      'qty6': state.qty6,
    }
    console.log(postData);
     MySwal.fire({
         title: <p>please wait...</p>,
         didOpen: () => {
           MySwal.showLoading()
           axios.post(dashURL, postData, headers2)
         .then((response) => {
           console.log("response received: ", response);
           Swal.close()
           Swal.fire({
             title: "Successful",
             text: "Consultation Recorded successfully",
             icon: "success",
             showCancelButton: false,
             confirmButtonText: "Okay",
             closeOnCancel: true,
           }).then(result => {
         window.location.reload(false);
           })
           })
           .catch((err) => {
           Swal.close()
             console.log("AXIOS ERROR: ", err);
             if(!err.response){
             return MySwal.fire(err.message)
             }else{
             return MySwal.fire(err.response.data.message)
             }
           })
         },
       }).then(() => {
       })
 }
};
    
  
  
      return (
        <div>
        <div className="header-container fixed-top">
        <Header/>
        </div>
        <div className="main-container" id="container">
        <Sidebar/>



          <div id="content" className="main-content">
        <div className="layout-px-spacing">
          <div className="page-header">
            <div className="page-title">
              <h3>New Consultation</h3>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<p ><button onClick={goBack} style={{float: 'right !important'}} className="btn btn-small btn-danger">Go back</button></p>
            {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<p ><button onClick={goBack} style={{float: 'right !important'}} className="btn btn-small btn-info">Print</button></p> */}

          </div>
          <div className="card row layout-top-spacing">
            <div id="flFormsGrid" className="col-lg-12 layout-spacing">
              <div className="widget-content widget-content-area">
                <div className="row">
                  <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                    <h4>Create new</h4>
                  </div>                                                                        
                </div>
                <form>
                  <div className="form-row mb-12">
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Patient Number</label>
                      <input type="text" className="form-control" disabled value={state.patientNo} onChange={handleChange} id="patientNo" name="patientNo" />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputState">Patient Name</label>
                      <input type="text" className="form-control" disabled value={state.patientName} onChange={handleChange} id="patientName" name="patientName" />
                    </div>
                    <div className="form-group col-md-12">
                        <label htmlFor="inputPassword4">Enter Notes</label>
                      <Editor
                          apiKey='tuqautil0uyp48yh9xfw9wvqhyrdmkhg1x5cx03z2erintm7'
                          init={{
                            plugins: ' table',
                            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                            tinycomments_mode: 'embedded',
                            height: 600,
                            tinycomments_author: 'SLMED',
                          
                            ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
                            
                          }}
                          onChange={handleEditorChange}
                          value={notes}
                          // initialValue="Enter notes"
                        />
                    </div>
                    <br/>

                    <br/>
                    <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                      <h4 className='text-primary'> Prescription</h4>
                       <button style={{float: 'right'}} onClick={toggleVisibility} className='btn btn-primary'>
                       {isVisible ? 'Hide More Fields' : 'Show More Fields'}
                       </button>
                       <br/>
                       <br/>
                       <br/>
                       <br/>
                      </div>  
                    <div className="form-group col-md-4">
                    {/* <Select className="react-select-container" classNamePrefix="react-select"  value={state.gender}  onChange={handleChange} options={[{ value: 'Male', label: 'Male' }, { value: 'Female', label: 'Female' },  ]}/> */}
                      <label htmlFor="inputState"> Drug name</label>
                      <input type="text" className="form-control" value={state.drug1} onChange={handleChange} id="drug1" name="drug1" />
                    </div>
                    <div className="form-group col-md-4">
                    <label for="drugWeight">Drug Weight (g/mg)</label>
                      <input type="text" className="form-control" value={state.drugweight1} onChange={handleChange} id="drugweight1" name="drugweight1" />
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="inputState">Quantity</label>
                      <input type="text" className="form-control" value={state.qty1} onChange={handleChange} id="qty1" name="qty1" />
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="inputState"> Drug name</label>
                      <input type="text" className="form-control" value={state.drug2} onChange={handleChange} id="drug2" name="drug2" />
                    </div>
                    <div className="form-group col-md-4">
                    <label for="drugWeight">Drug Weight  (g/mg)</label>
                      <input type="text" className="form-control" value={state.drugweight2} onChange={handleChange} id="drugweight2" name="drugweight2" />
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="inputState">Quantity</label>
                      <input type="text" className="form-control" value={state.qty2} onChange={handleChange} id="qty2" name="qty2" />
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="inputState"> Drug name</label>
                      <input type="text" className="form-control" value={state.drug3} onChange={handleChange} id="drug3" name="drug3" />
                    </div>
                    <div className="form-group col-md-4">
                    <label for="drugWeight">Drug Weight  (g/mg)</label>
                      <input type="text" className="form-control" value={state.drugweight3} onChange={handleChange} id="drugweight3" name="drugweight3" />
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="inputState">Quantity</label>
                      <input type="text" className="form-control" value={state.qty3} onChange={handleChange} id="qty3" name="qty3" />
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="inputState"> Drug name</label>
                      <input type="text" className="form-control" value={state.drug4} onChange={handleChange} id="drug4" name="drug4" />
                    </div>
                    <div className="form-group col-md-4">
                    <label for="drugWeight">Drug Weight  (g/mg)</label>
                      <input type="text" className="form-control" value={state.drugweight4} onChange={handleChange} id="drugweight4" name="drugweight4" />
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="inputState">Quantity</label>
                      <input type="text" className="form-control" value={state.qty4} onChange={handleChange} id="qty4" name="qty4" />
                    </div>
                    {isVisible && (
                      <>
                    <div className="form-group col-md-4">
                      <label htmlFor="inputState"> Drug name</label>
                      <input type="text" className="form-control" value={state.drug5} onChange={handleChange} id="drug5" name="drug5" />
                    </div>
                    <div className="form-group col-md-4">
                    <label for="drugWeight">Drug Weight  (g/mg)</label>
                      <input type="text" className="form-control" value={state.drugweight5} onChange={handleChange} id="drugweight5" name="drugweight5" />
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="inputState">Quantity</label>
                      <input type="text" className="form-control" value={state.qty5} onChange={handleChange} id="qty5" name="qty5" />
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="inputState"> Drug name</label>
                      <input type="text" className="form-control" value={state.drug6} onChange={handleChange} id="drug6" name="drug6" />
                    </div>
                    <div className="form-group col-md-4">
                    <label for="drugWeight">Drug Weight  (g/mg)</label>
                      <input type="text" className="form-control" value={state.drugweight6} onChange={handleChange} id="drugweight6" name="drugweight6" />
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="inputState">Quantity</label>
                      <input type="text" className="form-control" value={state.qty6} onChange={handleChange} id="qty6" name="qty6" />
                    </div>
                    </>
                     )}
                   
                  </div>
                  </form>
                  <button onClick={handlenewConsultation} className="btn btn-primary">Submit </button>
              </div>
            </div>
          </div>
         <Footer/>
        </div>
      </div>
        </div>
        </div>
        );
 
  };

export default Consultation;
