const icd10Codes  = [
    { value: 'K00 Anodontia', label: 'K00 Anodontia' },  
    { value: 'K00.1 Supernumary teeth', label: 'K00.1 Supernumary teeth' }, 
    { value: 'K00.2 Abnormalities in size', label: 'K00.2 Abnormalities in size' }, 
    { value: 'K00.3 Mottled Teeth', label: 'K00.3 Mottled Teeth'}, 
    { value: 'K00.4 Disturbances in tooth formation', label: 'K00.2 Disturbances in tooth formation'}, 
    { value: 'K00.5 Hereditary disturbance', label: 'K00.5 Hereditary disturbance'}, 
    { value: 'K00.6 Disturbances in tooth eruption', label: 'Disturbances in tooth eruption'}, 
    { value: 'K00.7 Teething syndrome', label: 'K07.Teething syndrome'}, 
    { value: 'K00.8 Other disorders of tooth development', label: 'K00.8 Other disorders of tooth development'},
    { value: 'K00.9 Disorder of tooth development', label: 'K00.9 Disorder of tooth development'},  
    { value: 'K01.0 Embedded teeth', label: 'K01.0 Embedded teeth'}, 
    { value: 'K01.1 Impacted teeth', label: 'K01.1 Impacted teeth'}, 
    { value: 'K02 Dental caries',  label: 'K02 Dental caries'}, 
    { value: 'K02.0 Caries limited to enamel', label: 'K02.0 Caries limited to enamel'}, 
    { value: 'K02.1 Caries of dentine', label: 'K02.1 Caries of dentine'}, 
    { value: 'K02.2 Caries of cementum', label: 'K02.2 Caries of cementum'}, 
    { value: 'K02.3 Arrested dental caries', label: 'K02.3 Arrested dental caries'}, 
    { value: 'K02.4 Odontoclasia', label: 'K02.4 Odontoclasia'}, 
    { value: 'K02.5 Caries with pulp exposure ', label: 'K02.5 Caries with pulp exposure '}, 
    { value: 'K02.8 Other dental caries', label: 'K02.8 Other dental caries'}, 
    { value: 'K03.0 Excessive attrition of teeth', label: 'K03.0 Excessive attrition of teeth'}, 
    { value: 'K03.1 Abrasion of teeth', label: 'K03.1 Abrasion of teeth'}, 
    { value: 'K03.2 Erosion of teeth', label: 'K03.2 Erosion of teeth'}, 
    { value: 'K03.3 Pathological resorption of teeth', label: 'K03.3 Pathological resorption of teeth'}, 
    { value: 'K03.4 Hypercementosis', label: 'K03.4 Hypercementosis'}, 
    { value: 'K03.5 Ankylosis of teeth', label: 'K03.5 Ankylosis of teeth'}, 
    { value: 'K03.6 Deposits [accretions] on teeth', label: 'K03.6 Deposits [accretions] on teeth'}, 
    { value: 'K03.7 Posteruptive colour changes of dental hard tissues', label: 'K03.7 Posteruptive colour changes of dental hard tissues'}, 
    { value: 'K03.8 Other specified diseases of hard tissues of teeth', label: 'K03.8 Other specified diseases of hard tissues of teeth'}, 
    { value: 'K03.9 Disease of hard tissues of teeth', label: 'K03.9 Disease of hard tissues of teeth'}, 
    { value: 'K04.0 Diseases of pulp and periapical tissues', label: 'K04.0 Diseases of pulp and periapical tissues'}, 
    { value: 'K04.1 Necrosis of pulp', label: 'K04.1 Necrosis of pulp'}, 
    { value: 'K04.2 Pulp degeneration ', label: 'K04.2 Pulp degeneration '}, 
    { value: 'K04.3 Abnormal hard tissue formation in pulp', label: 'K04.3 Abnormal hard tissue formation in pulp'}, 
    { value: 'K04.4 Acute apical periodontitis of pulpal origin', label: 'K04.4 Acute apical periodontitis of pulpal origin'}, 
    { value: 'K04.5 Chronic apical periodontitis', label: 'K04.5 Chronic apical periodontitis'}, 
    { value: 'K04.6 Periapical abscess with sinus ', label: 'K04.6 Periapical abscess with sinus '}, 
    { value: 'K04.7 Periapical abscess without sinus', label: 'K04.7 Periapical abscess without sinus'}, 
    { value: 'K04.8 Radicular cyst', label: 'K04.8 Radicular cyst'}, 
    { value: 'K04.9 Other diseases of pulp tissues', label: 'K04.9 Other diseases of pulp tissues'}, 
    { value: 'K05.0 Acute gingivitis', label: 'K05.0 Acute gingivitis'}, 
    { value: 'K05.1 Chronic gingivitis', label: 'K05.1 Chronic gingivitis'}, 
    { value: 'K05.2 Acute periodontitis', label: 'K05.2 Acute periodontitis'}, 
    { value: 'K05.3 Chronic periodontitis', label: 'K05.3 Chronic periodontitis'}, 
    { value: 'K05.4 Periodontosis', label: 'K05.4 Periodontosis'}, 
    { value: 'K05.5 Other periodontal diseases', label: 'K05.5 Other periodontal diseases'}, 
    { value: 'K06.0 Gingival recession', label: 'K06.0 Gingival recession'}, 
    { value: 'K06.1 Gingival recession', label: 'K06.1 Gingival recession'}, 
    { value: 'K06.2 Gingival and edentulous alveolar ridge lesions associated with trauma', label: 'K06.2 Gingival and edentulous alveolar ridge lesions associated with trauma'}, 
    { value: 'K06.8 Other specified disorders of gingiva', label: 'K06.8 Other specified disorders of gingiva'}, 
    { value: 'K06.9 Disorder of gingiva and edentulous alveolar ridge', label: 'K06.9 Disorder of gingiva and edentulous alveolar ridge'}, 
    { value: 'K07.0 Major anomalies of jaw size', label: 'K07.0 Major anomalies of jaw size'}, 
    { value: 'K07.1 Anomalies of jaw-cranial base relationship', label: 'K07.1 Anomalies of jaw-cranial base relationship'}, 
    { value: 'K07.2 Anomalies of dental arch relationship', label: 'K07.2 Anomalies of dental arch relationship'}, 
    { value: 'K07.3 Anomalies of tooth position Crowding', label: 'K07.3 Anomalies of tooth position Crowding'}, 
    { value: 'K07.4 Malocclusion', label: 'K07.4 Malocclusion'}, 
    { value: 'K07.5 Dentofacial functional abnormalities ', label: 'K07.5 Dentofacial functional abnormalities '}, 
    { value: 'K07.6 Temporomandibular joint disorders', label: 'K07.6 Temporomandibular joint disorders'}, 
    { value: 'K07.8 Other dentofacial anomalies', label: 'K07.8 Other dentofacial anomalies'}, 
    { value: 'K07.9 Dentofacial anomaly', label: 'K07.9 Dentofacial anomaly'}, 
    { value: 'K08.0 Exfoliation of teeth due to systemic causes', label: 'K08.0 Exfoliation of teeth due to systemic causes'}, 
    { value: 'K08.1 Loss of teeth due to accident, extraction or local periodontal disease', label: 'K08.1 Loss of teeth due to accident, extraction or local periodontal disease'}, 
    { value: 'K08.2 Atrophy of edentulous alveolar ridge', label: 'K08.2 Atrophy of edentulous alveolar ridge'},
    { value: 'K08.3 Retained dental root', label: 'K08.3 Retained dental root'}, 
    { value: 'K08.8 Other specified disorders of teeth and supporting structures', label: 'K08.8 Other specified disorders of teeth and supporting structures'}, 
    { value: 'K09.0 Developmental odontogenic cysts', label: 'K09.0 Developmental odontogenic cysts'}, 
    { value: 'K09.1 Developmental (nonodontogenic) cysts of oral region', label: 'K09.1 Developmental (nonodontogenic) cysts of oral region'}, 
    { value: 'K09.2 Other cysts of jaw', label: 'K09.2 Other cysts of jaw'}, 
    { value: 'K09.8 Other cysts of oral region', label: 'K09.8 Other cysts of oral region'}, 
    { value: 'K09.9 Cyst of oral region', label: 'K09.9 Cyst of oral region'}, 
    { value: 'K10.0 Developmental disorders of jaws', label: 'K10.0 Developmental disorders of jaws'}, 
    { value: 'K10.1 Giant cell granuloma, central ', label: 'K10.1 Giant cell granuloma, central '}, 
    { value: 'K10.2 Inflammatory conditions of jaws', label: 'K10.2 Inflammatory conditions of jaws'}, 
    { value: 'K10.3 Alveolitis of jaws', label: 'K10.3 Alveolitis of jaws'}, 
    { value: 'K10.8 Other specified diseases of jaws', label: 'K10.8 Other specified diseases of jaws'}, 
    { value: 'K10.9 Disease of jaws, unspecified', label: 'K10.9 Disease of jaws, unspecified'}, 
    { value: 'K11.0 Atrophy of salivary gland', label: 'K11.0 Atrophy of salivary gland'}, 
    { value: 'K11.1 Hypertrophy of salivary gland', label: 'K11.1 Hypertrophy of salivary gland'}, 
    { value: 'K11.2 Sialoadenitis', label: 'K11.2 Sialoadenitis'}, 
    { value: 'K11.3 Abscess of salivary gland', label: 'K11.3 Abscess of salivary gland'}, 
    { value: 'K11.4 Fistula of salivary gland', label: 'K11.4 Fistula of salivary gland'}, 
    { value: 'K11.5 Sialolithiasis', label: 'K11.5 Sialolithiasis'}, 
    { value: 'K11.6 Mucocele of salivary gland ', label: 'K11.6 Mucocele of salivary gland '}, 
    { value: 'K11.7 Disturbances of salivary secretion', label: 'K11.7 Disturbances of salivary secretion'}, 
    { value: 'K11.8 Recurrent oral aphthae', label: 'K11.8 Recurrent oral aphthae'}, 
    { value: 'K11.9 Other diseases of salivary glands', label: 'K11.9 Other diseases of salivary glands'}, 
    { value: 'K12.0 Recurrent oral aphthae', label: 'K12.0 Recurrent oral aphthae'}, 
    { value: 'K12.1 Other forms of stomatitis', label: 'K12.1 Other forms of stomatitis'}, 
    { value: 'K12.2 Cellulitis and abscess of mouth', label: 'K12.2 Cellulitis and abscess of mouth'}, 
    { value: 'K12.3 Oral mucositis (ulcerative)', label: 'K12.3 Oral mucositis (ulcerative)'}, 
    { value: 'K13.0 Diseases of lips', label: 'K13.0 Diseases of lips'},  
    { value: 'K13.1 Cheek and lip biting', label: 'K13.1 Cheek and lip biting'},  
    { value: 'K13.2 Leukoplakia and other disturbances of oral epithelium, including tongue', label: 'K13.2 Leukoplakia and other disturbances of oral epithelium, including tongue'},  
    { value: 'K13.3 Hairy leukoplakia', label: 'K13.3 Hairy leukoplakia'},  
    { value: 'K13.4 Granuloma and granuloma-like lesions of oral mucosa', label: 'K13.4 Granuloma and granuloma-like lesions of oral mucosa'},  
    { value: 'K13.5 Oral submucous fibrosis', label: 'K13.5 Oral submucous fibrosis'},  
    { value: 'K13.6 Irritative hyperplasia of oral mucosa', label: 'K13.6 Irritative hyperplasia of oral mucosa'},  
    { value: 'K13.7 Other and unspecified lesions of oral mucosa', label: 'K13.7 Other and unspecified lesions of oral mucosa'},  
    { value: 'K14.0 Diseases of lips', label: 'K14.0 Diseases of lips'},  
    { value: 'K14.1 Geographic tongue', label: 'K14.1 Geographic tongue'},  
    { value: 'K14.2 Median rhomboid glossitis', label: 'K14.2 Median rhomboid glossitis'},  
    { value: 'K14.3 Hypertrophy of tongue papillae', label: 'K14.3 Hypertrophy of tongue papillae'},  
    { value: 'K14.4 Atrophy of tongue papillae', label: 'K14.4 Atrophy of tongue papillae'},  
    { value: 'K14.5 Plicated tongue', label: 'K14.5 Plicated tongue'},  
    { value: 'K14.6 Glossodynia', label: 'K14.6 Glossodynia'},  
    { value: 'K14.8 Other diseases of tongue', label: 'K14.8 Other diseases of tongue'},  
    { value: 'K14.9 Disease of tongue, unspecified', label: 'K14.9 Disease of tongue, unspecified'},  
  ]

  export default icd10Codes;