import React, {useState,useEffect} from 'react';
import axios from "axios";
import dayjs from "dayjs";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom';
import Header from '../../component/layout/header';
import Sidebar from '../../component/layout/sidebar';
import Footer from '../../component/layout/footer';

function Editpatient (){
  if (!localStorage.getItem('sl_med_tokenize')) {
    console.log('no auth token set');
    window.location.href="/";
  } else {
        console.log('good token. Log in.')
    }
  const history = useHistory();
  const MySwal = withReactContent(Swal)
  const first_name = localStorage.getItem('sl_med_fname');
 

const handleChange = (e) => {
  const {id , value} = e.target   
  setState(prevState => ({
      ...prevState,
      [id] : value
  }))
} 
const token = localStorage.getItem('sl_med_tokenize');
const [patient_info, setinfo] = useState([]);
const headers2 = { 
  headers: {
    'AccessToken': JSON.parse(token),
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': "*",
    // 'Access-Control-Allow-Headers': "https://instavoicing.herokuapp.com"
  }
};

const business_name = localStorage.getItem('sl_med_businessname')
const myURL = "https://remitaid.starlightsoftware.co.bw/api/fetchbyid";
const urlParams = new URLSearchParams(window.location.search);
const myidd = urlParams.get('myidd');
const doctor_id = localStorage.getItem("sl_med_id");
  const body = {
    'doctor_id':doctor_id,
    'accesstoken': JSON.parse(token),
    'id': myidd,
  }

const getmyinfo = async (e) => {
  await axios.post(myURL, body, headers2)
  .then((response) => {
    console.log("response received: ", response);
    if(!response){
      setinfo(0);
    }else{
    console.log("response received: ", response);
      console.log(response.data.data.patient_data[0]);
      setinfo(response.data.data.patient_data[0]);
      setinfo(response.data.data.patient_data[0]);
    }
  });
};

useEffect(() => {
  getmyinfo();
    // When patient_info.firstname changes, update state.patientName
    setState((prevState) => ({
      ...prevState,
      patientName: patient_info.firstname,
      patientNo: patient_info.patients_number,
      phone: patient_info.phone,
      id_num: patient_info.id_number,
      gender: patient_info.gender,
      address: patient_info.address,
      id_type: patient_info.id_type,
      email: patient_info.email,
      dob: patient_info.dob,
      provider: patient_info.provider,
      member_number: patient_info.member_number,
      relation: patient_info.relationsip,
      occupation: patient_info.occupation,
      employer: patient_info.employer,
      nok: patient_info.nok,
      nokaddress: patient_info.nokaddress,
      paddress: patient_info.paddress,
      allergies: patient_info.allergies,
      relation: patient_info.relationsip,
      principal_member: patient_info.principal_member,
      principal_member_name: patient_info.principal_member_name,
      principal_member_number: patient_info.principal_member_number,
    }));
  }, [patient_info.firstname]);

  const [state , setState] = useState({
    patientName: '',
    occupation: '',
    employer: '',
    nok: '',
    nokaddress: '',
    paddress: '',
    allergies: '',
    patientNo: '',
    phone: '',
    id_num: '',
    gender: '',
    id_type: '',
    email: '',
    dob: '',
    provider: '',
    member_number: '',
    principal_member: '',
    principal_member_name: '',
    principal_member_number: ''
})

console.log(state.patientNo) 
    

const handlenewPatient = (e) => {
  // e.preventDefault();
  console.log('op')
  if (!state.patientName || !state.patientNo) {
    MySwal.fire({
      title: <p>Please enter patient name and Patient Number</p>,
      didOpen: () => {
      },
    }).then(() => {
    });
  }else{
     const dashURL = "https://remitaid.starlightsoftware.co.bw/api/updatepatient";
    //  const dashURL = "http://localhost:9800/api/updatepatient";
     const doctor_id = localStorage.getItem("sl_med_id"); 
     const postData = {
      'doctor_id': doctor_id,
      'accesstoken': JSON.parse(token),
      'patient_id': myidd,
      'patient_number': state.patientNo,
      'firstname': state.patientName,
      'lastname': state.patientName,
      'email': state.email,
      'phone': state.phone,
      'dob': state.dob,
      'gender': state.gender,
      'id_type': state.id_type,
      'id_number': state.id_num,
      'provider': state.provider,
      'member_number': state.member_number,
      'principal_member': state.principal_member,
      'principal_member_name': state.principal_member_name,
      'principal_member_number': state.principal_member_number,
      'relationsip': state.relation,
      'occupation': state.occupation,
      'employer': state.employer,
      'nok': state.nok,
      'nokaddress': state.nokaddress,
      'paddress': state.paddress,
      'allergies': state.allergies
    }
     console.log(postData);
     MySwal.fire({
         title: <p>please wait...</p>,
         didOpen: () => {
           MySwal.showLoading()
           axios.post(dashURL, postData, headers2)
         .then((response) => {
           console.log("response received: ", response);
           Swal.close()
           Swal.fire({
             title: "Successful",
             text: "Patient Updated successfully",
             icon: "success",
             showCancelButton: false,
             confirmButtonText: "Okay",
             closeOnCancel: true,
           }).then(result => {
         window.location.reload(false);
           })
           })
           .catch((err) => {
           Swal.close()
             console.log("AXIOS ERROR: ", err);
             if(!err.response){
             return MySwal.fire(err.message)
             }else{
             return MySwal.fire(err.response.data.message)
             }
           })
         },
       }).then(() => {
       })
 }
};


const goback = (e) => {
  e.preventDefault();
console.log(myidd)

  if(!myidd){
    console.log('failed')
    return MySwal.fire('No ID for this patient').then(() => {
    })
    }else{
  window.location.href = "profile?myidd=" + myidd;
}
}

      return (
<div>
        <div className="header-container fixed-top">
        <Header/>
        </div>
        <div className="main-container" id="container">
        <Sidebar/>



          <div id="content" className="main-content">
        <div className="layout-px-spacing">
          <div className="page-header">
            <div className="page-title">
              <h3>Edit Patient Information</h3>
            </div>
          </div>
          <div className="card row layout-top-spacing">
            <div id="flFormsGrid" className="col-lg-12 layout-spacing">
              <div className="widget-content widget-content-area">
                <div className="row">
                  <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                  <button onClick={goback} className="btn btn-danger">Go Back</button>
                  <br/>
                  <br/>
                    <h6 style={{color: 'red'}}>Only change the fields you require to update</h6>
                    <br/>
                  </div>                                                                        
                </div>
                <form>
                  <div className="form-row mb-12">
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Enter Patient No.</label>
                      <input type="text" className="form-control" value={state.patientNo} disabled onChange={handleChange} id="patientNo" name="patientNo" />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Enter Patient Name</label>
                      <input type="text" className="form-control" value={state.patientName} onChange={handleChange} id="patientName" name="patientName" />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Patient Date Of Birth</label>
                      <input type="date" className="form-control" value={state.dob} onChange={handleChange} id="dob" name="dob" />
                    </div>

                    <div className="form-group col-md-6">
                      <label htmlFor="inputState">Enter Gender</label>
                      <select  className="form-control" value={state.gender} onChange={handleChange} id="gender" name="gender">
                        <option selected>Choose...</option>
                        <option value={'Male'}>Male</option>
                        <option value={'Female'}>Female</option>
                      </select>
                    </div>
                    
                   
                    <div className="form-group col-md-6">
                      <label htmlFor="inputState">Enter Identification type</label>
                      <select className="form-control" value={state.id_type} onChange={handleChange} id="id_type" name="id_type">
                        <option selected>Choose...</option>
                        <option value={'Drivers License'}>Driver License</option>
                        <option value={'passport'}>Passport</option>
                        <option value={'id_card'}>Identification card</option>
                        {/* <option>SYMPHONY</option> */}
                      </select>
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Enter Identification Number</label>
                      <input type="text" className="form-control" value={state.id_num} onChange={handleChange} id="id_num" name="id_num" />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Patient Cell Number</label>
                      <input type="number" className="form-control" value={state.phone} onChange={handleChange} id="phone" name="phone" />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Patient email</label>
                      <input type="text" className="form-control" value={state.email} onChange={handleChange} id="email" name="email" />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Patient Physical address</label>
                      <input type="text" className="form-control" value={state.address} onChange={handleChange} id="address" name="address" />
                    </div>
                  
                  <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Postal Address </label>
                      <input type="text" className="form-control" value={state.paddress} onChange={handleChange} id="paddress" name="paddress" />
                    </div>
                   <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Patient Occupation</label>
                      <input type="text" className="form-control" value={state.occupation} onChange={handleChange} id="occupation" name="occupation" />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Patient Next of kin</label>
                      <input type="text" className="form-control" value={state.nok} onChange={handleChange} id="nok" name="nok" />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Next of Kin Address</label>
                      <input type="text" className="form-control" value={state.nokaddress} onChange={handleChange} id="nokaddress" name="nokaddress" />
                    </div>
                   
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Patient allergies</label>
                      <input type="text" className="form-control" value={state.allergies} onChange={handleChange} id="allergies" name="allergies" />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputState">Select Provider</label>
                      <select className="form-control" value={state.provider} onChange={handleChange} id="provider" name="provider">
                        <option selected>Choose...</option>
                        <option value={''}>None</option>
                        <option value={'1'}>BOMAID</option>
                        <option value={'2'}>BPOMAS</option>
                        <option value={'3'}>PULA</option>
                        <option value={'4'}>BOTSOGO</option>
                        <option value={'5'}>BDF</option>
                        <option value={'6'}>BPOMAS HIGH</option>
                        <option value={'7'}>BPOMAS STANDARD</option>
                        <option value={'8'}>PULA DELUXE</option>
                        <option value={'9'}>PULA STANDARD</option>
                      </select>
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Membership No: (leave empty if none)</label>
                      <input type="text" className="form-control" value={state.member_number} onChange={handleChange} id="member_number" name="member_number" />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputState">Is this the Principal Member?</label>
                      <select className="form-control" value={state.principal_member} onChange={handleChange} id="principal_member" name="principal_member">
                        <option selected>Choose...</option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                      </select>
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Principal Member Name: (leave empty if primary)</label>
                      <input type="text" className="form-control" value={state.principal_member_name} onChange={handleChange} id="principal_member_name" name="principal_member_name" />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Principal Membership No: (leave empty if primary)</label>
                      <input type="text" className="form-control" value={state.principal_member_number} onChange={handleChange} id="principal_member_number" name="principal_member_number" />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Relationship To Member:</label>
                      <select className="form-control" value={state.relation} onChange={handleChange} id="relation" name="relation">
                        <option selected>Choose...</option>
                        <option value={'SELF'}>SELF</option>
                        <option value={'SPOUSE'}>SPOUSE</option>
                        <option value={'CHILD'}>CHILD</option>
                        <option value={'PARENT'}>PARENT</option>
                        <option value={'OTHER'}>OTHER</option>
                      </select>
                    </div>
                  </div></form>
                  <button onClick={handlenewPatient} className="btn btn-primary">Submit </button>
              </div>
            </div>
          </div>
         <Footer/>
        </div>
      </div>
        </div>
        </div>
        
      
               
        );
 
  };

export default Editpatient;
