import React, {useState} from 'react';
import Chart from 'react-apexcharts'
import ReactApexChart from 'react-apexcharts';
import axios from "axios";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom';


  function SignIn2(){

  const MySwal = withReactContent(Swal)
  let history = useHistory();
  // const baseUrl = "https://api.instavoicing.com/api/login";
  // const baseUrl = "http://localhost:9900/api/verify";
  const baseUrl = "https://remitaid.starlightsoftware.co.bw/api/verify";
  
  const [state , setState] = useState({
    confirm: '',
    current: '',
    newpassword: '',
})

const urlParams = new URLSearchParams(window.location.search);
const myidd = urlParams.get('myidd');


  var postData = {
      "doctor_id": myidd,
      "currentPassword": state.current,
      "newPassword": state.newpassword
  };
  let axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }
  };




const handleChange = (e) => {
  const {id , value} = e.target   
  setState(prevState => ({
      ...prevState,
      [id] : value
  }))
}

const handleLoginSubmit = (e) => {
  e.preventDefault();
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
console.log(state.current,state.newpassword)

  if (!state.current || !state.newpassword) {
    MySwal.fire({
      title: <p>Please enter current and new password</p>,
      didOpen: () => {
      },
    }).then(() => {
    });
  } else if(state.confirm =! state.newpassword){
    MySwal.fire({
        title: <p>New password and confirm password must match.</p>,
        didOpen: () => {
        },
      }).then(() => {
      });
        }else if(!passwordRegex.test(state.newpassword)) {
          MySwal.fire({
              title: <p>Password must contain at least one uppercase letter, one number, one special character, and be minimum 8 characters</p>,
              didOpen: () => {},
            }).then(() => {});
  }else {
    MySwal.fire({
      // title: <p>please wait...</p>,
      didOpen: () => {
        MySwal.showLoading()
        axios.post(baseUrl, postData, axiosConfig)
        .then((response) => {
          console.log("response received: ", response);
          Swal.close()
          MySwal.fire({
            title: <p>Account updated successfully</p>,
            didOpen: () => {},
          }).then(() => {
            window.location.href = "dashboard?myidd=" + myidd;
          });
            
          })
          .catch((err) => {
            Swal.close()
            console.log("AXIOS ERROR: ", err);
            return MySwal.fire(err.response.data.message)
          })
      },
    }).then(() => {
      Swal.close()
    })
  }
};

 

      return ( 
        <div className="form-container">
        <div className="form-form">
          <div className="form-form-wrap">
            <div className="form-container">
              <div className="form-content">
                <br/>
                <br/>
                <center>
                <img src='assets/img/lo.png' width={130}/>
                </center>
                <form className="text-left">
                  <div className="form">
                    <div id="username-field" className="field-wrapper input">
                      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx={12} cy={7} r={4} /></svg>
                      <input type="password" placeholder="Current Password" value={state.current} onChange={handleChange} id="current" name="current" autoComplete="off" className="form-control bg-transparent" />
                    </div>
                    <div id="password-field" className="field-wrapper input mb-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-lock"><rect x={3} y={11} width={18} height={11} rx={2} ry={2} /><path d="M7 11V7a5 5 0 0 1 10 0v4" /></svg>
                    <input type="password" placeholder="New Password" value={state.newpassword} onChange={handleChange} id="newpassword" name="newpassword" className="form-control bg-transparent" />
                    </div>
                    <div id="password-field" className="field-wrapper input mb-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-lock"><rect x={3} y={11} width={18} height={11} rx={2} ry={2} /><path d="M7 11V7a5 5 0 0 1 10 0v4" /></svg>
                    <input type="password" placeholder="Confirm New Password" value={state.confirm} onChange={handleChange} id="confirm" name="confirm" className="form-control bg-transparent" />
                    </div>
                    <div className="d-sm-flex justify-content-between">
                      <div className="field-wrapper">
                        <span>
                  <button onClick={handleLoginSubmit} className="btn btn-primary">Submit
                        </button>
                     <small><a href="/" style={{float: 'right'}}  className="forgot-pass-link">Back to Login</a></small>
                     </span>
                      </div>
                    </div>
                    <div className="field-wrapper text-center keep-logged-in">
                    </div>
                   
                  </div>
                </form>    
                                  
              </div>                    
            </div>
          </div>
        </div>
        <div className="form-image">
        
          <div className="l-image">
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
          <img src='assets/img/19836.jpg' className="fulll-width" style={{verticalAlign: 'middle', alignItems: 'center', justifyContent: 'center'}} alt="Imagess"/>
          {/* <img src='assets/img/19836.JPG' className="fulll-width" style={{verticalAlign: 'middle', alignItems: 'center', justifyContent: 'center'}} alt="Image"/> */}
          </div>
        </div>
      </div>
      );

  };

export default SignIn2;
