import React, {useState} from 'react';
import Chart from 'react-apexcharts'
import ReactApexChart from 'react-apexcharts';
import axios from "axios";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom';


  function Forgot(){

  const MySwal = withReactContent(Swal)
  let history = useHistory();
  const baseUrl = "https://remitaid.starlightsoftware.co.bw/api/reset_password";
  // const baseUrl = "http://localhost:9550/api/reset_password";

  const [state , setState] = useState({
    otp: '',
    password: '',
    confirmpassword: ''
})
  var postData = {
      "otp": state.otp,
      "password": state.password,
  };
  let axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }
  };


const handleChange = (e) => {
  const {id , value} = e.target   
  setState(prevState => ({
      ...prevState,
      [id] : value
  }))
}
  
const handleLoginSubmit = (e) => {
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  console.log(state.password);
  if (!state.otp || !state.password || !state.confirmpassword) {
    MySwal.fire({
      title: <p>Please fill all fields</p>,
      didOpen: () => {},
    }).then(() => {});
  } else if (state.password !== state.confirmpassword) {
    MySwal.fire({
      title: <p>'Password and Confirm password should match'</p>,
      didOpen: () => {},
    }).then(() => {});
  } else if (!passwordRegex.test(state.password)) {
    MySwal.fire({
      title: <p>'Password must contain at least one uppercase letter, one number, one special character, and be minimum 8 characters'</p>,
      didOpen: () => {},
    }).then(() => {});
  } else {
    MySwal.fire({
      title: <p>please wait...</p>,
      didOpen: () => {
        MySwal.showLoading();
        axios.post(baseUrl, postData, axiosConfig)
          .then((response) => {
            console.log("response received: ", response);
            Swal.close();
            MySwal.fire({
              title: <p>'Password changed successfully'</p>,
              didOpen: () => {},
            }).then(() => {
              window.location.href = "/";
            });
          })
          .catch((err) => {
            Swal.close();
            console.log("AXIOS ERROR: ", err);
            return MySwal.fire(err.response.data.message);
          });
      },
    }).then(() => {});
  }
};

 

      return ( 
        <div className="form-container">
        <div className="form-form">
          <div className="form-form-wrap">
            <div className="form-container">
              <div className="form-content">
                <br/>
                <br/>
                <center>
                <img src='assets/img/logo.png' width={180}/>
                </center>
                <form className="text-left">
                  <div className="form">
                    <div id="username-field" className="field-wrapper input">
                      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx={12} cy={7} r={4} /></svg>
                      <input type="text" placeholder="otp" value={state.otp} onChange={handleChange} id="otp" name="otp" autoComplete="off" className="form-control bg-transparent" />
                    </div>
                    <div id="username-field" className="field-wrapper input">
                      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx={12} cy={7} r={4} /></svg>
                      <input type="password" placeholder="password" value={state.password} onChange={handleChange} id="password" name="password" autoComplete="off" className="form-control bg-transparent" />
                    </div>
                    <div id="username-field" className="field-wrapper input">
                      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx={12} cy={7} r={4} /></svg>
                      <input type="password" placeholder="confirm password" value={state.confirmpassword} onChange={handleChange} id="confirmpassword" name="confirmpassword" autoComplete="off" className="form-control bg-transparent" />
                    </div>
                  </div>
                </form>  

                 <button onClick={handleLoginSubmit} className="btn btn-primary">Submit
                        </button>
                     <small><a href="/" style={{float: 'right'}}  className="forgot-pass-link">Login</a></small>  
                                  
              </div>                    
            </div>
          </div>
        </div>
        <div className="form-image">
        
          <div className="l-image">
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
          <img src='assets/img/19836.jpg' className="fulll-width" style={{verticalAlign: 'middle', alignItems: 'center', justifyContent: 'center'}} alt="Image"/>
          </div>
        </div>
      </div>
      );

  };

export default Forgot;
