import React, {useState, useEffect} from 'react';
import Chart from 'react-apexcharts'
import ReactApexChart from 'react-apexcharts';
import axios from "axios";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom';
import Header from '../../component/layout/header';
import Sidebar from '../../component/layout/sidebar';
import Footer from '../../component/layout/footer';


  function Dashboard(){
    if (!localStorage.getItem('sl_med_tokenize')) {
      console.log('no auth token set');
      window.location.href="/";
    } else {
          console.log('good token. Log in.')
      }
    
    const MySwal = withReactContent(Swal)
    const [my_info, setinfo] = useState(0);
    const token = localStorage.getItem('sl_med_tokenize');
    const [isDataAvailable, setIsDataAvailable] = useState(false);
    // const myURL = "http://localhost:9800/api/get";
    const myURL = "https://remitaid.starlightsoftware.co.bw/api/get";
    
    const headers2 = { 
      headers: {
        'AccessToken': JSON.parse(token),
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': "*",
      }
    };
    const doctor_id = localStorage.getItem("sl_med_id"); 
    const first_name = localStorage.getItem('sl_med_fname');


    const body = {
      'id':doctor_id
    }
  
    const getmyinfo = async () => {
      MySwal.showLoading()
      axios.post(myURL, body, headers2)
      .then((response) => {
        Swal.close()
        console.log("response received: ", response);
        if(!response){
          setinfo(0);
        }else{
        console.log("response received: ", response);
          setinfo(response.data.data);
          setinfo(response.data.data);
          setinfo(response.data.data);
          console.log(my_info);
          console.log(response.data.data);
          setIsDataAvailable(true);
        }
      });
      Swal.close()
    };
    const business_name = localStorage.getItem('sl_med_businessname')
  
    useEffect(() => {
      getmyinfo();
    }, []);

    // const apiData = [my_info.amounts_per_month];
    // const totalAmounts = Array(12).fill(0);
    const getMonthName = (monthNumber) => {
      const monthNames = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul',
        'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];
      return monthNames[monthNumber - 1]; 
    };


    const monthLabels = my_info ? my_info.amounts_per_month.map((entry) => getMonthName(entry.month)) : '';
    // const totalAmounts = my_info? my_info.amounts_per_month.map((entry) => entry.total_amount) : '';   
    const totalAmounts = my_info
  ? my_info.amounts_per_month.map((entry) => {
      const totalAmount = parseFloat(entry.total_amount);
      return isNaN(totalAmount) ? '' : totalAmount.toFixed(2);
    })
  : '';
 

    
  const chartData = {
    series: [
      {
        name: 'Data',
        data: totalAmounts
      }
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false
        }
      },
      xaxis: {
        categories: monthLabels
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            // Format the y-axis label to two decimal places
            return parseFloat(value).toFixed(2);
          }
        }
      },
      plotOptions: {
        bar: {
          colors: {
            ranges: [{
              from: 0,
              to: 0,
              color: '#801AAC'
            }]
          }
        }
      }
    }
  };
    
 

      return ( 
        <div>
        <div className="header-container fixed-top">
        <Header/>
        </div>
        <div className="main-container" id="container">
        <Sidebar/>


          <div id="content" className="main-content">
            <div className="layout-px-spacing">
            <div className="page-header">
              <div className="page-title">
                <h3>Home</h3>
              </div>
              <div className="right-button-container" style={{marginLeft: '100px'}}>
                <button onClick={getmyinfo}   className='btn btn-primary'>Reload page</button>
              </div>
            </div>



              <div className="row layout-top-spacing">
                <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                   <div className="widget widget-card-four">
                    <div className="widget-content">
                      <div className="w-content">
                        <div className="w-info">
                          <h6 className="value">{my_info.total_invoices}</h6>
                          <p className>Invoices created</p>
                        </div>
                        <div className>
                          <div className="w-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" /><polyline points="9 22 9 12 15 12 15 22" /></svg>
                          </div>
                        </div>
                      </div>
                      <div className="progress">
                        <div className="progress-bar bg-gradient-primSary" role="progressbar" style={{width: '100%'}} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 layout-spacing">
                  <div className="widget widget-account-invoice-two">
                    <div className="widget-content">
                      <div className="account-box">
                        <div className="info">
                          <h5 className>Pro Plan</h5>
                          <p className="inv-balance">BWP 5000</p>
                        </div>
                        <div className="acc-action">
                          <a href="javascript:void(0);">Active</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 layout-spacing">
                  <div className="widget widget-card-four">
                    <div className="widget-content">
                      <div className="w-content">
                        <div className="w-info">
                          <h6 className="value">{my_info.total_patients}</h6>
                          <p className>Patients</p>
                        </div>
                        <div className>
                          <div className="w-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" /><polyline points="9 22 9 12 15 12 15 22" /></svg>
                          </div>
                        </div>
                      </div>
                      <div className="progress">
                        <div className="progress-bar bg-gradient-secondary" role="progressbar" style={{width: '100%'}} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                  <div className="widget widget-chart-three">
                    <div className="widget-heading">
                      <div className>
                        <h5 className>Monthly Invoice report</h5>
                      </div>
                      <div className="dropdown  custom-dropdown">
                        <a className="dropdown-toggle" href="#" role="button" id="uniqueVisitors" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-more-horizontal"><circle cx={12} cy={12} r={1} /><circle cx={19} cy={12} r={1} /><circle cx={5} cy={12} r={1} /></svg>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="uniqueVisitors">
                          <a className="dropdown-item" href="javascript:void(0);">View</a>
                          <a className="dropdown-item" href="javascript:void(0);">Update</a>
                          <a className="dropdown-item" href="javascript:void(0);">Download</a>
                        </div>
                      </div>
                    </div>
                    <div className="widget-content">
                    {isDataAvailable ? (
                    <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={350} />
                    ) : (
                      <div>Loading...</div>
                    )}
                    </div>
                  </div>
                </div>
                {/* <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
                  <div className="widget widget-activity-three">
                    <div className="widget-heading">
                      <h5 className>Notifications</h5>
                    </div>
                    <div className="widget-content">
                      <div className="mt-container mx-auto">
                        <div className="timeline-line">
                          <div className="item-timeline timeline-new">
                            <div className="t-dot">
                              <div className="t-primary"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12" /></svg></div>
                            </div>
                            <div className="t-content">
                              <div className="t-uppercontent">
                                <h5>Invoice</h5>
                                <span className>27 Feb, 2023</span>
                              </div>
                              <p><span>Created</span> New Invoice</p>
                            </div>
                          </div>
                          <div className="item-timeline timeline-new">
                            <div className="t-dot">
                              <div className="t-success"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" /><polyline points="22,6 12,13 2,6" /></svg></div>
                            </div>
                            <div className="t-content">
                              <div className="t-uppercontent">
                                <h5>Mail</h5>
                                <span className>28 Feb, 2023</span>
                              </div>
                              <p>Invoice Sent via mail <a href="javascript:void(0);">to</a> Bomaid <a href="javascript:void(0);">Admin</a></p>
                            </div>
                          </div>
                          <div className="item-timeline timeline-new">
                            <div className="t-dot">
                              <div className="t-danger"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12" /></svg></div>
                            </div>
                            <div className="t-content">
                              <div className="t-uppercontent">
                                <h5>New User</h5>
                                <span className>01 Mar, 2023</span>
                              </div>
                              <p>New patient created</p>
                            </div>
                          </div>
                        </div>                                    
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
           <Footer/>
          </div>
        </div>
      </div>
      );

  };

export default Dashboard;
