import React, {useState,useEffect} from 'react';
import axios from "axios";
import dayjs from "dayjs";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom';
import Header from '../../component/layout/header';
import Sidebar from '../../component/layout/sidebar';
import Footer from '../../component/layout/footer';

function Report (){
  if (!localStorage.getItem('sl_med_tokenize')) {
    console.log('no auth token set');
    window.location.href="/";
  } else {
        console.log('good token. Log in.')
    }
  const history = useHistory();
  const MySwal = withReactContent(Swal)
  const business_name = localStorage.getItem('sl_med_businessname');
  const fname = localStorage.getItem('sl_med_fname');
  const [state , setState] = useState({
    type: '',
    start: '',
    end: '',
})

function getCurrentDate() {
  const today = new Date();
  const year = today.getFullYear();
  let month = today.getMonth() + 1;
  let day = today.getDate();

  // Add leading zero if month or day is a single digit
  if (month < 10) {
    month = '0' + month;
  }
  if (day < 10) {
    day = '0' + day;
  }

  return `${year}-${month}-${day}`;
}

const handleChange = (e) => {
    const {id , value} = e.target   
    setState(prevState => ({
        ...prevState,
        [id] : value
    }))
  }

  const gotoReport = (e) => {
    console.log(state.start,state.end)
    if(!state.type|| !state.start || !state.end){
        Swal.fire({
            title: "Error",
            text: "Please enter all fields",
            // icon: "success",
            showCancelButton: true,
            closeOnCancel: true,
            cancelButtonText: "Cancel"
          }).then(result => {
          })
        }else if(state.end<state.start){
          return MySwal.fire('End date should not be before start date ')
    }else{
      const start = state.start;
      const end = state.end;
      window.location.href = "reporttable?start=" + start + "&end=" + end;
    }
}
  


      return (
<div>
<div className="header-container fixed-top">
        <Header/>
        </div>
        <div className="main-container" id="container">
        <Sidebar/>



          <div id="content" className="main-content">
        <div className="layout-px-spacing">
          <div className="page-header">
            <div className="page-title">
              <h3> Reports</h3>
            </div>
          </div>
          <div className="card row layout-top-spacing">
            <div id="flFormsGrid" className="col-lg-12 layout-spacing">
              <div className="widget-content widget-content-area">
                <div className="row">
                  <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                    <h4>Generate Report</h4>
                  </div>                                                                        
                </div>
                <form>
                  <div className="form-row mb-12">
                  <div className="form-group col-md-6">
                      <label htmlFor="inputState">Select Report type</label>
                      <select  value={state.type} onChange={handleChange} id="type" name="type" className="form-control">
                        <option selected>Choose</option>
                        <option>All reports</option>
                        {/* <option>VAT Reports</option> */}
                        {/* <option>Consultation Reports</option> */}
                        {/* <option>Invoice Reports</option> */}
                        {/* <option>Patient Reports</option> */}
                      </select>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="inputPassword4">start date</label>
                      <input type="date"  className="form-control" value={state.start} max={getCurrentDate()} onChange={handleChange} id="start" name="start" />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="inputPassword4">end date</label>
                      <input type="date"  className="form-control" value={state.end} max={getCurrentDate()} onChange={handleChange} id="end" name="end" />
                    </div>
                  </div></form>
                <a type="submit" onClick={gotoReport} className="btn btn-primary mt-3" style={{float: 'right'}} >Submit</a>
              </div>
            </div>
          </div>
         <Footer/>
        </div>
      </div>
        </div>
        </div>
        
      
               
        );
 
  };

export default Report;
