import React, {useState} from 'react';
import Chart from 'react-apexcharts'
import ReactApexChart from 'react-apexcharts';
import axios from "axios";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom';


  function Forgot(){

  const MySwal = withReactContent(Swal)
  let history = useHistory();
  const baseUrl = "https://remitaid.starlightsoftware.co.bw/api/forgot";
  // const baseUrl = "http://localhost:9550/api/forgot";
  const [state , setState] = useState({
    email: '',
    password: '',
})
  var postData = {
      "email": state.email,
      
  };
  let axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }
  };


const handleChange = (e) => {
  const {id , value} = e.target   
  setState(prevState => ({
      ...prevState,
      [id] : value
  }))
}
  

  const handleLoginSubmit = (e) => {
    // e.prevdenDefault()
    if (!state.email ){
      MySwal.fire({
        title: <p>Please fill all fields</p>,
        didOpen: () => {
        },
      }).then(() => {
      })
    }else{
      MySwal.fire({
        title: <p>please wait...</p>,
        didOpen: () => {
          axios.post(baseUrl, postData, axiosConfig)
        .then((response) => {
          MySwal.showLoading()
          console.log("response received: ", response);
          Swal.close()
          MySwal.fire({
            title: <p>Email otp sent to your mail</p>,
            // text: <p>`Email otp sent to your mail`</p>,
            didOpen: () => {},
          }).then(() => {
            window.location.href="verify";  
          });
          })
          .catch((err) => {
            Swal.close()
            console.log("AXIOS ERROR: ", err);
            return MySwal.fire(err.response.data.message)
          })
        },
      }).then(() => {
      })
    }
  }
 

      return ( 
        <div className="form-container">
        <div className="form-form">
          <div className="form-form-wrap">
            <div className="form-container">
              <div className="form-content">
                <br/>
                <br/>
                <center>
                <img src='assets/img/logo.png' width={180}/>
                </center>
                <form className="text-left">
                  <div className="form">
                    <div id="username-field" className="field-wrapper input">
                      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx={12} cy={7} r={4} /></svg>
                      <input type="text" placeholder="Email" value={state.email} onChange={handleChange} id="email" name="email" autoComplete="off" className="form-control bg-transparent" />
                    </div>
                    
                    <div className="d-sm-flex justify-content-between">
                      <div className="field-wrapper">
                        <span>
                 
                     </span>
                      </div>
                    </div>
                  </div>
                </form>    
                <button onClick={handleLoginSubmit}  className="btn btn-primary">Submit
                        </button>
                     <small><a href="/" style={{float: 'right', fontSize: '15px', color: 'purple'}}  className="forgot-pass-link">Login</a></small>

                                  
              </div>                    
            </div>
          </div>
        </div>
        <div className="form-image">
        
          <div className="l-image">
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
          <img src='assets/img/19836.jpg' className="fulll-width" style={{verticalAlign: 'middle', alignItems: 'center', justifyContent: 'center'}} alt="Image"/>
          </div>
        </div>
      </div>
      );

  };

export default Forgot;
