import React, {useState,useEffect} from 'react';
import axios from "axios";
import dayjs from "dayjs";
import Select from 'react-select';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom';
import Header from '../../component/layout/header';
import Sidebar from '../../component/layout/sidebar';
import Footer from '../../component/layout/footer';

function Newinvoice (){
  if (!localStorage.getItem('sl_med_tokenize')) {
    console.log('no auth token set');
    window.location.href="/";
  } else {
        console.log('good token. Log in.')
    }
  const history = useHistory();
  const MySwal = withReactContent(Swal)
  const [state , setState] = useState({
    patient: '',
})

const business_name = localStorage.getItem('sl_med_businessname');
const first_name = localStorage.getItem('sl_med_fname');
const [isHidden, setIsHidden] = useState(true);

const handleChange = (e) => {
    const {id , value} = e.target   
    setState(prevState => ({
        ...prevState,
        [id] : value
    }))
  }

      localStorage.setItem('remitaid_code', '');
      localStorage.setItem('remitaid_icd', '');
      localStorage.setItem('remitaid_qty', '');
      localStorage.setItem('remitaid_teeth', '');
      localStorage.setItem('remitaid_code1', '');
      localStorage.setItem('remitaid_icd1', '');
      localStorage.setItem('remitaid_qty1', '');
      localStorage.setItem('remitaid_teeth1', '');
      localStorage.setItem('remitaid_code2', '');
      localStorage.setItem('remitaid_icd2', '');
      localStorage.setItem('remitaid_qty2', '');
      localStorage.setItem('remitaid_teeth2', '');
      localStorage.setItem('remitaid_code3', '');
      localStorage.setItem('remitaid_icd3', '');
      localStorage.setItem('remitaid_qty3', '');
      localStorage.setItem('remitaid_teeth3', '');
      localStorage.setItem('remitaid_code4', '');
      localStorage.setItem('remitaid_icd4', ''); 
      localStorage.setItem('remitaid_qty4', '');
      localStorage.setItem('remitaid_teeth4', '');
      localStorage.setItem('remitaid_code5', '');
      localStorage.setItem('remitaid_icd5', '');
      localStorage.setItem('remitaid_qty5', '');
      localStorage.setItem('remitaid_teeth5', '');


  const token = localStorage.getItem('sl_med_tokenize');
  const myURL = "https://remitaid.starlightsoftware.co.bw/api/searchPatients";
  // const myURL = "https://remitaid.starlightsoftware.co.bw/api/searchPatients";
  
  const headers2 = { 
    headers: {
      'AccessToken': JSON.parse(token),
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': "*",
    }
  };

  const [my_info, setinfo] = useState([]);
  const doctor_id = localStorage.getItem("sl_med_id");

    const body = {
      'doctor_id':doctor_id,
      'searchQuery': state.patient,
      'accesstoken': JSON.parse(token),
    }
  
    const handleSubmit = (e) => {
      const id = e.target.id;
      const patientName = e.target.getAttribute('data-patientname');
      const provider = e.target.getAttribute('data-provider');

      if(!id){
        console.log('failed')
        return MySwal.fire('No ID for this user').then(() => {
        })
        }else{
          console.log('ID:', id);
          console.log('Patient Name:', patientName);
          window.location.href = `newinvoice2`;
          localStorage.setItem('slmed_inv_patient_id', encodeURIComponent(id));
          localStorage.setItem('slmed_inv_patient_name', encodeURIComponent(patientName));
          localStorage.setItem('slmed_inv_provider', encodeURIComponent(provider));
    }}
      

    const handlenewInvoice = (e) => {
      if (!state.patient) {
        MySwal.fire({
          title: <p>Please enter patient name</p>,
          didOpen: () => {
          },
        }).then(() => {
        });
      }else {
        MySwal.fire({
          didOpen: () => {
            MySwal.showLoading()
            axios.post(myURL, body, headers2)
            .then((response) => {
            Swal.close()
              console.log("response received: ", response);
              if(!response){
                setinfo(0);
              }else{
              console.log("response received: ", response);
                setinfo(response.data.data);
                setinfo(response.data.data);
                setIsHidden(false);
                console.log(response.data.data);
              }
            });
          },
        }).then(() => {
          Swal.close()
        })
      }
    };





      return (
<div>
        <div className="header-container fixed-top">
        <Header/>
        </div>
        <div className="main-container" id="container">
        <Sidebar/>
        
          <div id="content" className="main-content">
            <div className="layout-px-spacing">
              <div className="page-header">
                <div className="page-title">
                  <h3>New invoice</h3>
                </div>
              </div>
              <div className="card row layout-top-spacing">
                <div id="flFormsGrid" className="col-lg-12 layout-spacing">
                  <div className="widget-content widget-content-area">
                    <div className="row">
                      <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                        <h4>Create new</h4>
                      </div>                                                                        
                    </div>
                    <form>
                      <div className="form-row mb-12">
                       
                        <div className="form-group col-md-7">
                          <label htmlFor="inputPassword4">Enter patient name</label>
                          <input type="text" className="form-control" value={state.patient} onChange={handleChange} id="patient" name="date" placeholder="patient name" />
                        </div>
                      </div>
                      </form>
                    <a type="submit" className="btn btn-primary mt-3" style={{float: 'right'}} onClick={handlenewInvoice}>Search</a>
                  </div>
                </div>
              </div>
              {!isHidden &&
              <div className="card row layout-top-spacing">
              <div className="table-responsive mb-4 mt-4">
                  <table id="zero-config" className="table table-hover" style={{width: '100%'}}>
                    <thead>
                      <tr>
                        <th>Patient No</th>
                        <th>Name</th>
                        <th>Medical Provider</th>
                        <th>Membership Number</th>
                        <th className="no-content">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                    {my_info.map((info, index) => (
                      <tr>
                        <td>{info.patients_number}</td>
                        <td>{info.firstname}</td>
                        <td>
                          {
                            info.provider==1 ? 
                            <span class="badge badge-danger fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">BOMAID</span>
                            : info.provider==2 
                            ? <span class="badge badge-success fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">BPOMAS</span>
                            : info.provider==3
                            ? <span class="badge badge-warning fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">PULA</span>
                            : info.provider==4
                            ? <span class="badge badge-info fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">BOTSOGO</span>
                            : info.provider==5 
                            ? <span class="badge badge-primary fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">BDF</span>
                            : info.provider == 6
                            ? <span class="badge badge-success fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">BPOMAS HIGH</span>
                            : info.provider == 7 
                            ? <span class="badge badge-success fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">BPOMAS STANDARD</span>
                            : info.provider == 8 
                            ? <span class="badge badge-warning fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">PULA DELUXE</span>
                            : info.provider == 9
                            ? <span class="badge badge-warning fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">PULA STANDARD</span>
                            : <span class="badge badge-success fw-semibold fs-9 px-2 ms-2 cursor-default ms-2"> </span>
                            }</td>
                        <td>{info.member_number}</td>
                        <td>
                     <button onClick={handleSubmit} id={info.id} data-patientname={info.firstname} data-provider={info.provider} className="btn btn-primary">select
                        </button>
                        </td>
                      </tr>
                       ))}
                    </tbody>
                  </table>
                </div>
              </div>
        }
              <Footer/>
            </div>
          </div>
        </div>
        </div>
        
      
               
        );
 
  };

export default Newinvoice;
