import React from 'react';


function logout(){
  
  if (!localStorage.getItem('sl_med_tokenize')) {
    console.log('no auth token set');
    window.location.href="/";
  } else {
    localStorage.removeItem('sl_med_tokenize');
    sessionStorage.removeItem('sl_med_tokenize');
    window.location.href="/";
  }




      return (
           <div></div>  
        );
  };

export default logout;
