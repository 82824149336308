import React, {useState,useEffect} from 'react';
import axios from "axios";
import dayjs from "dayjs";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import PrintableContent from './PrintableContent';
import { useHistory } from 'react-router-dom';
import { CSVLink, CSVDownload } from "react-csv";
import Header from '../../component/layout/header';
import Sidebar from '../../component/layout/sidebar';
import Footer from '../../component/layout/footer';


function Viewinvoice2 (){
  if (!localStorage.getItem('sl_med_tokenize')) {
    console.log('no auth token set');
    window.location.href="/";
  } else {
      }
  const history = useHistory();
  const MySwal = withReactContent(Swal)
  const [xmldataArray, setXmldataArray] = useState([]);
  const business_name = localStorage.getItem('sl_med_businessname');
  const fname = localStorage.getItem('sl_med_fname');
  const business_address = localStorage.getItem('sl_med_businessaddress');
  const practise_number = localStorage.getItem('sl_med_practise_number');
  const mypostal_address = localStorage.getItem('sl_med_businesspaddress');
  const myvat_id = localStorage.getItem('sl_med_vat_id');
  const myfax = localStorage.getItem('sl_med_fax');
  const provider_id = localStorage.getItem('slmed_inv_provider');
  const provider_name = localStorage.getItem('slmed_inv_provider_name');
  const phone = localStorage.getItem('sl_med_phone');
  const [data, setData] = useState(/* Your data array */);
  const email = localStorage.getItem('sl_med_email');
  const [loading_, setLoading] = useState(false);
  const objectToXML = require('object-to-xml');
  const token = localStorage.getItem('sl_med_tokenize');
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); 
  const day = String(currentDate.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  var domToPdf = require('react-dom-to-pdf-byaasif');
  const [dr_info, setdr] = useState([]);
  const [patient_info, setpatient] = useState([ ]);
  const [code_info, setcode] = useState([]);
  const [dr_fee, setfees] = useState([]);
  const [dentist_fee, setfdrees] = useState([]);
  const doctor_id = localStorage.getItem("sl_med_id");
  const myURL = "https://remitaid.starlightsoftware.co.bw/api/fetchpatientbyID";
  // const myURL = "http://localhost:9500/api/fetchpatientbyID";
  const codeURL="https://remitaid.starlightsoftware.co.bw/api/fetchcodes";
  // const codeURL="http://localhost:9500/api/fetchcodes";
  const feeURL="https://remitaid.starlightsoftware.co.bw/api/fetchfees";
  // const feeURL="http://localhost:9500/api/fetchfees";
  const drfeesURL = "https://remitaid.starlightsoftware.co.bw/api/fetchdrfees";
  // const drURL = "https://remitaid.starlightsoftware.co.bw/api/fetchdrfees";
  const drURL = "https://remitaid.starlightsoftware.co.bw/api/getDoctorById";

  
  const patient_id = localStorage.getItem('slmed_inv_patient_id');
  const service_date = formattedDate;
  const urlParams = new URLSearchParams(window.location.search);
  const myidd = urlParams.get('ID');
  const patientid = urlParams.get('patients_id');
  const csvFileName = 'SLMED_invoice.csv'; // Set your custom CSV file name
  const csvTitle = 'New CSV invoice'; // Set your custom title

  const invoiceData = JSON.parse(localStorage.getItem('selectedData'));
  console.log(invoiceData);

  function getCodeDescription(codeInfo) {
    if (codeInfo && codeInfo.description) {
      return codeInfo.description;
    }
    return ''; // Fallback to an empty string if description is missing.
  }

  const goBack = () => {
    history.goBack(); // Navigate back to the previous page
  };

  const getInitials = (fullName) => {
    if (!fullName) return '';
    const nameParts = fullName.trim().split(/\s+/);
    const initials = nameParts.map(part => part.charAt(0).toUpperCase()).join('');
    return initials;
  }

  const getpatientinfo = async (e) => {
    axios.post(myURL, body, headers2)
    .then((response) => {
      // console.log("response received: ", response);
      if(!response){
        setpatient(0);
      }else{
      setpatient(response.data.data[0]);
      setpatient(response.data.data[0]);
      // console.log(response.data.data);
      }
    });
  };

  const patient_data = JSON.parse(invoiceData.patient_information);
console.log(patient_data)


  function getdrFeeDescription(feeInfo) {
    if (feeInfo && feeInfo.fee) {
      return feeInfo.fee;
    }
    return ''; // Fallback to an empty string if description is missing.
  }
  const csvData = [ 
    
    // ["Invoice_Date", "Doctor_Name", "Clinic_Name", "patient_name", "Medical_Aid", "Scheme_Number", "Code", "Description", "ICD_10_codes", "Quantity", "Scheme_Fee"],
    // [invoiceData.created_at, fname, business_name, invoiceData.patient_name, provider_name, patient_info.member_number, invoiceData.code, getCodeDescription(code_info[0]), invoiceData.ICD_10, invoiceData.qty, Number(getdrFeeDescription(dr_fee[0]))*Number(invoiceData.qty)*0.9],
    ["Transaction Number", "Member Number", "Patient Name", "Patient initials", "Service date", "DOB", "Doctor Number", "Tarrif Code", "Scheme Fee/ Claimed", "QTY", "ICD Code", "Mouth Part ID", "Invoice Number", "Description", "Clinic Name", "Administrator"],
    ['1',patient_data.membership_no, patient_data.invpatient_name, getInitials(invoiceData.patient_name), invoiceData.created_at, patient_data.invDOB, practise_number, invoiceData.code, invoiceData.scheme_fee1, invoiceData.qty,invoiceData.ICD_10,invoiceData.teeth,invoiceData.invoice_number,invoiceData.service,invoiceData.business_name,patient_data.medical_aid_scheme],
  ];
  
  if (invoiceData.code_1) {
    csvData.push(['2',patient_data.membership_no, patient_data.invpatient_name, getInitials(invoiceData.patient_name), invoiceData.created_at, patient_data.invDOB, practise_number, invoiceData.code1, invoiceData.scheme_fee2, invoiceData.qty_1,invoiceData.ICD_10_1,invoiceData.teeth_1,invoiceData.invoice_number,invoiceData.service1,invoiceData.business_name,patient_data.medical_aid_scheme]);
  }
  
  if (invoiceData.code_2) {
    csvData.push(['3',patient_data.membership_no, patient_data.invpatient_name, getInitials(invoiceData.patient_name), invoiceData.created_at, patient_data.invDOB, practise_number, invoiceData.code2, invoiceData.scheme_fee3, invoiceData.qty_2,invoiceData.ICD_10_2,invoiceData.teeth_2,invoiceData.invoice_number,invoiceData.service2,invoiceData.business_name,patient_data.medical_aid_scheme]);
  }
  
  if (invoiceData.code_3) {
    csvData.push(['4',patient_data.membership_no, patient_data.invpatient_name, getInitials(invoiceData.patient_name), invoiceData.created_at, patient_data.invDOB, practise_number, invoiceData.code3, invoiceData.scheme_fee4, invoiceData.qty_3,invoiceData.ICD_10_3,invoiceData.teeth_3,invoiceData.invoice_number,invoiceData.service3,invoiceData.business_name,patient_data.medical_aid_scheme]);
  }
  
  if (invoiceData.code_4) {
    csvData.push(['5',patient_data.membership_no, patient_data.invpatient_name, getInitials(invoiceData.patient_name), invoiceData.created_at, patient_data.invDOB, practise_number, invoiceData.code4, invoiceData.scheme_fee5, invoiceData.qty_4,invoiceData.ICD_10_4,invoiceData.teeth_4,invoiceData.invoice_number,invoiceData.service4,invoiceData.business_name,patient_data.medical_aid_scheme]);
  }
  if (invoiceData.code_6) {
    csvData.push(['6',patient_data.membership_no, patient_data.invpatient_name, getInitials(invoiceData.patient_name), invoiceData.created_at, patient_data.invDOB, practise_number, invoiceData.code5, invoiceData.scheme_fee6, invoiceData.qty_5,invoiceData.ICD_10_5,invoiceData.teeth_5,invoiceData.invoice_number,invoiceData.service5,invoiceData.business_name,patient_data.medical_aid_scheme]);
  }
  


  const generatePDF = () => {
    var element = document.getElementById('mycontent');
    var options = {
      filename: 'SLMED_Invoice.pdf'
    };
    domToPdf(element, options, function() {
      console.log('done');
    });
  };

  // console.log('Type:', typeof invoiceData.doctor_fee1);

  const [state , setState] = useState({
    procedure: '',
    patient_fee: '',
    scheme_fee: '',
    doctor_fee: '',
    vat: '',
    procedure1: '',
    patient_fee1: '',
    scheme_fee1: '',
    doctor_fee1: '',
    vat1: '',
    tot_amount: '',
    vat_amount: '',
    tot_vatamount: '',
    patientdue: '',
    claim_amount: '', 
    tot_patient_fee: '', 
    tot_scheme_fee: '', 
    tot_doctor_fee: '', 
    tot_patient_fee1: '', 
    tot_scheme_fee1: '', 
    tot_doctor_fee1: '', 
    discount: 0,
    patientduedisc: '',
})

const coder = {
  accesstoken: JSON.parse(token),
  code1:  invoiceData.code,
  code2:  invoiceData.code_1,
  code3:  invoiceData.code_2,
  code4:  invoiceData.code_3,
  provider_id: provider_id,
  doctor_id: invoiceData.doctor_id
};

      const body = {
        'doctor_id':doctor_id,
        'id':invoiceData.patient_id,
        'accesstoken': JSON.parse(token),
      }

      // console.log(body)

      const headers2 = { 
        headers: {
          'AccessToken': JSON.parse(token),
          'Content-Type': 'application/json;charset=UTF-8',
          'Access-Control-Allow-Origin': "*",
        }
      };

     

      const getdrinfo = async (e) => {
        axios.post(drURL, body, headers2)
        .then((response) => {
          console.log("response received: ", response);
          if(!response){
            setdr(0);
          }else{
          setdr(response.data.data[0]);
          setdr(response.data.data[0]);
          console.log(response.data.data);
          }
        });
      };


      const getcodeinfo = async (e) => {
        setLoading(true);
        try {
          const response = await axios.post(codeURL, coder, headers2);
          console.log("response received: ", response);
          if (!response.data.data || response.data.data.length === 0) {
            setcode([]); // Update with an empty array or appropriate value
          } else {
            setLoading(true);
            console.log("response received: ", response);
            setcode(response.data.data.codes); // Update the state with response data
            console.log(code_info); // This might not log the updated value immediately
          }
          const response2 = await axios.post(feeURL, coder, headers2);
      
          console.log("response received: ", response2);
      
          if (!response.data.data || response2.data.data.length === 0) {
            setfees(0);
          } else {
            console.log("response received: ", response2.data.data.fees);
            setfees(response2.data.data.fees);
            console.log(dr_fee);
          }
  
          const response3 = await axios.post(drfeesURL, coder, headers2);
      
          console.log("response received: ", response3);
      
          if (!response3.data.data || response3.data.data.length === 0) {
            setfdrees(0);
          } else {
            console.log("response received: ", response2.data.data.fees);
            setfdrees(response2.data.data.fees);
            console.log(dentist_fee);
          }
        } catch (error) {
          console.error("An error occurred: ", error);
        }
      };

      


      let provider = '';

      if (invoiceData.provider === '1') {
        provider = 'BOMAID';
      } else if (invoiceData.provider === '2') {
        provider = 'BPOMAS';
      } else if (invoiceData.provider === '3') {
        provider = 'PULA';
      } else if (invoiceData.provider === '4') {
        provider = 'BOTSOGO';
      } else if (invoiceData.provider === '5') {
        provider = 'BDF';
      }else if (invoiceData.provider==='6'){
        provider = "BPOMAS HIGH"
      }else if(invoiceData.provider==='7'){
        provider = "BPOMAS STANDARD"
      }else if(invoiceData.provider==='8'){
        provider = "PULA DELUXE"
      }else if(invoiceData.provider==='9'){
        provider = "PULA STANDARD"
      }

      const totalDrfee =
        (Number(invoiceData.doctor_fee1) || 0) +
        (Number(invoiceData.doctor_fee2) || 0) +
        (Number(invoiceData.doctor_fee3) || 0) +
        (Number(invoiceData.doctor_fee4) || 0) +
        (Number(invoiceData.doctor_fee5) || 0) +
        (Number(invoiceData.doctor_fee6) || 0) ;

      const totalScheme =  
      (Number(invoiceData.scheme_fee1) || 0) +
      (Number(invoiceData.scheme_fee2) || 0) +
      (Number(invoiceData.scheme_fee3) || 0) +
      (Number(invoiceData.scheme_fee4) || 0) +
      (Number(invoiceData.scheme_fee5) || 0) +
      (Number(invoiceData.scheme_fee6) || 0);

      const totalpatient =  
      (Number(invoiceData.patient_fee1) || 0) +
      (Number(invoiceData.patient_fee2) || 0) +
      (Number(invoiceData.patient_fee3) || 0) +
      (Number(invoiceData.patient_fee4) || 0) +
      (Number(invoiceData.patient_fee5) || 0) +
      (Number(invoiceData.patient_fee6) || 0);

      const totalVat =  
      (Number(invoiceData.vat) || 0) +
      (Number(invoiceData.vat1) || 0) +
      (Number(invoiceData.vat2) || 0) +
      (Number(invoiceData.vat3) || 0) +
      (Number(invoiceData.vat4) || 0) +
      (Number(invoiceData.vat5) || 0);

      const totalAmount = (Number(totalDrfee)+Number(totalScheme)+Number(totalpatient));

      const claimDetails = [
        {
          uniqueSeq: '1',
          lineType: 'S',
          tariffCode: invoiceData.code,
          tariffDesc: invoiceData.service,
          serviceDate: formattedDate,
          units: invoiceData.qty,
          claimed: (
            Number(invoiceData.patient_fee1) +
            Number(invoiceData.scheme_fee1)
          ).toFixed(2),
          benefit: Number(invoiceData.scheme_fee1).toFixed(2),
          mouthpartid: invoiceData.teeth,
          diagnosis: {
            primSec: 'P',
            diagType: 'Diagnosis',
            diagCode: invoiceData.ICD_10.split(' ')[0],
          },
        },
        invoiceData.ICD_10_1
          ? {
              uniqueSeq: '2',
              lineType: 'S',
              tariffCode: invoiceData.code1,
              tariffDesc: invoiceData.service1,
              serviceDate: formattedDate,
              units: invoiceData.qty_1,
              claimed: (
                Number(invoiceData.patient_fee2) +
                Number(invoiceData.scheme_fee2)
              ).toFixed(2),
              benefit: Number(invoiceData.scheme_fee2).toFixed(2),
              mouthpartid: invoiceData.teeth_1,
              diagnosis: {
                primSec: 'P',
                diagType: 'Diagnosis',
                diagCode: invoiceData.ICD_10_1.split(' ')[0],
              },
            }
          : null,
          invoiceData.ICD_10_2
          ? {
              uniqueSeq: '3',
              lineType: 'S',
              tariffCode: invoiceData.code2,
              tariffDesc: invoiceData.service2,
              serviceDate: formattedDate,
              units: invoiceData.qty_2,
              claimed: (
                Number(invoiceData.patient_fee3) +
                Number(invoiceData.scheme_fee3)
              ).toFixed(2),
              benefit: Number(invoiceData.scheme_fee3).toFixed(2),
              mouthpartid: invoiceData.teeth_2,
              diagnosis: {
                primSec: 'P',
                diagType: 'Diagnosis',
                diagCode: invoiceData.ICD_10_2.split(' ')[0],
              },
            }
          : null,
          invoiceData.ICD_10_3
          ? {
              uniqueSeq: '4',
              lineType: 'S',
              tariffCode: invoiceData.code3, 
              tariffDesc: invoiceData.service3,
              serviceDate: formattedDate,
              units: invoiceData.qty_3,
              claimed: (
                Number(invoiceData.patient_fee4) +
                Number(invoiceData.scheme_fee4)
              ).toFixed(2),
              benefit: Number(invoiceData.scheme_fee4).toFixed(2),
              mouthpartid: invoiceData.teeth_3,
              diagnosis: {
                primSec: 'P',
                diagType: 'Diagnosis',
                diagCode: invoiceData.ICD_10_3.split(' ')[0],
              },
            }
          : null,
          invoiceData.ICD_10_4
          ? {
              uniqueSeq: '5',
              lineType: 'S',
                  tariffCode: invoiceData.code4, 
              tariffDesc: invoiceData.service4,
              serviceDate: formattedDate,
              units: invoiceData.qty_4,
              claimed: (
                Number(invoiceData.patient_fee5) +
                Number(invoiceData.scheme_fee5)
              ).toFixed(2),
              benefit: Number(invoiceData.scheme_fee5).toFixed(2),
              mouthpartid: invoiceData.teeth_4,
              diagnosis: {
                primSec: 'P',
                diagType: 'Diagnosis',
                diagCode: invoiceData.ICD_10_4.split(' ')[0],
              },
            }
          : null,
          invoiceData.ICD_10_5
          ? {
              uniqueSeq:'6',
              lineType: 'S',
                  tariffCode: invoiceData.code5, 
              tariffDesc: invoiceData.service5,
              serviceDate: formattedDate,
              units: invoiceData.qty_5,
              claimed: (
                Number(invoiceData.patient_fee6) +
                Number(invoiceData.scheme_fee6)
              ).toFixed(2),
              benefit: Number(invoiceData.scheme_fee6).toFixed(2),
              mouthpartid: invoiceData.teeth_5,
              diagnosis: {
                primSec: 'P',
                diagType: 'Diagnosis',
                diagCode: invoiceData.ICD_10_5.split(' ')[0],
              },
            }
          : null
      ];
      const filteredClaimDetails = claimDetails.filter((claimDetail) => claimDetail !== null);

      const fetchData = async () => {
        try {
          const generatedXmldataArray = [];
           const fullNameArray = patient_info.firstname.split(" ");
          const firstName = fullNameArray[0];
          const lastName = fullNameArray[1];
          for (let index = 0; index < code_info.length; index++) {
            const info = code_info[index];
            
            const xmldata = {
              claimHeader: {
                scheme: provider,
                tradepart: practise_number,  
                batchNo: invoiceData.invoice_number,
                createDate: formattedDate,
                transactionType: 'Request',
                numTrans: dr_fee.length,
                totClaim: (
                  Number(totalDrfee) +
                  Number(totalpatient) +
                  Number(totalScheme)
                ).toFixed(2),
                claims: {
                  scriptNum: invoiceData.invoice_number,
                  extUniqueNum: patient_info.patient_number,
                  medAid: patient_info.member_number,
                  // depIdNum: patient_info.id_number,
                  depBirthDate: patient_info.dob,
                  depInitials: '',
                  depName: firstName,
                  depSurname: lastName,
                  depGender: patient_info.gender,
                  claimDetail: filteredClaimDetails
                },
              },
            };
              generatedXmldataArray.push(xmldata);
            }
            setXmldataArray(generatedXmldataArray);
            console.log(generatedXmldataArray)
              
          } catch (error) {
                  console.error('Error fetching data:', error);
                  // Handle error if needed
                }
              };

        
              useEffect(() => {
                getpatientinfo();
                getcodeinfo();
                fetchData();
                getdrinfo()
              }, []);



const printAndDownloadXML = async (e) => {
  e.preventDefault();
    await fetchData(); 
  console.log(xmldataArray)
  const xmlData3 = {
    claimHeader: {
      scheme: provider,
      tradepart: practise_number,
      batchNo: invoiceData.invoice_number,
      createDate: formattedDate,
      transactionType: 'Request',
      numTrans: dr_fee.length,
      totClaim: (
        Number(totalDrfee) +
        Number(totalScheme)
      ).toFixed(2),
      claims: {
        claim: {
          scriptNum: invoiceData.invoice_number,
          extUniqueNum: patient_info.patient_number,
          medAid: patient_info.member_number,
          depIdNum: patient_info.member_number,
          depBirthDate: patient_info.dob,
          depInitials: '',
          depName: patient_info.firstname,
          depGender: patient_info.gender,
          claimDetail: filteredClaimDetails.map((claimDetail, index) => {
            return {
              uniqueSeq: claimDetail.uniqueSeq,
              lineType: claimDetail.lineType,
              tariffCode: claimDetail.tariffCode,
              tariffDesc: claimDetail.tariffDesc,
              serviceDate: claimDetail.serviceDate,
              units: claimDetail.units,
              claimed: claimDetail.claimed,
              benefit: claimDetail.benefit,
              mouthpartid: claimDetail.mouthpartid,
              diagnosis: claimDetail.diagnosis,
            };
          }),
        },
      },
    },
  };

  const { js2xml } = require('xml-js');

  const xmlDataToDownload = xmldataArray.length > 0 ? xmlData3 : null;

  if (xmlDataToDownload) {
    const xmlString = js2xml(xmlDataToDownload);
    console.log(xmlString)
    // Create a Blob with the XML content
      const blob = new Blob([xmlString], { type: 'text/xml' });

      // Create a link element
      const link = document.createElement('a');

      // Set link attributes
      link.href = window.URL.createObjectURL(blob);
      link.download = 'output.xml';

      // Append the link to the document
      document.body.appendChild(link);

      // Trigger a click event on the link to start the download
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
  } else{
    console.log('nothing')
  }
  

  console.log(objectToXML(xmlData3));
};







      return (
<div>
        <div className="header-container fixed-top">
        <Header/>
        </div>
        <div className="main-container" id="container">
        <Sidebar/>



       <div id="content" className="main-content">
        <div className="layout-px-spacing">
          <div className="page-header">
            <div className="page-title">
              <h3>Invoice</h3>
              <a type="submit" onClick={goBack} className="btn btn-danger mt-3" >Go Back</a> &nbsp;&nbsp;&nbsp;&nbsp;
              <button type="submit" onClick={getcodeinfo} className ="btn btn-warning mt-3" >COMPUTE</button>&nbsp;
              <button type="submit" onClick={generatePDF} className="btn btn-primary mt-3" >Download</button>&nbsp;
              <CSVLink data={csvData} filename={csvFileName} target={csvTitle}> <button type="submit" className="btn btn-success mt-3" >CSV</button>&nbsp;</CSVLink>
              
              <button type="submit" onClick={printAndDownloadXML} className="btn btn-info mt-3" >XML</button>&nbsp;
              {/* <button type="submit" className="btn btn-danger mt-3" >Send to Medical Scheme</button>&nbsp; */}

            </div>
          </div>
          <div className="row invoice layout-top-spacing">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="app-hamburger-container">
                <div className="hamburger"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-menu chat-menu d-xl-none"><line x1={3} y1={12} x2={21} y2={12} /><line x1={3} y1={6} x2={21} y2={6} /><line x1={3} y1={18} x2={21} y2={18} /></svg></div>
              </div>

              <div className="doc-container">
                <div className="invoice-container">
                  <div className="invoice-inbox">
                    <div className="invoice-header-section">
                      <h4 className="inv-number" />
                      <div className="invoice-action">
                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-printer action-print" data-toggle="tooltip" data-placement="top" data-original-title="Reply"><polyline points="6 9 6 2 18 2 18 9" /><path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2" /><rect x={6} y={14} width={12} height={8} /></svg>
                      </div>
                    </div>

                    <div>
                      <div className="invoice-00001" id='mycontent'>
                        <div className="content-section">
                          <br/>
                          <br/>
                          <br/>
                          <br/>
                          <div className="row inv--head-section">
                            <div className="col-sm-3 col-12">
                              <h3 className="in-heading">INVOICE</h3>
                            </div>
                            <div className="col-sm-9 col-12 text-sm-right">
                              <div className="company-info">
                                <div className="col-sm-6 align-self-center  text-sm-right order-2">
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row inv--detail-section">
                            
                            <div className="col-sm-7 align-self-center">
                              <br/>
                              <br/>
                              <br/>
                              {/* <img src={dr_info.logo} style={{width:'30%'}} alt="Doctor Logo"/> */}
                                <br/>
                              <br/>
                            </div>
                            <div className="col-sm-5 align-self-center  text-sm-right order-sm-0 order-1">
                                  <p className="inv-street-addr"><b>Clinic Name: </b>{invoiceData.business_name}</p>
                                  <p className="inv-street-addr"><b>Physical Address: </b>{invoiceData.business_address}</p>
                                  <p className="inv-street-addr"><b>Postal Address: </b>{invoiceData.business_postal}</p>
                                  <p className="inv-street-addr"><b>Tel:</b> {invoiceData.business_tel}</p>
                                  <p className="inv-street-addr"><b>Fax: </b>{invoiceData.business_fax}</p>
                                  <p className="inv-street-addr"><b>Email: </b>{invoiceData.business_email}</p>
                                  <p className="inv-street-addr"><b>VAT: </b>{invoiceData.business_vat ? invoiceData.business_vat : null}</p>
                            </div>
                            <div className="col-sm-7 align-self-center">
                              <br/>
                              <br/>
                              <br/>
                              <br/>
                              <p className="inv-to">Patient information</p>
                              <p className="inv-customer-name">Invoice Number: {invoiceData.invoice_number}</p>
                              <p className="inv-customer-name"><b>Patient File No:</b> {patient_data.invpatient_number}</p>
                              <p className="inv-street-addr"><b>Patient Name:</b> {patient_data.invpatient_name}</p>
                              <p className="inv-email-address"><b>Date Of Birth:</b> {patient_data.invDOB}</p>
                              <p className="inv-email-address"><b>Cell</b>: {patient_data.invCell}</p>
                              <p className="inv-email-address"><b>Service Date:</b> {patient_data.service_date}</p>
                              <br />
                              <br />
                              <p className="inv-customer-name"><b>Diagnosis</b></p>
                              <p className="inv-street-addr">{invoiceData.ICD_10}</p>
                              <p className="inv-street-addr">{invoiceData.ICD_10_1}</p>
                              <p className="inv-street-addr">{invoiceData.ICD_10_2}</p> 
                              <p className="inv-street-addr">{invoiceData.ICD_10_3}</p>
                              <p className="inv-street-addr">{invoiceData.ICD_10_4}</p>
                              <p className="inv-street-addr">{invoiceData.ICD_10_5}</p>
                              {/* <p className="inv-street-addr">{icd4}</p> */}
                              <br/>
                              <br/>
                            </div>
                            <div className="col-sm-5 align-self-center  text-sm-right order-2">
                            <p className="inv-detail-title">Principal Member</p>
                              <p className="inv-customer-name">Principal Member Name :  {patient_data.principal_member_name}</p>
                              <p className="inv-street-addr">Medical Aid Scheme: {patient_data.medical_aid_scheme}</p>
                              <p className="inv-street-addr">Membership No: {patient_data.membership_no}</p>
                              <p className="inv-street-addr">Relationship To Member: {patient_data.relationship_to_member}</p>
                              {/* <p className="inv-street-addr">Tel:</p> */}
                            </div>
                          </div>
                          <div className="row inv--product-table-section">
                            <div className="col-12">
                              <div className="table-responsive">
                                <table className="table">
                                <thead>
                                    <tr>
                                      <th scope="col">Code</th>
                                      <th scope="col">ICD - 10</th>
                                      <th scope="col">Service</th>
                                      <th className="" scope="col">Teeth</th>
                                      <th className="" scope="col">Qty</th> 
                                      <th className="" scope="col">Scheme Fee</th>
                                      <th className="" scope="col">Doctor Fee</th>
                                      <th className="" scope="col">Patient Due</th>
                                      <th className="" scope="col">Vat</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {invoiceData ? (
                                      <>
                                          <tr>
                                          <td>{invoiceData.code}</td>
                                          <td>{invoiceData.ICD_10}</td>
                                          <td>{invoiceData.service}</td>
                                          <td>{invoiceData.teeth}</td>
                                          <td>{invoiceData.qty}</td>
                                          <td>{Number(invoiceData.scheme_fee1).toFixed(2)}</td>
                                          <td>
                                            {typeof invoiceData.doctor_fee1 === 'string' ? Number(invoiceData.doctor_fee1).toFixed(2) : ''}
                                          </td>
                                          <td>
                                            {typeof invoiceData.patient_fee1 === 'string' ? Number(invoiceData.patient_fee1).toFixed(2) : ''}
                                          </td>
                                          <td>
                                            {typeof invoiceData.vat === 'string' ? Number(invoiceData.vat).toFixed(2) : ''}
                                          </td>
                                        </tr>
                                        { invoiceData.ICD_10_1 ? 
                                        <tr>
                                          <td>{invoiceData.code_1}</td>
                                          <td>{invoiceData.ICD_10_1}</td>
                                          <td>{invoiceData.service1}</td>
                                          <td>{invoiceData.teeth_1}</td>
                                          <td>{invoiceData.qty_1}</td>
                                          <td>{invoiceData.scheme_fee2}</td>
                                          <td>
                                            {typeof invoiceData.doctor_fee2 === 'string' ? Number(invoiceData.doctor_fee2).toFixed(2) : ''}
                                          </td>
                                          <td>
                                            {typeof invoiceData.patient_fee2 === 'string' ? Number(invoiceData.patient_fee2).toFixed(2) : ''}
                                          </td>
                                          <td>
                                            {typeof invoiceData.vat1 === 'string' ? Number(invoiceData.vat1).toFixed(2) : ''}
                                          </td>
                                        </tr>:(
                                          ''
                                        )
                                        }
                                        { invoiceData.ICD_10_2 ? 
                                        <tr>
                                        <td>{invoiceData.code_2}</td>
                                        <td>{invoiceData.ICD_10_2}</td>
                                        <td>{invoiceData.service2}</td>
                                        <td>{invoiceData.teeth_2}</td>
                                        <td>{invoiceData.qty_2}</td>
                                        <td>
                                          {typeof invoiceData.scheme_fee3 === 'string' ? Number(invoiceData.scheme_fee3).toFixed(2) : ''}
                                        </td>
                                        <td>
                                          {typeof invoiceData.doctor_fee3 === 'string' ? Number(invoiceData.doctor_fee3).toFixed(2) : ''}
                                        </td>
                                        <td>
                                          {typeof invoiceData.patient_fee3 === 'string' ? Number(invoiceData.patient_fee3).toFixed(2) : ''}
                                        </td>
                                        <td>
                                          {typeof invoiceData.vat2 === 'string' ? Number(invoiceData.vat2).toFixed(2) : ''}
                                        </td>
                                      </tr>
                                      :(
                                        ''
                                      )
                                        }
                                          { invoiceData.ICD_10_3 ? 
                                        <tr>
                                        <td>{invoiceData.code_3}</td>
                                        <td>{invoiceData.ICD_10_3}</td>
                                        <td>{invoiceData.service3}</td>
                                        <td>{invoiceData.teeth_3}</td>
                                        <td>{invoiceData.qty_3}</td>
                                        <td>
                                          {typeof invoiceData.scheme_fee4 === 'string' ? Number(invoiceData.scheme_fee4).toFixed(2) : ''}
                                        </td>
                                        <td>
                                          {typeof invoiceData.doctor_fee4 === 'string' ? Number(invoiceData.doctor_fee4).toFixed(2) : ''}
                                        </td>
                                        <td>
                                          {typeof invoiceData.patient_fee4 === 'string' ? Number(invoiceData.patient_fee4).toFixed(2) : ''}
                                        </td>
                                        <td>
                                          {typeof invoiceData.vat3 === 'string' ? Number(invoiceData.vat3).toFixed(2) : ''}
                                        </td>
                                      </tr>
                                      :(
                                        ''
                                      )
                                      }

                                    { invoiceData.ICD_10_4 ? 
                                        <tr>
                                        <td>{invoiceData.code_4}</td>
                                        <td>{invoiceData.ICD_10_4}</td>
                                        <td>{invoiceData.service4}</td>
                                        <td>{invoiceData.teeth_4}</td>
                                        <td>{invoiceData.qty_4}</td>
                                        <td>
                                          {typeof invoiceData.scheme_fee5 === 'string' ? Number(invoiceData.scheme_fee5).toFixed(2) : ''}
                                        </td>
                                        <td>
                                          {typeof invoiceData.doctor_fee5 === 'string' ? Number(invoiceData.doctor_fee5).toFixed(2) : ''}
                                        </td>
                                        <td>
                                          {typeof invoiceData.patient_fee5 === 'string' ? Number(invoiceData.patient_fee5).toFixed(2) : ''}
                                        </td>
                                        <td>
                                          {typeof invoiceData.vat4 === 'string' ? Number(invoiceData.vat4).toFixed(2) : ''}
                                        </td>
                                      </tr>
                                      :(
                                        ''
                                      )
                                      }
                                      { invoiceData.ICD_10_5 ? 
                                        <tr>
                                        <td>{invoiceData.code_5}</td>
                                        <td>{invoiceData.ICD_10_5}</td>
                                        <td>{invoiceData.service5}</td>
                                        <td>{invoiceData.teeth_5}</td>
                                        <td>{invoiceData.qty_5}</td>
                                        <td>
                                          {typeof invoiceData.scheme_fee6 === 'string' ? Number(invoiceData.scheme_fee6).toFixed(2) : ''}
                                        </td>
                                        <td>
                                          {typeof invoiceData.doctor_fee6 === 'string' ? Number(invoiceData.doctor_fee6).toFixed(2) : ''}
                                        </td>
                                        <td>
                                          {typeof invoiceData.patient_fee6 === 'string' ? Number(invoiceData.patient_fee6).toFixed(2) : ''}
                                        </td>
                                        <td>
                                          {typeof invoiceData.vat5 === 'string' ? Number(invoiceData.vat5).toFixed(2) : ''}
                                        </td>
                                      </tr>
                                      :(
                                        ''
                                      )
                                      }

                                      
                                        
                                      </>
                                    ) : (
                                      <tr>
                                        <td colSpan="8">No data available, click the "COMPUTE" button.</td>
                                      </tr>
                                    )}
                                  </tbody>

                                </table>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col-sm-5 col-12 order-sm-0 order-1">
                            </div>
                            <div className="col-sm-7 col-12 order-sm-1 order-0">
                              <div className="inv--total-amounts text-sm-right">
                                <div className="row">
                                <div className="col-sm-8 col-7">
                                    <p className>Discount (%): </p>
                                  </div>
                                  <div className="col-sm-4 col-5">
                                    <input type="number"  value={invoiceData.discount} disabled id="discount" name="discount"  className="form-control"  />
                                  </div>
                                  <div className="col-sm-8 col-7">
                                    <p className>Total Amount: </p>
                                  </div>
                                  <div className="col-sm-4 col-5">
                                    <p className> {totalAmount.toLocaleString('en-BW', { style: 'currency', currency: 'BWP' })}</p>
                                  </div>
                                  <div className="col-sm-8 col-7">
                                    <p className>Vat Amount: </p>
                                  </div>
                                  <div className="col-sm-4 col-5">
                                    <p className>{(totalVat).toLocaleString('en-BW', { style: 'currency', currency: 'BWP' })}</p>
                                  </div>
                                  <div className="col-sm-8 col-7">
                                    <p className=" discount-rate">Total (Vat Inclusive): </p>
                                  </div>
                                  <div className="col-sm-4 col-5">
                                    <p className> {(Number(totalAmount)+Number(totalVat)).toLocaleString('en-BW', { style: 'currency', currency: 'BWP' })}</p>
                                  </div>
                                  <div className="col-sm-8 col-7">
                                    <p className>Total (Due From Patient): </p>
                                  </div>
                                  <div className="col-sm-4 col-5">
                                    <p className> {(Number(totalpatient)+Number(totalDrfee)+Number(totalVat)).toLocaleString('en-BW', { style: 'currency', currency: 'BWP' })}</p>
                                  </div>
                                  <div className="col-sm-8 col-7">
                                    <p className>Total (Due From Patient Discounted): </p>
                                  </div>
                                  <div className="col-sm-4 col-5">
                                    <p className> {((Number(totalpatient)+Number(totalDrfee)+Number(totalVat)) - (Number(totalpatient)+Number(totalDrfee)+Number(totalVat)) * Number(invoiceData.discount)/100).toLocaleString('en-BW', { style: 'currency', currency: 'BWP' })}</p>
                                  </div>
                                  
                                  <div className="col-sm-8 col-7">
                                    <p className>Amount (Claimed From Medical Aid): </p>
                                  </div>
                                  <div className="col-sm-4 col-5">
                                    <p className> {(totalScheme).toLocaleString('en-BW', { style: 'currency', currency: 'BWP' })}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <br/>
                          <br/>
                          <br/> <br/>
                          <br/>
                          <br/>
                          <div className="row">
                            <div className="col-6">
                              <div className="col-sm-7 align-self-center">
                                <p className="inv-to">Patient's Signature: ___________</p>
                              </div>
                              <div className="col-sm-8 col-7">
                                <p className>Date: ____________ </p>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="col-sm-7 align-self-center">
                                <p className="inv-to">Doctor's Signature: ___________</p>
                              </div>
                              <div className="col-sm-8 col-7">
                                <p className>Date: ____________ </p>
                              </div>
                            </div>
                          </div>
                          <br />
                          <br />
                          <b>Dr. {fname} <br/><br/> Practise No: {practise_number}</b>
                          <br/>
                          <br/>
                          <br/>
                          <br />
                          <br />
                          <br />
                          <br />
                          <small style={{ position: "absolute", bottom: "10", width: "100%", textAlign: "left" }}>
                              Powered by Starlight Software Solutions (PTY) LTD - info@starlightsoftware.co.bw
                            </small>
                        </div>
                      </div> 
                    </div>
                  </div>
                  <div className="inv--thankYou">
                    <div className="row">
                      <div className="col-sm-12 col-12">
                        <p className>Developed by Starlight Software Solutions (Pty) Ltd.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       <Footer/>
      </div> 
        </div>
        </div>
        );
  };

export default Viewinvoice2;
