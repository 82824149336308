import React, {useState} from 'react';
import Chart from 'react-apexcharts'
import ReactApexChart from 'react-apexcharts';
import axios from "axios";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom';


  function SignIn1(){

  const MySwal = withReactContent(Swal)
  let history = useHistory();
  const baseUrl = "https://remitaid.starlightsoftware.co.bw/api/login";
  // const baseUrl = "http://localhost:9800/api/login";
  const [state , setState] = useState({
    email: '',
    password: '',
})
  var postData = {
      "email": state.email,
      "password": state.password,
  };
  let axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }
  };


const handleChange = (e) => {
  const {id , value} = e.target   
  setState(prevState => ({
      ...prevState,
      [id] : value
  }))
}

const handleLoginSubmit = (e) => {
  e.preventDefault();

  if (!state.email || !state.password) {
    MySwal.fire({
      title: <p>Please fill all fields</p>,
      didOpen: () => {
      },
    }).then(() => {
    });
  } else {
    MySwal.fire({
      // title: <p>please wait...</p>,
      didOpen: () => {
        MySwal.showLoading()
        axios.post(baseUrl, postData, axiosConfig)
        .then((response) => {
          console.log("response received: ", response);
          Swal.close()
            localStorage.setItem("sl_med_tokenize", JSON.stringify(response.data.data.accessToken));
            localStorage.setItem("sl_med_fname", response.data.data.firstname);
            localStorage.setItem("sl_med_businessname", response.data.data.business_name);
            localStorage.setItem("sl_med_businessaddress", response.data.data.business_address);
            localStorage.setItem("sl_med_businesspaddress", response.data.data.postal_address);
            localStorage.setItem("sl_med_vat_id", response.data.data.vat_number);
            localStorage.setItem("sl_med_businessemail", response.data.data.business_email);
            localStorage.setItem("sl_med_practise_number", response.data.data.practise_number);
            localStorage.setItem("sl_med_phone", response.data.data.phone);
            localStorage.setItem("sl_med_email", response.data.data.email);
            localStorage.setItem("sl_med_fax", response.data.data.fax);
            localStorage.setItem("sl_med_id", response.data.data.id);
            // window.location.href="dashboard";
            console.log(response.data.data);
            if(response.data.data.is_active == 0){
              console.log(response.data.data.is_active);
              const myidd = response.data.data.id;
              window.location.href = "signin2?myidd=" + myidd;
            }else{
            window.location.href="dashboard";
            }
          })
          .catch((err) => {
            Swal.close()
            console.log(err);
              if(!err.response){  
                return MySwal.fire(err.message)
                }else{
                return MySwal.fire(err.response.data.message)
                }
            
          })
      },
    }).then(() => {
      // Swal.close()
    })
  }
};




  

  // const handleLoginSubmit = (e) => {
  //   if (!state.email || !state.password){
  //     MySwal.fire({
  //       title: <p>Please fill all fields</p>,
  //       didOpen: () => {
  //       },
  //     }).then(() => {
  //     })
  //   }else{
  //     window.location.href="dashboard";
  //     MySwal.fire({
  //       title: <p>please wait...</p>,
  //       didOpen: () => {
  //         MySwal.showLoading()
  //         axios.post(baseUrl, postData, axiosConfig)
  //       .then((response) => {
  //         console.log("response received: ", response);
  //         Swal.close()
  //           sessionStorage.setItem("instv_tokenize", JSON.stringify(response.data.data.accessToken));
  //           sessionStorage.setItem("instv_fname", JSON.stringify(response.data.data.firstname));
  //           sessionStorage.setItem("instv_fi", JSON.stringify(response.data.data.id));
  //         })
  //         .catch((err) => {
  //           Swal.close()
  //           console.log("AXIOS ERROR: ", err);
  //           return MySwal.fire(err.response.data.message)
  //         })
  //       },
  //     }).then(() => {
  //     })
  //   }
  // }
 

      return ( 
        <div className="form-container">
        <div className="form-form">
          <div className="form-form-wrap">
            <div className="form-container">
              <div className="form-content">
                <br/>
                <br/>
                <center>
                <img src='assets/img/logo.png' width={180}/>
                </center>
                <form className="text-left">
                  <div className="form">
                    <div id="username-field" className="field-wrapper input">
                      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx={12} cy={7} r={4} /></svg>
                      <input type="text" placeholder="Email" value={state.email} onChange={handleChange} id="email" name="email" autoComplete="off" className="form-control bg-transparent" />
                    </div>
                    <div id="password-field" className="field-wrapper input mb-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-lock"><rect x={3} y={11} width={18} height={11} rx={2} ry={2} /><path d="M7 11V7a5 5 0 0 1 10 0v4" /></svg>
                    <input type="password" placeholder="Password" value={state.password} onChange={handleChange} id="password" name="password" className="form-control bg-transparent" />
                    </div>
                    <div className="d-sm-flex justify-content-between">
                      <div className="field-wrapper">
                        <span>
                  <button onClick={handleLoginSubmit} className="btn btn-primary">Log In
                        </button>
                     <small><a href="forgot" style={{float: 'right'}}  className="forgot-pass-link">Forgot Password?</a></small>
                     </span>
                      </div>
                    </div>
                    <div className="field-wrapper text-center keep-logged-in">
                    </div>
                   
                  </div>
                </form>    
                                  
              </div>                    
            </div>
          </div>
        </div>
        <div className="form-image">
        
          <div className="l-image">
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
          <img src='assets/img/19836.jpg' className="fulll-width" style={{verticalAlign: 'middle', alignItems: 'center', justifyContent: 'center'}} alt="Image"/>
          </div>
        </div>
      </div>
      );

  };

export default SignIn1;
