import React, {useEffect} from 'react';
import { useLocation } from 'react-router-dom';


function Footer() {

    const location = useLocation();
    const currentYear = new Date().getFullYear();
    // console.log(currentYear);


    return (
        <div className="footer-wrapper">
        <div className="footer-section f-section-1">
          <p className>Version 1.1.2 || Copyright © {currentYear} All rights reserved. SLMED by <a target="_blank" href="https://starlightsoftware.co.bw/">Starlight Software Solutions (Pty) Ltd.</a></p>
        </div>
      </div>
    )
}
export default Footer;
