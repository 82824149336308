import React, {useState,useEffect} from 'react';
import axios from "axios";
import dayjs from "dayjs";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom';
import Header from '../../component/layout/header';
import Sidebar from '../../component/layout/sidebar';
import Footer from '../../component/layout/footer';
import storage from "./firebaseConfig.js"
import {
  ref,
  uploadBytesResumable,
  getDownloadURL 
} from "firebase/storage";


function Updateprofile (){
  if (!localStorage.getItem('sl_med_tokenize')) {
    console.log('no auth token set');
    window.location.href="/";
  } else {
        console.log('good token. Log in.')
    }
  const MySwal = withReactContent(Swal);
  const myURL = "https://remitaid.starlightsoftware.co.bw/api/getdr";
  // const myURL = "https://remitaid.starlightsoftware.co.bw/api/get";

  const business_name = localStorage.getItem('sl_med_businessname');
  // const fname = localStorage.getItem('sl_med_fname');
  // const doctor_id = localStorage.getItem("sl_med_id");
  const [state , setState] = useState({
    name: localStorage.getItem('sl_med_fname'),
    email: localStorage.getItem('sl_med_email'),
    phone: localStorage.getItem('sl_med_phone'),
    logo: '',
    practise: localStorage.getItem('sl_med_practise_number'),
    business_name: localStorage.getItem('sl_med_businessname'),
    business_email: localStorage.getItem('sl_med_businessemail'),
    business_phone: localStorage.getItem('sl_med_phone'),
    business_address: localStorage.getItem('sl_med_businessaddress'),
    business_paddress: localStorage.getItem('sl_med_businesspaddress'),
    vat_id: localStorage.getItem('sl_med_vat_id'),
    fax: localStorage.getItem('sl_med_fax')
})

const handleChange = (event) => {
  const { name, value } = event.target;
  setState((prevState) => ({
    ...prevState,
    [name]: value,
  }));
};

const token = localStorage.getItem('sl_med_tokenize');

const headers2 = { 
  headers: {
    'AccessToken': JSON.parse(token),
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': "*",
  }
};

const handleFileChange = (event, inputName) => {
  const file = event.target.files[0];
  if (file) {
    setState({
      ...state,
      [inputName]: file, // Store the file object in state
    });
  }
};

const handleImageUpload = async (file) => {
  const storageRef = ref(storage, `/files/${file.name}`);
  const uploadTask = uploadBytesResumable(storageRef, file);

  return new Promise((resolve, reject) => {
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      (err) => {
        console.log(err);
        reject(err);
      },
      () => {
        // Get the download URL
        getDownloadURL(uploadTask.snapshot.ref)
          .then((url) => {
            resolve(url);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      }
    );
  });
};



const handleupdate = async (e) => {
  e.preventDefault();
  if (!state.name || !state.email|| !state.phone|| !state.practise|| !state.business_name|| !state.business_email|| !state.business_phone|| !state.business_address) {
    MySwal.fire({
      title: <p>Please enter all information</p>,
      didOpen: () => {
      },
    }).then(() => {
    });
  }else{
    //  const dashURL = "http://localhost:9550/api/updateinfo";
     const dashURL = "https://remitaid.starlightsoftware.co.bw/api/updateinfo";
     const doctor_id = localStorage.getItem("sl_med_id"); 
     
     MySwal.showLoading()

     const [picture1URL] = await Promise.all([
      handleImageUpload(state.logo),
    ]);
    console.log(picture1URL)
        const postData = {
          'userId': doctor_id,
          'firstname': state.name,
          'lastname': state.name,
          'email': state.email,
          'phone': state.phone,
          'logo': picture1URL,
          'business_name': state.business_name,
          'business_address': state.business_address,
          'business_email': state.business_email,
          'practise_number': state.practise,
          "postal_address": state.business_paddress,
          "vat_id": state.vat_id,
          "fax": state.fax,
          'role': 2,
        }
     console.log(postData);

     MySwal.fire({
         title: <p>please wait...</p>,
         didOpen: () => {
          //  MySwal.showLoading()
           axios.post(dashURL, postData, headers2)
         .then((response) => {
           console.log("response received: ", response);
           Swal.close()
           Swal.fire({
             title: "Successful",
             text: "Profile Updated successfully",
             icon: "success",
             showCancelButton: false,
             confirmButtonText: "Okay",
             closeOnCancel: true,
           }).then(result => {
            localStorage.setItem("sl_med_fname", state.name);
            localStorage.setItem("sl_med_businessname", state.business_name);
            localStorage.setItem("sl_med_businessaddress", state.business_address);
            localStorage.setItem("sl_med_businessemail", state.business_email);
            localStorage.setItem("sl_med_practise_number", state.practise);
            localStorage.setItem("sl_med_phone", state.phone);
            localStorage.setItem("sl_med_email", state.email);
            localStorage.setItem("sl_med_businesspaddress", state.business_paddress);
            localStorage.setItem("sl_med_vat_id", state.vat_id);
            localStorage.setItem("sl_med_fax", state.fax);
         window.location.reload(false);

           })
           })
           .catch((err) => {
           Swal.close()
             console.log("AXIOS ERROR: ", err);
             if(!err.response){
             return MySwal.fire(err.message)
             }else{
             return MySwal.fire(err.response.data.message)
             }
           })
         },
       }).then(() => {
       })
 }
};






      return (
<div>
<div className="header-container fixed-top">
        <Header/>
        </div>
        <div className="main-container" id="container">
        <Sidebar/>



          <div id="content" className="main-content">
        <div className="layout-px-spacing">
          <div className="page-header">
            <div className="page-title">
              <h3>Update Profile</h3>
            </div>
          </div>
          <div className="card row layout-top-spacing">
            <div id="flFormsGrid" className="col-lg-12 layout-spacing">
              <div className="widget-content widget-content-area">
                <div className="row">
                  <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                    <h4>Update your information</h4>
                  </div>                                                                        
                </div>
                <form>
                  <div className="form-row mb-12">
                  <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Upload Logo</label>
                      <input type="file" onChange={(event) => handleFileChange(event, 'logo')} id="state.logo"  className="form-control" name="state.logo" placeholder="upload"/>
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Name</label>
                      <input type="text"  value={state.name} onChange={handleChange} id="name" name="name" className="form-control" />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Email</label>
                      <input type="text" value={state.email} onChange={handleChange} disabled id="email" name="email" className="form-control" />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Phone</label>
                      <input type="number" value={state.phone} onChange={handleChange} id="phone" name="phone" className="form-control" />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Practise Number</label>
                      <input type="number" value={state.practise} onChange={handleChange} id="practise" name="practise" className="form-control" />
                    </div>
                    {/* <div className="form-row mb-12"> */}
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Business Name</label>
                      <input type="text" value={state.business_name} onChange={handleChange} id="business_name" name="business_name" className="form-control" />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Business Email</label>
                      <input type="text" value={state.business_email} onChange={handleChange} disabled id="business_email" name="business_email" className="form-control" />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Business Phone</label>
                      <input type="number" value={state.business_phone} onChange={handleChange} id="business_phone" name="business_phone" className="form-control" />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Business Address</label>
                      <input type="text" value={state.business_address} onChange={handleChange} id="business_address" name="business_address" className="form-control" />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Business Postal Address</label>
                      <input type="text" value={state.business_paddress} onChange={handleChange} id="business_paddress" name="business_paddress" className="form-control" />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Business VAT ID</label>
                      <input type="text" value={state.vat_id} onChange={handleChange} id="vat_id" name="vat_id" className="form-control" />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Business Fax</label>
                      <input type="text" value={state.fax} onChange={handleChange} id="fax" name="fax" className="form-control" />
                    </div>
                    
                  {/* </div> */}
                  
                  </div></form>
                <button onClick={handleupdate} className="btn btn-primary mt-3" style={{float: 'right'}} href="">Submit</button>
              </div>
            </div>
          </div>
          <Footer/>
        </div>
      </div>
        </div>
        </div>
        
      
               
        );
 
  };

export default Updateprofile;
