import React, {useState,useEffect} from 'react';
import axios from "axios";
import dayjs from "dayjs";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom';
import DataTable from "react-data-table-component";
import Spinner from 'react-bootstrap/Spinner';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Header from '../../component/layout/header';
import Sidebar from '../../component/layout/sidebar';
import Footer from '../../component/layout/footer';


function Reporttable (){
  if (!localStorage.getItem('sl_med_tokenize')) {
    console.log('no auth token set');
    window.location.href="/";
  } else {
        console.log('good token. Log in.')
    }
  const history = useHistory();
  const MySwal = withReactContent(Swal)
  const business_name = localStorage.getItem('sl_med_businessname');
  const fname = localStorage.getItem('sl_med_fname');
  const [my_info, setinfo] = useState([]);
  const [my_info2, setinfo2] = useState([]);
  const token = sessionStorage.getItem('sl_med_tokenize');
  const [state , setState] = useState({
    name: '',
    email: '',
    amount: '',
    file: null,
    base64URL: ''
})


const goback = (e) => {
  e.preventDefault();
  window.location.href='report'
}


    // const myid = sessionStorage.getItem("instv_fi");
    const myURL = "https://remitaid.starlightsoftware.co.bw/api/getSummary";
    // const myURL = "http://localhost:9550/api/getSummary";
    
    const headers2 = { 
      headers: {
        'AccessToken': JSON.parse(token),
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': "*",
      }
    };
    const urlParams = new URLSearchParams(window.location.search);
    const start_date = urlParams.get('start');
    const end_date = urlParams.get('end');
    const doctor_id = localStorage.getItem("sl_med_id"); 

    const body = {
      'doctor_id':doctor_id,
      'date1':start_date,
      'date2':end_date,
    }

    console.log(body)
  
    const getmyinfo = async () => {
      axios.post(myURL, body, headers2)
      .then((response) => {
        console.log("response received: ", response);
        if(!response){
          setinfo(0);
        }else{
        console.log("response received: ", response);
          setinfo(response.data.invoices_list);
          setinfo(response.data.invoices_list);
          setinfo2(response.data);
          setinfo2(response.data);
          console.log(my_info);
          console.log(response.data);
        }
      });
    };


    const handleLoginSubmit = async () => {

    }

    useEffect(() => {
      getmyinfo();
    }, []);




    const columns = [
      {
        name: 'DATE',
        selector: (row) => dayjs(row.created_at).format("DD MMM YY"),
        sortable: true,
        width: "8rem" ,
          cellExport: (row) => dayjs(row.created_at).format("DD MMM YY")
    },
    {
      name: 'INVOICE NO.',
      selector: (row) => row.invoice_number,
      sortable: true,
      width: "8rem" ,
        cellExport: (row) => row.invoice_number
  },
    {
      name: 'PATIENT NAME',
      selector: (row) => row.patient_name,
      sortable: true,
      width: '15rem',
      cellExport: row => row.patient_name 

    },
    {
      name: 'MEDICAL PROVIDER',
      selector: (row) => row.provider == 1 ? 
  <span class="badge badge-danger fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">BOMAID</span>
  : row.provider == 2 
  ? <span class="badge badge-success fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">BPOMAS</span>
  : row.provider == 3
  ? <span class="badge badge-warning fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">PULA</span>
  : row.provider == 4
  ? <span class="badge badge-info fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">BOTSOGO</span>
  : row.provider == 5 
  ? <span class="badge badge-primary fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">BDF</span>
  : row.provider == 6
  ? <span class="badge badge-success fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">BPOMAS HIGH</span>
  : row.provider == 7 
  ? <span class="badge badge-success fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">BPOMAS STANDARD</span>
  : row.provider == 8 
  ? <span class="badge badge-warning fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">PULA DELUXE</span>
  : row.provider == 9
  ? <span class="badge badge-warning fw-semibold fs-9 px-2 ms-2 cursor-default ms-2">PULA STANDARD</span>
  : <span class="badge badge-success fw-semibold fs-9 px-2 ms-2 cursor-default ms-2"> </span>,
      sortable: true,
      width: "15rem" ,
     cellExport: row => row.provider 
  },
  {
    name: 'TOTAL INVOICE AMOUNT',
    selector: (row) => row.total_amount.toLocaleString('en-BW', { style: 'currency', currency: 'BWP' }),  
    sortable: true,
    width: "15rem" ,
   cellExport: row => row.total_amount 
},
    {
      name: 'ACTIONS',
      button: true,
      cell: (row) => <button onClick={() => viewInvoice(row.id)} id={row.id} data-patientid={row.patient_id} className="btn btn-sm btn-primary btn-active-color-primary">View</button>

    },
   
  ];
    
  const data=my_info;

  const tableData = {
    columns,
    data
  };

  const viewInvoice = (selectedId) => {
    const selectedData = data.find((item) => item.id === selectedId);
    if (selectedData) {
      const selectedDataString = JSON.stringify(selectedData);
      localStorage.setItem('selectedData', selectedDataString);
      // console.log(selectedDataString)
      const nextPageUrl = '/viewinvoice2'; // Specify the URL of the next page
      window.location.href = nextPageUrl;
    } else {
      console.error(`Data for ID ${selectedId} not found`);
    }
}




      return (
<div>
        <div className="header-container fixed-top">
        <Header/>
        </div>
        <div className="main-container" id="container">
        <Sidebar/>



          <div id="content" className="main-content">
        <div className="layout-px-spacing">
          <div className="page-header">
            <div className="page-title">
              <h3>All reports</h3>
            </div>
          </div>
          <div className="row layout-top-spacing" id="cancel-row">
          <div className="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                   <div className="widget widget-account-invoice-two">
                    <div className="widget-content">
                      <div className="w-content">
                        <div className="w-info">
                          <h6 className="value" style={{color: 'white'}}>{my_info2.total_invoices}</h6>
                          <p className style={{color: 'ghostwhite'}}>Invoices created</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                   <div className="widget widget-account-invoice-one">
                    <div className="widget-content">
                      <div className="w-content">
                        <div className="w-info">
                          <h6 className="value" style={{color: 'white '}}>{my_info2.total_consultations}</h6>
                          <p className style={{color: 'white '}}>Total Consultations</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                   <div className="widget widget-account-invoice-3">
                    <div className="widget-content">
                      <div className="w-content">
                        <div className="w-info">
                          <h6 className="value" style={{color: 'white '}}>{my_info2.total_sum? my_info2.total_sum.toLocaleString('en-BW', { style: 'currency', currency: 'BWP' }): '0'}</h6>
                          
                          <p className style={{color: 'white '}}>Invoice amount</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                   <div className="widget widget-account-invoice-4">
                    <div className="widget-content">
                      <div className="w-content">
                        <div className="w-info">
                          <h6 className="value" style={{color: 'white '}}>{my_info2.total_patients}</h6>
                          <p className style={{color: 'white '}}>Total Patients</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                
             
              <div className="widget-content widget-content-area br-6">
              {/* <a href='' className="btn btn-small btn-info">Excel</a>&nbsp; */}
              <a href='' onClick={goback} className="btn btn-small btn-danger">Go Back</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <button onClick={getmyinfo} className="btn btn-small btn-primary">Reload Table</button>
            <div className="form-group col-md-3" style={{float: 'right'}}>
                    <input type="text" placeholder='search' className="form-control" id="inputPassword4" />
                  </div>
                <div className="table-responsive mb-4 mt-4">
                  <table id="zero-config" className="table table-hover" style={{width: '100%'}}>
                    {/* <thead>
                      <tr>
                        <th>Invoice Date</th>
                        <th>Patient Name</th>
                        <th>Total Amount</th>
                        <th className="no-content">Actions</th>
                      </tr>
                    </thead>

                    <tbody>
                    {Array.isArray(my_info) && my_info.length > 0 ? (
                        my_info.map((info, index) => (
                      <tr>
                        <td>{info.created_at}</td>
                        <td>{info.doctor_id}</td>
                        <td>{info.total_amount}</td>
                        <td>
                     <button onClick={handleLoginSubmit} id={info.id} className="btn btn-primary">View
                        </button>
                        </td>
                      </tr>
                      ))
                      ): (
                        <tr>
                        <td colSpan="8">No data available, click the "COMPUTE" button.</td>
                      </tr>
                      )
                       }
                    </tbody> */}

                {my_info == null ? <Spinner animation="border" /> : ""}
                {Array.isArray(my_info) && my_info.length > 0 ? (

                  <DataTableExtensions {...tableData}>
                                <DataTable className=""
                                  columns={columns}
                                  data={data}
                                  noHeader
                                  defaultSortField="id"
                                  defaultSortAsc={false}
                                  pagination
                                  exportHeaders= {true}
                                  highlightOnHover
                                />
                              </DataTableExtensions>
                               ): (
                                <tr>
                                <td colSpan="8">No data available, click the "Reload Table" button.</td>
                              </tr>
                              )
                               }


                    
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
       <Footer/>
      </div>
        </div>
        </div>
        
      
               
        );
 
  };

export default Reporttable;
