import React, {useEffect} from 'react';

function Header() {


    const business_name = localStorage.getItem('sl_med_businessname');
    const fname = localStorage.getItem('sl_med_fname');



    return (
        <header className="header navbar navbar-expand-sm">
            <ul className="navbar-item flex-row">
              <li className="nav-item theme-logo">
                <a href="dashboard">
                <img src='assets/img/lo.png' width={180}/>
                </a>
              </li>
            </ul>
            <a href="javascript:void(0);" className="sidebarCollapse" data-placement="bottom">
              
              <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-list"><line x1={8} y1={6} x2={21} y2={6} /><line x1={8} y1={12} x2={21} y2={12} /><line x1={8} y1={18} x2={21} y2={18} /><line x1={3} y1={6} x2={3} y2={6} /><line x1={3} y1={12} x2={3} y2={12} /><line x1={3} y1={18} x2={3} y2={18} /></svg></a>
            <ul className="navbar-item flex-row search-ul">
              <li className="nav-item search-animated">
              <br/>
                  <h5 className='text-primary'>SL-MED</h5> 
              </li>
            </ul>
            <ul className="navbar-item flex-row navbar-dropdown">
              <li className="nav-item dropdown notification-dropdown">
                <br/>
              <h6 className='text-primary'>{business_name}</h6>
              </li>
              <li className="nav-item dropdown user-profile-dropdown  order-lg-0 order-1">
                <a href="javascript:void(0);" className="nav-link dropdown-toggle user" id="userProfileDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <img src="assets/img/profile-7.jpg" alt="admin-profile" className="img-fluid" />
                </a>
                <div className="dropdown-menu position-absolute animated fadeInUp" aria-labelledby="userProfileDropdown">
                  <div className="user-profile-section">
                    <div className="media mx-auto">
                      {/* <img src="assets/img/profile-7.jpg" className="img-fluid mr-2" alt="avatar" /> */}
                      <div className="media-body">
                        <h5>{fname}</h5>
                        <p>Dentist</p>
                      </div>
                    </div>
                  </div>
                  <div className="dropdown-item">
                    <a href="updateprofile">
                      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx={12} cy={7} r={4} /></svg> <span>My Profile</span>
                    </a>
                  </div>
                  <div className="dropdown-item">
                    <a href="logout">
                      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" /><polyline points="16 17 21 12 16 7" /><line x1={21} y1={12} x2={9} y2={12} /></svg> <span>Log Out</span>
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </header>
    )
}
export default Header;
