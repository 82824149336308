import React, {useState,useEffect} from 'react';
import axios from "axios";
import dayjs from "dayjs";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom';
import Header from '../../component/layout/header';
import Sidebar from '../../component/layout/sidebar';
import Footer from '../../component/layout/footer';


function Newpassword (){
  if (!localStorage.getItem('sl_med_tokenize')) {
    console.log('no auth token set');
    window.location.href="/";
  } else {
        console.log('good token. Log in.')
    }
  const history = useHistory();
  const MySwal = withReactContent(Swal);
  const business_name = localStorage.getItem('sl_med_businessname');
  const fname = localStorage.getItem('sl_med_fname');
  const baseUrl = "https://remitaid.starlightsoftware.co.bw/api/verify";
  const doctor_id = localStorage.getItem("sl_med_id");
  const [state , setState] = useState({
    current: '',
    newpassword: '',
    confirm: '',
})


var postData = {
  "doctor_id": doctor_id,
  "currentPassword": state.current,
  "newPassword": state.newpassword
};
let axiosConfig = {
headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    "Access-Control-Allow-Origin": "*",
}
};

const handleChange = (e) => {
  const {id , value} = e.target   
  setState(prevState => ({
      ...prevState,
      [id] : value
  }))
}

const handleLoginSubmit = (e) => {
  e.preventDefault();
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  console.log(state.current,state.newpassword)

  if (!state.current || !state.newpassword) {
    MySwal.fire({
      title: <p>Please enter current and new password</p>,
      didOpen: () => {
      },
    }).then(() => {
    });
  } else if(state.confirm != state.newpassword){
    MySwal.fire({
        title: <p>New password and confirm password must match.</p>,
        didOpen: () => {
        },
      }).then(() => {
      });
        }else if(!passwordRegex.test(state.newpassword)) {
          MySwal.fire({
              title: <p>Password must contain at least one uppercase letter, one number, one special character, and be minimum 8 characters</p>,
              didOpen: () => {},
            }).then(() => {});
  }else {
    MySwal.fire({
      didOpen: () => {
        MySwal.showLoading()
        axios.post(baseUrl, postData, axiosConfig)
        .then((response) => {
          console.log("response received: ", response);
          Swal.close()
          MySwal.fire({
            title: "Successful",
            text: "Password Updated successfully",
            icon: "success",
            showCancelButton: false,
            confirmButtonText: "Okay",
            closeOnCancel: true,
            didOpen: () => {},
          }).then(() => {
             window.location.reload(false);
          });
            
          })
          .catch((err) => {
            Swal.close()
            console.log("AXIOS ERROR: ", err.response.data.message);
            return MySwal.fire(err.response.data.message)
          })
      },
    }).then(() => {
      // Swal.close()
    })
  }
};
  


      return (
<div>
<div className="header-container fixed-top">
        <Header/>
        </div>
        <div className="main-container" id="container">
        <Sidebar/>



          <div id="content" className="main-content">
        <div className="layout-px-spacing">
          <div className="page-header">
            <div className="page-title">
              <h3>Update Password</h3>
            </div>
          </div>
          <div className="card row layout-top-spacing">
            <div id="flFormsGrid" className="col-lg-12 layout-spacing">
              <div className="widget-content widget-content-area">
                <div className="row">
                  <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                    <h4>Change your password</h4>
                  </div>                                                                        
                </div>
                <form>
                  <div className="form-row mb-12">
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Current Password</label>
                      <input type="password" placeholder="Current Password" value={state.current} onChange={handleChange} id="current" name="current" autoComplete="off" className="form-control bg-transparent" />
                    </div>
                    </div>
                  <div className="form-row row mb-12">
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">New Password</label>
                      <input type="password" placeholder="New Password" value={state.newpassword} onChange={handleChange} id="newpassword" name="newpassword" className="form-control bg-transparent" />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Confirm Password</label>
                      <input type="password" placeholder="Confirm New Password" value={state.confirm} onChange={handleChange} id="confirm" name="confirm" className="form-control bg-transparent" />
                    </div>
                   
                  </div></form>
                <button onClick={handleLoginSubmit} className="btn btn-primary mt-3" style={{float: 'right'}} href="">Submit</button>
              </div>
            </div>
          </div>
         <Footer/>
        </div>
      </div>
        </div>
        </div>
        
      
               
        );
 
  };

export default Newpassword;
