import React, {useState,useEffect} from 'react';
import axios from "axios";
import dayjs from "dayjs";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom';
import { CSVLink, CSVDownload } from "react-csv";
import Header from '../../component/layout/header';
import Sidebar from '../../component/layout/sidebar';
import Footer from '../../component/layout/footer';
import html2pdf from 'html2pdf.js';




function Viewinvoice (){
  if (!localStorage.getItem('sl_med_tokenize')) {
    console.log('no auth token set');
    window.location.href="/";
  } else {
        console.log('good token. Log in.')
    }
  const history = useHistory();
  const MySwal = withReactContent(Swal)
  const business_name = localStorage.getItem('sl_med_businessname');
  const fname = localStorage.getItem('sl_med_fname');
  const business_address = localStorage.getItem('sl_med_businessaddress');
  const practise_number = localStorage.getItem('sl_med_practise_number');
  const provider_id = localStorage.getItem('slmed_inv_provider');
  const provider_name = localStorage.getItem('slmed_inv_provider_name');
  const mypostal_address = localStorage.getItem('sl_med_businesspaddress');
  const myvat_id = localStorage.getItem('sl_med_vat_id');
  const myfax = localStorage.getItem('sl_med_fax');
  const phone = localStorage.getItem('sl_med_phone');
  const email = localStorage.getItem('sl_med_email');
  const [loading_, setLoading] = useState(false);
  const [xmldataArray, setXmldataArray] = useState([]);
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); 
  const day = String(currentDate.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  var domToPdf = require('react-dom-to-pdf-byaasif');
  const [patient_info, setpatient] = useState([]);
  const [code_info, setcode] = useState([]);
  const [dr_fee, setfees] = useState([]);
  const [dentist_fee, setfdrees] = useState([]);
  const doctor_id = localStorage.getItem("sl_med_id");
  const token = localStorage.getItem('sl_med_tokenize');
  const myURL = "https://remitaid.starlightsoftware.co.bw/api/fetchpatientbyID";
  const codeURL="https://remitaid.starlightsoftware.co.bw/api/fetchcodes";
  const feeURL="https://remitaid.starlightsoftware.co.bw/api/fetchfees";
  const drfeesURL = "https://remitaid.starlightsoftware.co.bw/api/fetchdrfees";
  const drURL = "https://remitaid.starlightsoftware.co.bw/api/getDoctorById";
  const patient_id = localStorage.getItem('slmed_inv_patient_id');
  const [dr_info, setdr] = useState([]);
  const service_date = formattedDate;
  const icd = localStorage.getItem('remitaid_icd');
  const icd1 = localStorage.getItem('remitaid_icd1');
  const icd2 = localStorage.getItem('remitaid_icd2');
  const icd3 = localStorage.getItem('remitaid_icd3');
  const icd4 = localStorage.getItem('remitaid_icd4');
  const icd5 = localStorage.getItem('remitaid_icd5');
  const code = localStorage.getItem('remitaid_code');
  const code1 = localStorage.getItem('remitaid_code1');
  const code2 = localStorage.getItem('remitaid_code2');
  const code3 = localStorage.getItem('remitaid_code3');
  const code4 = localStorage.getItem('remitaid_code4');
  const code5 = localStorage.getItem('remitaid_code5');
  const qty = localStorage.getItem('remitaid_qty');
  const qty1 = localStorage.getItem('remitaid_qty1');
  const qty2 = localStorage.getItem('remitaid_qty2');
  const qty3 = localStorage.getItem('remitaid_qty3');
  const qty4 = localStorage.getItem('remitaid_qty4');
  const qty5 = localStorage.getItem('remitaid_qty5');
  const teeth = localStorage.getItem('remitaid_teeth');
  const teeth1 = localStorage.getItem('remitaid_teeth1');
  const teeth2 = localStorage.getItem('remitaid_teeth2');
  const teeth3 = localStorage.getItem('remitaid_teeth3');
  const teeth4 = localStorage.getItem('remitaid_teeth4');
  const teeth5 = localStorage.getItem('remitaid_teeth5');
  const csvFileName = 'SLMED_invoice.csv'; // Set your custom CSV file name
  const csvTitle = 'New CSV invoice'; // Set your custom title
  const objectToXML = require('object-to-xml');
  



  console.log(formattedDate);

  const generatePDF = () => {
    var element = document.getElementById('mycontent');
    var options = {
      filename: 'SLMED_Invoice.pdf'
    };
    domToPdf(element, options, function() {
      console.log('done');
    });
  };

  // function generatePDF() {
  //   const element = document.getElementById('mycontent');
  //   const opt = {
  //     margin: 0.5,
  //     filename: 'SLMED_Invoice.pdf',
  //     image: { type: 'jpeg', quality: 0.98 },
  //     html2canvas: { scale: 2 },
  //     jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
  //   };
  //   html2pdf().set(opt).from(element).save();
  // }

  function getCodeDescription(codeInfo) {
    if (codeInfo && codeInfo.description) {
      return codeInfo.description;
    }
    return ''; // Fallback to an empty string if description is missing.
  }

  function getCodecode(codeInfo) {
    if (codeInfo && codeInfo.coder) {
      return codeInfo.coder;
    }
    return ''; // Fallback to an empty string if description is missing.
  }

  function getdrFeeDescription(feeInfo) {
    if (feeInfo && feeInfo.fee) {
      return feeInfo.fee;
    }
    return ''; // Fallback to an empty string if description is missing.
  }

  

  const [state , setState] = useState({
    procedure: '',
    patient_fee: '',
    scheme_fee: '',
    doctor_fee: '',
    vat: '',
    procedure1: '',
    patient_fee1: '',
    scheme_fee1: '',
    doctor_fee1: '',
    vat1: '',
    tot_amount: '',
    vat_amount: '',
    tot_vatamount: '',
    patientdue: '',
    claim_amount: '', 
    tot_patient_fee: '', 
    tot_scheme_fee: '', 
    tot_doctor_fee: '', 
    tot_patient_fee1: '', 
    tot_scheme_fee1: '', 
    tot_doctor_fee1: '', 
    discount: 0,
    patientduedisc: '',
})

    let totalDoctorFee = 0;
    let totalPatientFee = 0;
    let totalVatFee = 0;
    let totalSchemefee = 0;

    // let mydoctor_fee = 0;
    // let mypatient_fee = 0;
    // let myvat_fee = 0;
    // let myscheme_fee = 0;

    const csvData = [
      ["Invoice_Date", "Doctor_Name", "Clinic_Name", "patient_name", "Medical_Aid", "Scheme_Number", "Code", "Description", "ICD_10_codes", "Quantity", "Scheme_Fee"],
      [service_date, fname, business_name, patient_info.firstname, provider_name, patient_info.member_number, getCodecode(code_info[0]), getCodeDescription(code_info[0]), icd, qty, Number(getdrFeeDescription(dr_fee[0]))*Number(qty)*0.9],
    ];
    
    if (code1) {
      csvData.push([service_date, fname, business_name, patient_info.firstname, provider_name, patient_info.member_number,  getCodecode(code_info[1]), getCodeDescription(code_info[1]), icd1, qty1, getdrFeeDescription(dr_fee[1])*Number(qty1)*0.9]);
    }
    
    if (code2) {
      csvData.push([service_date, fname, business_name, patient_info.firstname, provider_name,  patient_info.member_number, getCodecode(code_info[2]), getCodeDescription(code_info[2]), icd2, qty2, getdrFeeDescription(dr_fee[2])*Number(qty2)*0.9]);
    }
    
    if (code3) {
      csvData.push([service_date, fname, business_name, patient_info.firstname, provider_name, patient_info.member_number, getCodecode(code_info[3]), getCodeDescription(code_info[3]), icd3, qty3, getdrFeeDescription(dr_fee[3])*Number(qty3)*0.9]);
    }

    if (code4) {
      csvData.push([service_date, fname, business_name, patient_info.firstname, provider_name, patient_info.member_number, getCodecode(code_info[4]), getCodeDescription(code_info[4]), icd4, qty4, getdrFeeDescription(dr_fee[4])*Number(qty4)*0.9]);
    }

    if (code5) {
      csvData.push([service_date, fname, business_name, patient_info.firstname, provider_name, patient_info.member_number, getCodecode(code_info[5]), getCodeDescription(code_info[5]), icd5, qty5, getdrFeeDescription(dr_fee[5])*Number(qty5)*0.9]);
    }
    
 



  const headers2 = { 
    headers: {
      'AccessToken': JSON.parse(token),
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': "*",
    }
  };

  const goback = (e) => {
    window.location.href = 'newinvoice2'
  }

  const getdrinfo = async (e) => {
    axios.post(drURL, body, headers2)
    .then((response) => {
      console.log("response received: ", response);
      if(!response){
        setdr(0);
      }else{
      setdr(response.data.data[0]);
      setdr(response.data.data[0]);
      console.log(response.data.data);
      }
    });
  };

    const body = {
      'doctor_id':doctor_id,
      'id':patient_id,
      'accesstoken': JSON.parse(token),
    }

    const icdcodes = [icd,icd1,icd2,icd3,icd4,icd5]
    const qtycodes = [qty,qty1,qty2,qty3,qty4,qty5]
    const teethcodes = [teeth,teeth1,teeth2,teeth3,teeth4,teeth5]
    
  
    const getpatientinfo = async (e) => {
      axios.post(myURL, body, headers2)
      .then((response) => {
        console.log("response received: ", response);
        if(!response){
          setpatient(0);
        }else{
        setpatient(response.data.data[0]);
        setpatient(response.data.data[0]);
        console.log(response.data.data[0]);
        }
      });
    };

    const coder = {
      accesstoken: JSON.parse(token),
      code1:  code,
      code2:  code1,
      code3:  code2,
      code4:  code3,
      code5:  code4,
      code6:  code5,
      provider_id: provider_id,
      doctor_id: 2
    };
    console.log(coder)

    const getcodeinfo = async (e) => {
      setLoading(true);
      try {
        const response = await axios.post(codeURL, coder, headers2);
        console.log("response received: ", response);
        if (!response.data.data || response.data.data.length === 0) {
          setcode([]); // Update with an empty array or appropriate value
        } else {
          setLoading(true);
          console.log("response received: ", response);
          setcode(response.data.data.codes); // Update the state with response data
          console.log(code_info); // This might not log the updated value immediately
        }
        const response2 = await axios.post(feeURL, coder, headers2);
    
        console.log("response received: ", response2);
    
        if (!response.data.data || response2.data.data.length === 0) {
          setfees(0);
        } else {
          console.log("response received: ", response2.data.data.fees);
          setfees(response2.data.data.fees);
          console.log(dr_fee);
        }

        const response3 = await axios.post(drfeesURL, coder, headers2);
    
        console.log("response received: ", response3);
    
        if (!response3.data.data || response3.data.data.length === 0) {
          setfdrees(0);
        } else {
          console.log("response received: ", response2.data.data.fees);
          setfdrees(response2.data.data.fees);
          console.log(dentist_fee);
        }

      } catch (error) {
        console.error("An error occurred: ", error);
      }
    };

    const min = 100000; 
    const max = 999999; // Maximum value (inclusive)

    const randomSixDigitNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    console.log(randomSixDigitNumber);


    function getInvoiceNumber () {
      const invoiceNumber = localStorage.getItem('slmed_inv_newinvnumber');
      return invoiceNumber ? invoiceNumber : ''; // Return null if the key doesn't exist
    };
    
    
    const invoiceNumber = getInvoiceNumber();

    const fetchData = async () => {
      try {
        const generatedXmldataArray = [];

        for (let index = 0; index < code_info.length; index++) {
          const info = code_info[index];

          const myscheme_fee = (Number(dr_fee[index]?.fee) * Number(qtycodes[index])) * 0.9;
          const mydoctor_fee = (Number(dentist_fee[index]?.total_fee) - (Number(dr_fee[index]?.fee)));
          const mypatient_fee = ((Number(dr_fee[index]?.fee) * Number(qty)) * 0.1);
          const myvat_fee = (((Number(dr_fee[index]?.fee) * Number(qty)) * 0.9) + ((Number(dr_fee[index]?.fee) * Number(qty)) * 0.1) + (Number(dentist_fee[index]?.total_fee) - (Number(dr_fee[index]?.fee)))) * 0.14;

          allSchemeFees.push(myscheme_fee);
          allDoctorFees.push(mydoctor_fee);
          allPatientFees.push(mypatient_fee);
          allVatFees.push(myvat_fee);

          
          totalDoctorFee += mydoctor_fee;
          totalPatientFee += mypatient_fee;
          totalVatFee += myvat_fee;
          totalSchemefee += myscheme_fee;
          const fullNameArray = patient_info.firstname.split(" ");
          const firstName = fullNameArray[0];
          const lastName = fullNameArray[1];
         


          const xmldata = {
            claimHeader: {
              scheme: provider,
              tradepart: practise_number,
              batchNo: invoiceNumber,
              createDate: formattedDate,
              transactionType: 'Request',
              numTrans: dr_fee.length,
              totClaim: (Number(mydoctor_fee)+Number(mypatient_fee)+Number(myscheme_fee)),
              claims: {
                scriptNum: invoiceNumber,
                extUniqueNum: patient_info.patients_number,
                medAid: patient_info.member_number,
                depIdNum: patient_info.id_number,
                depBirthDate: patient_info.dob,
                depInitials: '',
                depName: firstName,
                depSurname: lastName,
                depGender: patient_info.gender,
                claimDetail: {
                  uniqueSeq: (index + 1).toString(),
                  lineType: 'S',
                  tariffCode: info.coder,
                  tariffDesc: info.description,
                  serviceDate: formattedDate,
                  units: qtycodes[index],
                  claimed: (
                    Number(mypatient_fee) +
                    Number(myscheme_fee)
                  ).toFixed(2),
                  benefit: (Number(myscheme_fee)).toFixed(2),
                  mouthpartid: teethcodes[index],
                  diagnosis: {
                    primSec: 'P',
                    diagType: 'Diagnosis',
                    diagCode: icdcodes[index].split(' ')[0],
                  },
                },
              },
            },
          };

    generatedXmldataArray.push(xmldata);
  }
  setXmldataArray(generatedXmldataArray);
  console.log(generatedXmldataArray)
    
} catch (error) {
        console.error('Error fetching data:', error);
        // Handle error if needed
      }
    };
    
    

    useEffect(() => {
      getpatientinfo();
      getcodeinfo();
      fetchData();
      getdrinfo();
  }, []); 

  



  const handleChange = (e) => {
    const {id , value} = e.target   
    setState(prevState => ({
        ...prevState,
        [id] : value
    }))
  }  

  const allSchemeFees = [];
  const allDoctorFees = [];
  const allPatientFees = [];
  const allVatFees = [];
  const description = [];
  

  const handleInvoice = (e) => {
    e.preventDefault();
    if (!doctor_id || !patient_id) {
      MySwal.fire({
        title: <p>Please enter patient name and Patient Number</p>,
        didOpen: () => {
        },
      }).then(() => {
      });
    }else{
      // console.log(code_info[0].description)
      //  const dashURL = "http://localhost:9550/api/createinvoice";
       const dashURL = "https://remitaid.starlightsoftware.co.bw/api/createinvoice";
       const doctor_id = localStorage.getItem("sl_med_id"); 
       const postData = {
      'accesstoken': JSON.parse(token),
        'doctor_id': doctor_id, 
        'patient_id': patient_id,
        'patient_name': patient_info.firstname,
        'provider': patient_info.provider,
        'created_at': formattedDate,
        'code': code,
        'code_1': code1,
        'code_2': code2,
        'code_3': code3,
        'code_4': code4,
        'code_5': code5,
        'ICD_10': icd,
        'ICD_10_1': icd1,
        'ICD_10_2': icd2,
        'ICD_10_3': icd3,
        'ICD_10_4': icd4,
        'ICD_10_5': icd5,
        'qty': qty,
        'qty_1': qty1,
        'qty_2': qty2,
        'qty_3': qty3,
        'qty_4': qty4,
        'qty_5': qty5,
        'teeth': teeth,
        'teeth_1': teeth1,
        'teeth_2': teeth2,
        'teeth_3': teeth3,
        'teeth_4': teeth4,
        'teeth_5': teeth5,
        'discount': state.discount,
        'scheme_fee1': allSchemeFees[0],
        'scheme_fee2': allSchemeFees[1],
        'scheme_fee3': allSchemeFees[2],
        'scheme_fee4': allSchemeFees[3],
        'scheme_fee5': allSchemeFees[4],
        'scheme_fee6': allSchemeFees[5],
        'doctor_fee1': allDoctorFees[0],
        'doctor_fee2': allDoctorFees[1],
        'doctor_fee3': allDoctorFees[2],
        'doctor_fee4': allDoctorFees[3],
        'doctor_fee5': allDoctorFees[4],
        'doctor_fee6': allDoctorFees[5],
        'vat': allVatFees[0],
        'vat1': allVatFees[1],
        'vat2': allVatFees[2],
        'vat3': allVatFees[3],
        'vat4': allVatFees[4],
        'vat5': allVatFees[5],
        'service': code_info[0]? code_info[0].description : '',
        'service1': code_info[1]? code_info[1].description : '',
        'service2': code_info[2]? code_info[2].description : '',
        'service3': code_info[3]? code_info[3].description : '',
        'service4': code_info[4]? code_info[4].description : '',
        'service5': code_info[5]? code_info[5].description : '',
        'patient_fee1': allPatientFees[0],
        'patient_fee2': allPatientFees[1],
        'patient_fee3': allPatientFees[2],
        'patient_fee4': allPatientFees[3],
        'patient_fee4': allPatientFees[4],
        'patient_fee5': allPatientFees[5],
        'invoice_number': invoiceNumber,
        'patient_information': patientDataString,
        'business_name': business_name,
        'business_address': business_address,
        'business_postal': mypostal_address,
        'business_tel': phone,
        'business_fax': myfax,
        'business_email':email,
        'business_vat':myvat_id,
        'total_amount': (Number(totalDoctorFee)+Number(totalPatientFee)+Number(totalSchemefee) + Number(totalVatFee)).toFixed(2),
      }
      console.log(postData);
      // console.log(allSchemeFees[0], allSchemeFees[1]);
       MySwal.fire({
           title: <p>please wait...</p>,
           didOpen: () => {
             MySwal.showLoading()
             axios.post(dashURL, postData, headers2)
           .then((response) => {
             console.log("response received: ", response);
             Swal.close()
             Swal.fire({
               title: "Successful",
               text: "Invoice Recorded Successfully",
               icon: "success",
               showCancelButton: false,
               confirmButtonText: "Okay",
               closeOnCancel: true,
             }).then(result => {
           window.location.href = 'allinvoice';
             })
             })
             .catch((err) => {
             Swal.close()
               console.log("AXIOS ERROR: ", err);
               if(!err.response){
               return MySwal.fire(err.message)
               }else{
               return MySwal.fire(err.response.data.message)
               }
             })
           },
         }).then(() => {
         })
   }
  };

  let provider = '';
  if (patient_info.provider === '1') {
    provider = 'BOMAID';
  } else if (patient_info.provider === '2') {
    provider = 'BPOMAS';
  } else if (patient_info.provider === '3') {
    provider = 'PULA';
  } else if (patient_info.provider === '4') {
    provider = 'BOTSOGO';
  } else if (patient_info.provider === '5') {
    provider = 'BDF';
  }else if(patient_info.provider==='6'){
    provider = "BPOMAS HIGH"
  }else if(patient_info.provider==='7'){
    provider = "BPOMAS STANDARD"
  }else if(patient_info.provider==='8'){
    provider = "PULA DELUXE"
  }else if(patient_info.provider==='9'){
    provider = "PULA STANDARD"
  }

  const patientDataforupload = 
  {
    'invpatient_name': patient_info.firstname,
    'invpatient_number': patient_info.patients_number,
    'invDOB': patient_info.dob,
    'invCell': patient_info.phone,
    'service_date': service_date,
    'principal_member_name': patient_info.principal_member_name,
    'medical_aid_scheme': provider,
    'membership_no': patient_info.member_number,
    'relationship_to_member': patient_info.relationsip
  }
  const patientDataString = JSON.stringify(patientDataforupload);
  console.log(patientDataString)


const printAndDownloadXML = async (e) => {
  e.preventDefault();
    await fetchData(); 
    const fullNameArray = patient_info.firstname.split(" ");
    const firstName = fullNameArray[0];
    const lastName = fullNameArray[1];
  console.log(xmldataArray)
  const xmlData = {
    claimHeader: {
      scheme: provider,
      tradepart: practise_number,
      batchNo: invoiceNumber,
      createDate: formattedDate,
      transactionType: 'Request',
      numTrans: dr_fee.length,
      totClaim: 
      // (Number(mydoctor_fee)+Number(mypatient_fee)+Number(myscheme_fee)),

      (Number(totalDoctorFee)+Number(totalPatientFee)+Number(totalSchemefee))/2,

      claims: {
        claim: {
          scriptNum: invoiceNumber,
          extUniqueNum: patient_info.patients_number,
          medAid: patient_info.member_number,
          depIdNum: patient_info.member_number,
          depBirthDate: patient_info.dob,
          depInitials: '',
          depName: firstName,
          depSurname: lastName,
          depGender: patient_info.gender,
          claimDetail: xmldataArray.map((xmldata, index) => {
            return {
              uniqueSeq: xmldata.claimHeader.claims.claimDetail.uniqueSeq,
              lineType: xmldata.claimHeader.claims.claimDetail.lineType,
              tariffCode: xmldata.claimHeader.claims.claimDetail.tariffCode,
              tariffDesc: xmldata.claimHeader.claims.claimDetail.tariffDesc,
              serviceDate: xmldata.claimHeader.claims.claimDetail.serviceDate,
              units: xmldata.claimHeader.claims.claimDetail.units,
              claimed: xmldata.claimHeader.claims.claimDetail.claimed,
              benefit: xmldata.claimHeader.claims.claimDetail.benefit,
              mouthpartid: xmldata.claimHeader.claims.claimDetail.mouthpartid,
              diagnosis: xmldata.claimHeader.claims.claimDetail.diagnosis,
            };
          }),
        },
      },
    },
  };

  // const { js2xml } = require('xml-js');
  const xmlDataToDownload = xmldataArray.length > 0 ? xmlData : null;

  if (xmlDataToDownload) {
    const xmlString = objectToXML(xmlDataToDownload);
    console.log(xmlString)
    // Create a Blob with the XML content
      const blob = new Blob([xmlString], { type: 'text/xml' });

      // Create a link element
      const link = document.createElement('a');

      // Set link attributes
      link.href = window.URL.createObjectURL(blob);
      link.download = 'output.xml';

      // Append the link to the document
      document.body.appendChild(link);

      // Trigger a click event on the link to start the download
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
  } else{
    console.log('nothing')
  }
  

  console.log(objectToXML(xmlData));
  // Add logic to print and download XML as needed
};






      return (
<div>
        <div className="header-container fixed-top">
        <Header/>
        </div>
        <div className="main-container" id="container">
        <Sidebar/>



      <div id="content" className="main-content">
        <div className="layout-px-spacing">
          <div className="page-header">
            <div className="page-title">
              <h3>Invoice</h3>
              <button type="submit" onClick={goback} className="btn btn-danger mt-3" >Go Back</button> &nbsp;&nbsp;&nbsp;&nbsp;
              <button type="submit" onClick={getcodeinfo} className ="btn btn-warning mt-3" >COMPUTE</button>&nbsp;
              <button type="submit" onClick={generatePDF} className="btn btn-primary mt-3" >Download</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {/* <CSVLink data={csvData} filename={csvFileName} target={csvTitle}> <button type="submit" className="btn btn-success mt-3" >CSV</button>&nbsp;</CSVLink> */}
              {/* <button type="submit" onClick={printAndDownloadXML} className="btn btn-info mt-3" >XML</button>&nbsp; */}
              {/* <button onClick={fetchData} className="btn btn-danger mt-3" >Send to Medical Scheme</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
              <button type="submit" onClick={handleInvoice} className="btn btn-success mt-3" >COMPLETE</button>

            </div>
          </div>
          <div className="row invoice layout-top-spacing">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="app-hamburger-container">
                <div className="hamburger"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-menu chat-menu d-xl-none"><line x1={3} y1={12} x2={21} y2={12} /><line x1={3} y1={6} x2={21} y2={6} /><line x1={3} y1={18} x2={21} y2={18} /></svg></div>
              </div>

              <div className="doc-container">
                <div className="invoice-container">
                  <div className="invoice-inbox">
                    <div className="invoice-header-section">
                      <h4 className="inv-number" />
                      <div className="invoice-action">
                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-printer action-print" data-toggle="tooltip" data-placement="top" data-original-title="Reply"><polyline points="6 9 6 2 18 2 18 9" /><path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2" /><rect x={6} y={14} width={12} height={8} /></svg>
                      </div>
                    </div>

                    <div>
                      <div className="invoice-00001" id='mycontent'>
                        <div className="content-section ">
                        <br/>
                          <br/>
                          <br/>
                          <br/>
                          <div className="row inv--head-section">
                            <div className="col-sm-3 col-12">
                              <h3 className="in-heading">INVOICE</h3>
                            </div>
                            <div className="col-sm-9 col-12 text-sm-right">
                              <div className="company-info">
                                <div className="col-sm-6 align-self-center  text-sm-right order-2">
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row inv--detail-section">
                            <div className="col-sm-7 align-self-center">
                            <br/>
                              <br/>
                              <br/>
                              {/* <img src={dr_info.logo} style={{width:'20%'}} alt="Doctor Logo"/> */}
                              <br/>
                              <br/>
                            </div>
                            <div className="col-sm-5 align-self-center  text-sm-right order-sm-0 order-1">
                                  <p className="inv-street-addr"><b>Clinic Name: </b>{business_name}</p>
                                  <p className="inv-street-addr"><b>Physical Address: </b>{business_address}</p>
                                  <p className="inv-street-addr"><b>Postal Address: </b>{mypostal_address}</p>
                                  <p className="inv-street-addr"><b>Tel:</b> {phone}</p>
                                  <p className="inv-street-addr"><b>Fax: </b>{myfax}</p>
                                  <p className="inv-street-addr"><b>Email: </b>{email}</p>
                                  <p className="inv-street-addr"><b>VAT: </b>{myvat_id}</p>
                            </div>
                            <div className="col-sm-7 align-self-center">
                            <br/>
                              <br/>
                              <br/>
                              <br/>
                              
                              <p className="inv-to"><b>Patient information</b></p>
                              <p className="inv-customer-name">Invoice Number: {invoiceNumber}</p>
                              <p className="inv-customer-name">Patient File No: {patient_info.patients_number}</p>
                              <p className="inv-street-addr">Patient Name: {patient_info.firstname}</p>
                              <p className="inv-email-address">Date Of Birth: {patient_info.dob}</p>
                              <p className="inv-email-address">Cell: {patient_info.phone}</p>
                              <p className="inv-email-address">Service Date: {service_date}</p>
                              <br />
                              <p className="inv-customer-name"><b>Diagnosis</b></p>
                              <p className="inv-street-addr">{icd}</p>
                              <p className="inv-street-addr">{icd1}</p>
                              <p className="inv-street-addr">{icd2}</p> 
                              <p className="inv-street-addr">{icd3}</p>
                              <p className="inv-street-addr">{icd4}</p>
                              <p className="inv-street-addr">{icd5}</p>
                              <br/>
                              <br/>
                            </div>
                            <div className="col-sm-5 align-self-center  text-sm-right order-2">
                              <p className="inv-customer-name">Principal Member Name :  {patient_info.principal_member_name}</p>
                              <p className="inv-street-addr">Medical Aid Scheme: {provider}</p>
                              <p className="inv-street-addr">Membership No: {patient_info.member_number}</p>
                              <p className="inv-street-addr">Relationship To Member: {patient_info.relationsip}</p>
                              {/* <p className="inv-street-addr">Tel:</p> */}
                            </div>
                          </div>
                          <div className="row inv--product-table-section">
                            <div className="col-12">
                              <div className="table-responsive">
                             
                              <table className="table">
                                  <thead className>
                                    <tr>
                                      <th scope="col">Code</th>
                                      <th scope="col">ICD - 10</th>
                                      <th scope="col">Service</th>
                                      <th className="" scope="col">Teeth</th>
                                      <th className="" scope="col">Qty</th> 
                                      <th className="" scope="col">Scheme Fee</th>
                                      <th className="" scope="col">Doctor Fee</th>
                                      <th className="" scope="col">Patient Due</th>
                                      <th className="" scope="col">Vat</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                  {Array.isArray(code_info) && code_info.length > 0 ? (
                                    code_info.map((info, index) => {
                                      const myscheme_fee = (Number(dr_fee[index]?.fee) * Number(qtycodes[index])) * 0.9;
                                      const mydoctor_fee = (Number(dentist_fee[index]?.total_fee) - (Number(dr_fee[index]?.fee)));
                                      const mypatient_fee = ((Number(dr_fee[index]?.fee) * Number(qty)) * 0.1);
                                      // const myvat_fee = (((Number(dr_fee[index]?.fee) * Number(qty)) * 0.9) + ((Number(dr_fee[index]?.fee) * Number(qty)) * 0.1) + (Number(dentist_fee[index]?.total_fee) - (Number(dr_fee[index]?.fee)))) * 0.14;
                                      const myvat_fee = 0;

                                      allSchemeFees.push(myscheme_fee);
                                      allDoctorFees.push(mydoctor_fee);
                                      allPatientFees.push(mypatient_fee);
                                      allVatFees.push(myvat_fee);

                                      
                                      totalDoctorFee += mydoctor_fee;
                                      totalPatientFee += mypatient_fee;
                                      totalVatFee += myvat_fee;
                                      totalSchemefee += myscheme_fee;
                                     

                                      return (
                                        <tr key={index}>
                                          <td>{info.coder}</td>
                                          <td>{icdcodes[index]}</td>
                                          <td className="">{info.description}</td>
                                          <td className="">{teethcodes[index]}</td>
                                          <td className="">{qtycodes[index]}</td>
                                          <td className=""> {myscheme_fee.toFixed(2)}</td>
                                          <td className=""> {mydoctor_fee.toFixed(2)}</td>
                                          <td className="">{mypatient_fee.toFixed(2)}</td>
                                          <td className="">  {myvat_fee.toFixed(2)}</td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr>
                                      <td colSpan="8">No data available, click the "COMPUTE" button.</td>
                                    </tr>
                                  )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col-sm-5 col-12 order-sm-0 order-1">
                            </div>
                            <div className="col-sm-7 col-12 order-sm-1 order-0">
                              <div className="inv--total-amounts text-sm-right">
                                <div className="row">
                                <div className="col-sm-8 col-7">
                                    <p className>Discount (%): </p>
                                  </div>
                                  <div className="col-sm-4 col-5">
                                    <input type="number"  value={state.discount} id="discount" name="discount" onChange={handleChange} className="form-control"  />
                                  </div>
                                  <div className="col-sm-8 col-7">
                                    <p className>Total Amount: </p>
                                  </div>
                                  <div className="col-sm-4 col-5">
                                    <p className> {(Number(totalDoctorFee)+Number(totalPatientFee)+Number(totalSchemefee)).toLocaleString('en-BW', { style: 'currency', currency: 'BWP' })}</p>
                                  </div>
                                  <div className="col-sm-8 col-7">
                                    <p className>Vat Amount: </p>
                                  </div>
                                  <div className="col-sm-4 col-5">
                                    <p className>{(totalVatFee).toLocaleString('en-BW', { style: 'currency', currency: 'BWP' })}</p>
                                  </div>
                                  <div className="col-sm-8 col-7">
                                    <p className=" discount-rate">Total (Vat Inclusive): </p>
                                  </div>
                                  <div className="col-sm-4 col-5">
                                    <p className> {(Number(totalDoctorFee)+Number(totalPatientFee)+Number(totalSchemefee) + Number(totalVatFee)).toLocaleString('en-BW', { style: 'currency', currency: 'BWP' })}</p>
                                  </div>
                                  <div className="col-sm-8 col-7">
                                    <p className>Total (Due From Patient): </p>
                                  </div>
                                  <div className="col-sm-4 col-5">
                                    <p className> {(Number(totalPatientFee)+Number(totalDoctorFee)+Number(totalVatFee)).toLocaleString('en-BW', { style: 'currency', currency: 'BWP' })}</p>
                                  </div>
                                  <div className="col-sm-8 col-7">
                                    <p className>Total (Due From Patient Discounted): </p>
                                  </div>
                                  <div className="col-sm-4 col-5">
                                    <p className> {((Number(totalPatientFee)+Number(totalDoctorFee)+Number(totalVatFee)) - (Number(totalPatientFee)+Number(totalDoctorFee)+Number(totalVatFee)) * Number(state.discount)/100).toLocaleString('en-BW', { style: 'currency', currency: 'BWP' })}</p>
                                  </div>
                                  
                                  <div className="col-sm-8 col-7">
                                    <p className>Amount (Claimed From Medical Aid): </p>
                                  </div>
                                  <div className="col-sm-4 col-5">
                                    <p className> {(totalSchemefee).toLocaleString('en-BW', { style: 'currency', currency: 'BWP' })}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <br/>
                          <br/>
                          <br/> <br/>
                          <br/>
                          <br/>
                          <div className="row">
                            <div className="col-6">
                              <div className="col-sm-7 align-self-center">
                                <p className="inv-to">Patient Signature: ___________</p>
                              </div>
                              <div className="col-sm-8 col-7">
                                <p className>Date: ____________ </p>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="col-sm-7 align-self-center">
                                <p className="inv-to">Doctor's Signature: ___________</p>
                              </div>
                              <div className="col-sm-8 col-7">
                                <p className>Date: ____________ </p>
                              </div>
                            </div>
                          </div>
                          <br />
                          <br />
                          <b>Dr. {fname} Practise No: {practise_number}</b>
                          <br/>
                          <br/>
                          <br/>
                          <br />
                          <br />
                          <br />
                          <br />
                          <br/>
                          <br/>
                          <br/>
                          <br />
                          <br />
                          <br />
                          <br /> <br/>
                          <br/>
                          <br/>
                          <br />
                          <br />
                          <br />
                          <br /> <br/>
                          <br/>
                          <small style={{ position: "absolute", bottom: "10", width: "100%", textAlign: "left" }}>
                              Powered by Starlight Software Solutions (PTY) LTD - info@starlightsoftware.co.bw
                            </small>
                        </div>
                       
                      </div> 
                    </div>
                  </div>
                  <div className="inv--thankYou">
                    <div className="row">
                      <div className="col-sm-12 col-12">
                        <p className>Developed by Starlight Software Solutions (Pty) Ltd.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       <Footer/>
      </div> 
        </div>
        </div>
        
      
               
        );
 
  };

export default Viewinvoice;
