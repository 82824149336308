import React, {useState,useEffect} from 'react';
import axios from "axios";
import dayjs from "dayjs";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom';
import Header from '../../component/layout/header';
import Sidebar from '../../component/layout/sidebar';
import Footer from '../../component/layout/footer';

function Uploadpatient (){
  if (!localStorage.getItem('sl_med_tokenize')) {
    console.log('no auth token set');
    window.location.href="/";
  } else {
        console.log('good token. Log in.')
    }
  const history = useHistory();
  const MySwal = withReactContent(Swal)
  const first_name = localStorage.getItem('sl_med_fname');

  const [state , setState] = useState({
    name: '',
    email: '',
    amount: '',
    file: null,
    base64URL: ''
})
  


      return (
<div>
<div className="header-container fixed-top">
        <Header/>
        </div>
        <div className="main-container" id="container">
        <Sidebar/>



          <div id="content" className="main-content">
        <div className="layout-px-spacing">
          <div className="page-header">
            <div className="page-title">
              <h3>Upload bulk Patient Information</h3>
            </div>
          </div>
          <div className="card row layout-top-spacing">
            <div id="flFormsGrid" className="col-lg-12 layout-spacing">
              <div className="widget-content widget-content-area">
                <div className="row">
                  <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                    <a  disabled className="btn btn-lg btn-success mt-3" >Download sample document</a>
                  </div>                                                                        
                </div>
                <form>
                  <br />
                  <br />
                  <div className="form-row mb-12">
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Enter Patient No.</label>
                      {/* <input type="file" className="form-control" /> */}
                      <br/>
                      <br/>
                      <br/>
                      <small style={{color: 'red'}}>This feature is currently unavailable.</small>
                    </div>
                  </div></form>
                <a disabled className="btn btn-primary mt-3" style={{float: 'right'}}>Upload</a>
              </div>
            </div>
          </div>
         <Footer/>
        </div>
      </div>
        </div>
        </div>
        
      
               
        );
 
  };

export default Uploadpatient;
