import React, {useState,useEffect} from 'react';
import axios from "axios";
import dayjs from "dayjs";
import Select from 'react-select';
import Swal from 'sweetalert2';
import icd10Codes from './icd10Codes';
import Codes from './Codes';
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom';
import Header from '../../component/layout/header';
import Sidebar from '../../component/layout/sidebar';
import Footer from '../../component/layout/footer';

function Newinvoice2 (){
  if (!localStorage.getItem('sl_med_tokenize')) {
    console.log('no auth token set');
    window.location.href="/";
  } else {
        console.log('good token. Log in.')
    }
  const history = useHistory();
  const MySwal = withReactContent(Swal)
  const urlParams = new URLSearchParams(window.location.search);
  const first_name = localStorage.getItem('sl_med_fname');
  const provider_id = localStorage.getItem('slmed_inv_provider');
  const doctor_id = localStorage.getItem('sl_med_id');

  const [state , setState] = useState({
    code: '',
    code1: '',
    code2: '',
    code3: '',
    code4: '',
    code5: '',
    icd: '',
    icd1: '',
    icd2: '',
    icd3: '',
    icd4: '',
    icd5: '',
    teeth: '',
    teeth1: '',
    teeth2: '',
    teeth3: '',
    teeth4: '',
    teeth5: '',
    qty: '',
    qty1: '',
    qty2: '',
    qty3: '',
    qty4: '',
    qty5: '',
})

const business_name = localStorage.getItem('sl_med_businessname');

let slmed_provider_name = ""
if (provider_id==1){
  slmed_provider_name = "BOMAID"
}else if(provider_id==2){
  slmed_provider_name = "BPOMAS"
}else if(provider_id==3){
  slmed_provider_name = "PULA"
}else if(provider_id==4){
  slmed_provider_name = "BOTSOGO"
}else if(provider_id==5){
  slmed_provider_name = "BDF"
}else if(provider_id==6){
  slmed_provider_name = "BPOMAS HIGH"
}else if(provider_id==7){
  slmed_provider_name = "BPOMAS STANDARD"
}else if(provider_id==8){
  slmed_provider_name = "PULA DELUXE"
}else if(provider_id==9){
  slmed_provider_name = "PULA STANDARD"
}


const goBack = (e) => {
  console.log('ey')
  window.location.href='newinvoice'; 
};

  const handleChange = (e) => {
    const {id , value} = e.target   
    setState(prevState => ({
        ...prevState,
        [id] : value
    }))
  }
  
  const token = localStorage.getItem('sl_med_tokenize');
  const myURL = "https://remitaid.starlightsoftware.co.bw/api/fetchaidsbyID";
  // const invURL = "http://localhost:9550/api/newinvoicenumber";
  const invURL = "https://remitaid.starlightsoftware.co.bw/api/newinvoicenumber";
  const [my_info, setinfo] = useState();


  const body = {
    'provider_id':provider_id,
    'accesstoken': JSON.parse(token),
  }
  const headers2 = { 
    headers: {
      'AccessToken': JSON.parse(token),
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': "*",
    }
  };

  const getaids = async (e) => {
    axios.post(myURL, body, headers2)
    .then((response) => {
      console.log("response received: ", response);
      if(!response){
        setinfo(0);
      }else{
      console.log("response received: ", response);
      setinfo(response.data.data[0].provider_name);
      localStorage.setItem('slmed_inv_provider_name', response.data.data[0].provider_name);
      }
    });
  };

  useEffect(() => {
    getaids();
  }, []); 

  
  const handleChange2 = (fieldName) => (selectedOption) => {
    setState((prevState) => ({
      ...prevState,
      [fieldName]: selectedOption.value
    }));
  };

  const [isHidden, setIsHidden] = useState(true);
  const handleClick = () => {
    setIsHidden(false);
  };

  var postedData = {
    "doctor_id": doctor_id,
};

let axiosConfig = {
  headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      "Access-Control-Allow-Origin": "*",
  }
};

  const handlenewInvoice = (e) => {
    if (!state.code || !state.icd || !state.qty) {
      Swal.fire({
        title: "Error",
        text: "Please enter all fields in the first line",
        showCancelButton: true,
        closeOnCancel: true,
        cancelButtonText: "Cancel"
      }).then(result => {
        // Handle cancel action if needed
      });
    } else {
      MySwal.fire({
        didOpen: () => {
          MySwal.showLoading();
          axios.post(invURL, postedData, axiosConfig)
            .then((response) => {
              console.log("response received: ", response);
              Swal.close();
              console.log(response.data.data);
              // Store data in localStorage
              localStorage.setItem('slmed_inv_newinvnumber', response.data.data);
              localStorage.setItem('remitaid_code', state.code);
              localStorage.setItem('remitaid_icd', state.icd);
              localStorage.setItem('remitaid_qty', state.qty);
              localStorage.setItem('remitaid_teeth', state.teeth);
              localStorage.setItem('remitaid_code1', state.code1);
              localStorage.setItem('remitaid_icd1', state.icd1);
              localStorage.setItem('remitaid_qty1', state.qty1);
              localStorage.setItem('remitaid_teeth1', state.teeth1);
              localStorage.setItem('remitaid_code2', state.code2);
              localStorage.setItem('remitaid_icd2', state.icd2);
              localStorage.setItem('remitaid_qty2', state.qty2);
              localStorage.setItem('remitaid_teeth2', state.teeth2);
              localStorage.setItem('remitaid_code3', state.code3);
              localStorage.setItem('remitaid_icd3', state.icd3);
              localStorage.setItem('remitaid_qty3', state.qty3);
              localStorage.setItem('remitaid_teeth3', state.teeth3);
              localStorage.setItem('remitaid_code4', state.code4);
              localStorage.setItem('remitaid_icd4', state.icd4);  
              localStorage.setItem('remitaid_qty4', state.qty4);
              localStorage.setItem('remitaid_teeth4', state.teeth4);
              localStorage.setItem('remitaid_code5', state.code5);
              localStorage.setItem('remitaid_icd5', state.icd5);
              localStorage.setItem('remitaid_qty5', state.qty5);
              localStorage.setItem('remitaid_teeth5', state.teeth5);
             window.location.href="viewinvoice";
            })
            .catch((err) => {
              Swal.close();
              console.log("AXIOS ERROR: ", err);
              return MySwal.fire(err.response.data.message);
            });
        },
      }).then(() => {
        // Handle after the swal action if needed
      });
    }
  };
  
  


      return (
<div>
        <div className="header-container fixed-top">
        <Header/>
        </div>
        <div className="main-container" id="container">
        <Sidebar/>



          <div id="content" className="main-content">
        <div className="layout-px-spacing">
          <div className="page-header">
            <div className="page-title">
              <h3>New invoice</h3>
            </div>
          </div>
          <div className="card row layout-top-spacing">
            <div id="flFormsGrid" className="col-lg-12 layout-spacing">
              <div className="widget-content widget-content-area">
                <div className="row">
                  <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                    <h4>Enter invoice lines ({slmed_provider_name})<span>
                        <button type="submit" onClick={handleClick} className="btn btn-primary mt-3" style={{float: 'right'}}>Add more lines</button>
                      </span></h4>
                    <p style={{color: 'red'}}>Please enter  invoice information as applicable below, leave bottom lines empty if not required.</p>
                  </div>                                                                        
                </div>
                <form>
                  <br />
                  <br />
                  <div className="form-row mb-12">
                    <div className="form-group col-md-4">
                      <label htmlFor="inputPassword4">Code</label>
                          <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.code, label: state.code }}  onChange={handleChange2('code')}  
                           options={Codes}
                          />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="inputPassword4">ICD-10</label>
                          <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.icd, label: state.icd }}  onChange={handleChange2('icd')}  
                           options={icd10Codes}
                          />
                    </div>
                    <div className="form-group col-md-2">
                      <label htmlFor="inputPassword4">Qty</label>
                      <input type="number"  value={state.qty} id="qty" name="qty" onChange={handleChange} className="form-control"  />

                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="inputPassword4">Teeth</label>
                      <input type="text" value={state.teeth} id="teeth" name="teeth" onChange={handleChange} className="form-control"  />
                    </div>
                  </div>
                  <div className="form-row mb-12">
                  <div className="form-group col-md-4">
                  <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.code1, label: state.code1 }}  onChange={handleChange2('code1')}  
                           options={Codes}
                          />
                        </div>
                    <div className="form-group col-md-3">
                          <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.icd1, label: state.icd1 }}  onChange={handleChange2('icd1')}  
                           options={icd10Codes}
                          />
                    </div>
                    <div className="form-group col-md-2">
                      <input type="number"  value={state.qty1} id="qty1" name="qty1" onChange={handleChange} className="form-control"  />
                    </div>
                    <div className="form-group col-md-3">
                      <input type="text" value={state.teeth1} id="teeth1" name="teeth1" onChange={handleChange} className="form-control"  />
                    </div>
                  </div>
                 
                  <div className="form-row mb-12">
                  <div className="form-group col-md-4">
                  <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.code2, label: state.code2 }}  onChange={handleChange2('code2')}  
                           options={Codes}
                          />
                        </div>
                        <div className="form-group col-md-3">
                          <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.icd2, label: state.icd2 }}  onChange={handleChange2('icd2')}  
                           options={icd10Codes}
                          />
                    </div>
                    <div className="form-group col-md-2">
                      <input type="number"  value={state.qty2} id="qty2" name="qty2" onChange={handleChange} className="form-control"  />
                    </div>
                    <div className="form-group col-md-3">
                      <input type="text" value={state.teeth2} id="teeth2" name="teeth2" onChange={handleChange} className="form-control"  />
                    </div>
                  </div>
                  <div className="form-row mb-12">
                  <div className="form-group col-md-4">
                  <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.code3, label: state.code3 }}  onChange={handleChange2('code3')}  
                           options={Codes}
                          />
                        </div>
                    <div className="form-group col-md-3">
                    <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.icd3, label: state.icd3 }}  onChange={handleChange2('icd3')}  
                           options={icd10Codes}
                          />
                    </div>
                    <div className="form-group col-md-2">
                      <input type="number"  value={state.qty3} id="qty3" name="qty3" onChange={handleChange} className="form-control"  />
                    </div>
                    <div className="form-group col-md-3">
                      <input type="text" value={state.teeth3} id="teeth3" name="teeth3" onChange={handleChange} className="form-control"  />
                    </div>
                  </div>

                  {!isHidden &&
                  <div className="form-row mb-12">
                  <div className="form-group col-md-4">
                  <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.code4, label: state.code4 }}  onChange={handleChange2('code4')}  
                           options={Codes}
                          />
                        </div>
                    <div className="form-group col-md-3">
                    <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.icd4, label: state.icd4 }}  onChange={handleChange2('icd4')}  
                           options={icd10Codes}
                          />
                    </div>
                    <div className="form-group col-md-2">
                      <input type="number"  value={state.qty4} id="qty4" name="qty4" onChange={handleChange} className="form-control"  />
                    </div>
                    <div className="form-group col-md-3">
                      <input type="text" value={state.teeth4} id="teeth4" name="teeth4" onChange={handleChange} className="form-control"  />
                    </div>
                  </div>
                      }
                      {!isHidden &&
                  <div className="form-row mb-12">
                  <div className="form-group col-md-4">
                  <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.code5, label: state.code5 }}  onChange={handleChange2('code5')}  
                           options={Codes}
                          />
                        </div>
                    <div className="form-group col-md-3">
                    <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.icd5, label: state.icd5 }}  onChange={handleChange2('icd5')}  
                           options={icd10Codes}
                          />
                      
                    </div>
                    <div className="form-group col-md-2">
                      <input type="number"  value={state.qty5} id="qty5" name="qty5" onChange={handleChange} className="form-control"  />
                    </div>
                    <div className="form-group col-md-3">
                      <input type="text" value={state.teeth5} id="teeth5" name="teeth5" onChange={handleChange} className="form-control"  />
                    </div>
                  </div>
}
                </form>
              </div>
              <button onClick={goBack} type="submit" className="btn btn-danger mt-3">Back</button>
              <button  onClick={handlenewInvoice} className="btn btn-primary mt-3" style={{float: 'right'}}>Complete</button>
            </div>
          </div>
        </div>
       <Footer/>
      </div>
        </div>
        </div>
        
      
               
        );
 
  };

export default Newinvoice2;
