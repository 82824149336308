import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
 
// Initialize Firebase
const app = initializeApp ({
  apiKey: "AIzaSyC0PCNqTDJVHInYeIq40knV5CdHrn0nTTY",
  authDomain: "slmed-fb76b.firebaseapp.com",
  projectId: "slmed-fb76b",
  storageBucket: "slmed-fb76b.appspot.com",
  messagingSenderId: "7615066250",
  appId: "1:7615066250:web:4ddaaf23f75d06a903fd73",
  measurementId: "G-70F3SMDQCW"
});

 
// Firebase storage reference
const storage = getStorage(app);
export default storage;