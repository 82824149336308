import React, {useState,useEffect} from 'react';
import axios from "axios";
import dayjs from "dayjs";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom';
import Header from '../../component/layout/header';
import Sidebar from '../../component/layout/sidebar';
import Footer from '../../component/layout/footer';


function Updatebusiness (){
  if (!localStorage.getItem('sl_med_tokenize')) {
    console.log('no auth token set');
    window.location.href="/";
  } else {
        console.log('good token. Log in.')
    }
  const history = useHistory();
  const MySwal = withReactContent(Swal);
  const business_name = localStorage.getItem('sl_med_businessname');
  const fname = localStorage.getItem('sl_med_fname');
  const [code_info, setcode] = useState([]);
  const [state , setState] = useState({
    fee: '',
    provider: '',
    code: ''
})

useEffect(() => {
  if (code_info && code_info.dr_fee) {
    setState(prevState => ({
      ...prevState,
      fee: code_info.dr_fee
    }));
  }
}, [code_info]);

const [isHidden, setIsHidden] = useState(true);

const token = localStorage.getItem('sl_med_tokenize');
const headers2 = { 
  headers: {
    'AccessToken': JSON.parse(token),
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': "*",
  }
};
  
const handleChange = (e) => {
  const {id , value} = e.target   
  setState(prevState => ({
      ...prevState,
      [id] : value
  }))
} 

const handlesearch = (e) => {
  e.preventDefault();
  if (!state.code || !state.provider) {
    MySwal.fire({
      title: <p>Please enter all fields</p>,
    });
  } else {
    const dashURL = "https://remitaid.starlightsoftware.co.bw/api/getdrfeesById";
    // const dashURL = "http://localhost:9550/api/getdrfeesById";
    const doctor_id = localStorage.getItem("sl_med_id"); 
    const postData = {
      'code': state.code, 
      'accesstoken': token,
      'providerId': state.provider,
      'doctorId': doctor_id
    };
    console.log(postData);
    MySwal.fire({
      title: <p>Please wait...</p>,
      didOpen: () => {
        MySwal.showLoading();
        axios.post(dashURL, postData, headers2)
          .then((response) => {
            console.log("Response received: ", response);
            MySwal.close();
            MySwal.fire({
              title: "Successful",
              text: "Code fetched successfully",
              icon: "success",
              confirmButtonText: "Okay",
            });
            setcode(response.data.data[0]);
            setcode(response.data.data[0]);
            setcode(response.data.data[0]);
          setIsHidden(false);
          })
          .catch((err) => {
            console.log("AXIOS ERROR: ", err);
            MySwal.close();
            const errorMessage = err.response ? err.response.data.message : err.message;
            MySwal.fire(errorMessage);
          });
      }
    });
  }
};

const handleupdate = (e) => {
  e.preventDefault();
  if (!state.code || !state.provider) {
    MySwal.fire({
      title: <p>Please enter all fields</p>,
    });
  } else {
    const updateURL = "https://remitaid.starlightsoftware.co.bw/api/updatedoctorfee";
    // const updateURL = "http://localhost:9550/api/updatedoctorfee";

    const doctor_id = localStorage.getItem("sl_med_id"); 
    const postupdateData = {
      'code': state.code, 
      'accesstoken': token,
      'provider_id': state.provider,
      'doctor_id': doctor_id,
      'new_fee': state.fee
    };
    console.log(postupdateData);
    MySwal.fire({
      title: <p>Please wait...</p>,
      didOpen: () => {
        MySwal.showLoading();
        axios.post(updateURL, postupdateData, headers2)
          .then((response) => {
            console.log("Response received: ", response);
            MySwal.close();
            MySwal.fire({
              title: "Successful",
              text: "Code Updated successfully",
              icon: "success",
              confirmButtonText: "Okay",
            }).then(() => {
              window.location.reload(false);
            });
          })
          .catch((err) => {
            console.log("AXIOS ERROR: ", err);
            MySwal.close();
            const errorMessage = err.response ? err.response.data.message : err.message;
            MySwal.fire(errorMessage);
          });
      }
    });
  }
};



      return (
<div>
        <div className="header-container fixed-top">
        <Header/>
        </div>
        <div className="main-container" id="container">
        <Sidebar/>




          <div id="content" className="main-content">
        <div className="layout-px-spacing">
          <div className="page-header">
            <div className="page-title">
              <h3>Update Business Fees</h3>
            </div>
          </div>
          <div className="card row layout-top-spacing">
            <div id="flFormsGrid" className="col-lg-12 layout-spacing">
              <div className="widget-content widget-content-area">
                <div className="row">
                <button onClick={() => window.location.reload(false)} className="btn btn-success mt-3" style={{float: 'right'}}>Restart</button>
                  <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                    <br></br>
                  <br></br>
                 
                    <h4>Edit your business fees</h4>
                  </div>                                                                        
                </div>

                <form>
                  <div className="form-row mb-12">
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Select Medical Aid</label>
                      <select className="form-control" value={state.provider} onChange={handleChange} id="provider" name="provider">
                        <option selected>Choose...</option>
                        <option value={''}>None</option>
                        <option value={'1'}>BOMAID</option>
                        <option value={'2'}>BPOMAS</option>
                        <option value={'3'}>PULA</option>
                        <option value={'4'}>BOTSOGO</option>
                        <option value={'5'}>BDF</option>
                        <option value={'6'}>BPOMAS HIGH</option>
                        <option value={'7'}>BPOMAS STANDARD</option>
                        <option value={'8'}>PULA DELUXE</option>
                        <option value={'9'}>PULA STANDARD</option>
                      </select>
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Enter Code to Search for</label>
                      <input type="text" value={state.code} onChange={handleChange} id="code" name="code" className="form-control" />
                    </div>
                  </div>
                  </form>
                  {!isHidden &&
                <form>
                  <div className="form-row mb-12">
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Enter Code</label>
                      <input type="text" disabled value={state.code} className="form-control" />
                    </div>

                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Update New Dr Fee</label>
                      <input type="text" value={state.fee} onChange={handleChange} id="fee" name="fee" className="form-control" />
                    </div>
                    {/* {code_info.description? code_info.description : '' } */}

                    
                  </div>
              <button onClick={handleupdate}  className="btn btn-primary mt-3" style={{float: 'right'}} href="">Update</button>

                  </form>
            }
              </div>
              {isHidden &&
              <button onClick={handlesearch}  className="btn btn-primary mt-3" style={{float: 'right'}} href="">Submit</button>
              }
            </div>
          </div>
        <Footer/>
        </div>
      </div>
        </div>
        </div>
        
      
               
        );
 
  };

export default Updatebusiness;
