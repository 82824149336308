import React, {useState,useEffect} from 'react';
import axios from "axios";
import dayjs from "dayjs";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom';
import Header from '../../component/layout/header';
import Sidebar from '../../component/layout/sidebar';
import Footer from '../../component/layout/footer';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

function Viewquote (){
  if (!localStorage.getItem('sl_med_tokenize')) {
    console.log('no auth token set');
    window.location.href="/";
  } else {
        console.log('good token. Log in.')
    }
  const history = useHistory();
  const MySwal = withReactContent(Swal)
  const [dr_info, setdr] = useState([]);
  const drURL = "https://remitaid.starlightsoftware.co.bw/api/getDoctorById";
  const business_name = localStorage.getItem('sl_med_businessname');
  const fname = localStorage.getItem('sl_med_fname');
  const business_address = localStorage.getItem('sl_med_businessaddress');
  const practise_number = localStorage.getItem('sl_med_practise_number');
  const provider_id = localStorage.getItem('slmed_inv_provider');
  const provider_name = localStorage.getItem('slmed_inv_provider_name');
  const mypostal_address = localStorage.getItem('sl_med_businesspaddress');
  const myvat_id = localStorage.getItem('sl_med_vat_id');
  const myfax = localStorage.getItem('sl_med_fax');
  const phone = localStorage.getItem('sl_med_phone');
  const email = localStorage.getItem('sl_med_email');
  const [loading_, setLoading] = useState(false);
  var domToPdf = require('react-dom-to-pdf-byaasif');
  const currentDate = new Date();
  const formattedDate = currentDate.toDateString();
  const [state , setState] = useState({
    procedure: '',
    patient_fee: '',
    scheme_fee: '',
    doctor_fee: '',
    vat: '',
    procedure1: '',
    patient_fee1: '',
    scheme_fee1: '',
    doctor_fee1: '',
    vat1: '',
    tot_amount: '',
    vat_amount: '',
    tot_vatamount: '',
    patientdue: '',
    claim_amount: '', 
    tot_patient_fee: '', 
    tot_scheme_fee: '', 
    tot_doctor_fee: '', 
    tot_patient_fee1: '', 
    tot_scheme_fee1: '', 
    tot_doctor_fee1: '', 
    discount: 0,
    patientduedisc: '',
})


let totalDoctorFee = 0;
let totalPatientFee = 0;
let totalVatFee = 0;
let totalSchemefee = 0;

const generatePDF = () => {
  var element = document.getElementById('mycontent');
  var options = {
    filename: 'SLMED_Invoice.pdf'
  };
  domToPdf(element, options, function() {
    console.log('done');
  });
};

let provider = ''
const quote_invoice = localStorage.getItem('remitaid_quote_medicalaid');
if (quote_invoice === '1') {
  provider = 'BOMAID';
} else if (quote_invoice === '2') {
  provider = 'BPOMAS';
} else if (quote_invoice === '3') {
  provider = 'PULA';
} else if (quote_invoice === '4') {
  provider = 'BOTSOGO';
} else if (quote_invoice === '5') {
  provider = 'BDF';
}else if (quote_invoice==='6'){
  provider = "BPOMAS HIGH"
}else if(quote_invoice==='7'){
  provider = "BPOMAS STANDARD"
}else if(quote_invoice==='8'){
  provider = "PULA DELUXE"
}else if(quote_invoice==='9'){
  provider = "PULA STANDARD"
}

// const generatePDF = () => {
//   const element = document.getElementById('mycontent');

//   // Use html2canvas to capture the entire page as a canvas
//   html2canvas(element).then(canvas => {
//     // Convert the canvas to a data URL
//     const dataURL = canvas.toDataURL('image/jpeg');

//     // Initialize jsPDF and add the canvas as an image
//     const pdf = new jsPDF('p', 'mm', 'a4');
//     pdf.addImage(dataURL, 'JPEG', 0, 0);

//     // Save the PDF
//     pdf.save('SLMED_Invoice.pdf');
//   });
// };


const goback = (e) => {
  window.location.href = 'newinvoice2'
}

const getdrinfo = async (e) => {
  axios.post(drURL, body, headers2)
  .then((response) => {
    console.log("response received: ", response);
    if(!response){
      setdr(0);
    }else{
    setdr(response.data.data[0]);
    setdr(response.data.data[0]);
    console.log(response.data.data);
    }
  });
};

const [imageUrl, setImageUrl] = useState('');

const downloadImage = () => {
  // const imageUrl = dr_info.logo;
  const imageUrl = 'https://firebasestorage.googleapis.com/v0/b/slmed-fb76b.appspot.com/o/files%2Flogo.png?alt=media&token=ae424af5-d00d-4b9e-ad2a-becc05f87eef';
  setImageUrl(imageUrl);
};



useEffect(() => {
  getdrinfo();
  downloadImage();
}, []); 




const [patient_info, setpatient] = useState([]);
const [code_info, setcode] = useState([]);
const [dr_fee, setfees] = useState([]);
const [dentist_fee, setfdrees] = useState([]);
const [provider_info, setprovider] = useState(0);
const doctor_id = localStorage.getItem("sl_med_id");
const token = localStorage.getItem('sl_med_tokenize');
const myURL = "https://remitaid.starlightsoftware.co.bw/api/fetchpatientbyID";
const codeURL="https://remitaid.starlightsoftware.co.bw/api/fetchcodes";
const providerURL = "https://remitaid.starlightsoftware.co.bw/api/fetchaidsbyID";
const feeURL="https://remitaid.starlightsoftware.co.bw/api/fetchfees";
const drfeesURL = "https://remitaid.starlightsoftware.co.bw/api/fetchdrfees";
const patient_id = localStorage.getItem('slmed_inv_patient_id');

const service_date = formattedDate;
const code = localStorage.getItem('remitaid_code');
const code1 = localStorage.getItem('remitaid_code1');
const code2 = localStorage.getItem('remitaid_code2');
const code3 = localStorage.getItem('remitaid_code3');
const code4 = localStorage.getItem('remitaid_code4');
const code5 = localStorage.getItem('remitaid_code5');
const qty = localStorage.getItem('remitaid_qty');
const qty1 = localStorage.getItem('remitaid_qty1');
const qty2 = localStorage.getItem('remitaid_qty2');
const qty3 = localStorage.getItem('remitaid_qty3');
const qty4 = localStorage.getItem('remitaid_qty4');
const qty5 = localStorage.getItem('remitaid_qty5');
const teeth = localStorage.getItem('remitaid_teeth');
const teeth1 = localStorage.getItem('remitaid_teeth1');
const teeth2 = localStorage.getItem('remitaid_teeth2');
const teeth3 = localStorage.getItem('remitaid_teeth3');
const teeth4 = localStorage.getItem('remitaid_teeth4');
const teeth5 = localStorage.getItem('remitaid_teeth5');
const icd = localStorage.getItem('remitaid_icd');
const icd1 = localStorage.getItem('remitaid_icd1');
const icd2 = localStorage.getItem('remitaid_icd2');
const icd3 = localStorage.getItem('remitaid_icd3');
const icd4 = localStorage.getItem('remitaid_icd4');
const icd5 = localStorage.getItem('remitaid_icd5');



const headers2 = { 
  headers: {
    'AccessToken': JSON.parse(token),
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': "*",
  }
};

  const body = {
    'doctor_id':doctor_id,
    'id':patient_id,
    'accesstoken': JSON.parse(token),
    'provider_id': provider_id
  }

  const getaids = async (e) => {
    axios.post(providerURL, body, headers2)
    .then((response) => {
      console.log("response received: ", response);
      if(!response){
        setprovider(0);
      }else{
      console.log("response received: ", response);
      setprovider(response.data.data[0].provider_name);
      localStorage.setItem('slmed_inv_provider_name', response.data.data[0].provider_name);
      }
    });
  };

  const icdcodes = [icd,icd1,icd2,icd3,icd4,icd5]
  const qtycodes = [qty,qty1,qty2,qty3,qty4,qty5]
  const teethcodes = [teeth,teeth1,teeth2,teeth3,teeth4,teeth5]

  // console.log(icdcodes);
  // console.log(teethcodes);

  const goBack = async (e) => {
    window.location.href = `newquote`;
  }

  
  const coder = {
    accesstoken: JSON.parse(token),
    code1:  code,
    code2:  code1,
    code3:  code2,
    code4:  code3,
    code5:  code4,
    code6:  code5,
    provider_id: provider_id,
    doctor_id: doctor_id
  };

  const getcodeinfo = async (e) => {
    setLoading(true);
    try {
      const response = await axios.post(codeURL, coder, headers2);
      console.log("response received: ", response);
      if (!response.data.data || response.data.data.length === 0) {
        setcode([]); // Update with an empty array or appropriate value
      } else {
        setLoading(true);
        console.log("response received: ", response);
        setcode(response.data.data.codes); // Update the state with response data
        console.log(code_info); // This might not log the updated value immediately
      }
      const response2 = await axios.post(feeURL, coder, headers2);
  
      console.log("response received: ", response2);
  
      if (!response.data.data || response2.data.data.length === 0) {
        setfees(0);
      } else {
        console.log("response received: ", response2.data.data.fees);
        setfees(response2.data.data.fees);
        console.log(dr_fee);
      }

      const response3 = await axios.post(drfeesURL, coder, headers2);
  
      console.log("response received: ", response3);
  
      if (!response3.data.data || response3.data.data.length === 0) {
        setfdrees(0);
      } else {
        console.log("response received: ", response2.data.data.fees);
        setfdrees(response2.data.data.fees);
        console.log(dentist_fee);
      }

    } catch (error) {
      console.error("An error occurred: ", error);
    }
  };



  useEffect(() => {
    getcodeinfo();
    getaids();
    // getfeeinfo();
  }, []);






  if (code == 8101) {
  state.procedure='Full mouth evaluation, charting and treatment planning'
  state.patient_fee = '20.99';
  state.scheme_fee = '188.91';
  state.doctor_fee = '26.40';
  state.tot_patient_fee = Number(state.patient_fee) * Number(qty)
  state.tot_scheme_fee = Number(state.scheme_fee) * Number(qty)
  state.tot_doctor_fee = Number(state.doctor_fee) * Number(qty)
  // console.log(state.tot_patient_fee);
  // console.log( Number(state.patient_fee) * Number(qty));

}
if (code1 == 8101) {
  state.procedure1='Full mouth evaluation, charting and treatment planning'
  state.patient_fee1 = '20.99';
  state.scheme_fee1= '188.91';
  state.doctor_fee1= '26.40';
  state.tot_patient_fee1 = Number(state.patient_fee1) * Number(qty1)
  state.tot_scheme_fee1 = Number(state.scheme_fee1) * Number(qty1)
  state.tot_doctor_fee1 = Number(state.doctor_fee1) *  Number(qty1)

}
if (code == 8102) {
  state.procedure = 'Comprehensive consultation';
  state.patient_fee = '29.54';
  state.scheme_fee = '265.86';
  state.doctor_fee = '0.00';
  state.tot_patient_fee = Number(state.patient_fee) * Number(qty)
  state.tot_scheme_fee = Number(state.scheme_fee) * Number(qty)
  state.tot_doctor_fee = Number(state.doctor_fee) *  Number(qty)

}
if (code1 == 8102) {
  state.procedure1 = 'Comprehensive consultation';
  state.patient_fee1= '29.54';
  state.scheme_fee1= '265.86';
  state.doctor_fee1= '0.00';
  state.tot_patient_fee1 = Number(state.patient_fee1) * Number(qty1)
  state.tot_scheme_fee1 = Number(state.scheme_fee1) * Number(qty1)
  state.tot_doctor_fee1 = Number(state.doctor_fee1) *  Number(qty1)

}
if (code == 8104) {
  state.procedure = 'Examination for a specific problem';
  state.patient_fee = '9.64';
  state.scheme_fee = '86.76';
  state.doctor_fee = '24.50';
  state.tot_patient_fee = Number(state.patient_fee) * Number(qty)
  state.tot_scheme_fee = Number(state.scheme_fee) * Number(qty)
  state.tot_doctor_fee = Number(state.doctor_fee) *  Number(qty)

}
if (code1 == 8104) {
  state.procedure1 = 'Examination for a specific problem';
  state.patient_fee1 = '9.64';
  state.scheme_fee1 = '86.76';
  state.doctor_fee1 = '24.50';
  state.tot_patient_fee1 = Number(state.patient_fee1) * Number(qty1)
  state.tot_scheme_fee1 = Number(state.scheme_fee1) * Number(qty1)
  state.tot_doctor_fee1 = Number(state.doctor_fee1) *  Number(qty1)
}
if (code == 8105) {
  state.procedure = 'Appointment not kept';
  state.patient_fee = '0.00';
  state.scheme_fee= '0.00';
  state.doctor_fee= '236.30';
  state.tot_patient_fee = Number(state.patient_fee) * Number(qty1)
  state.tot_scheme_fee = Number(state.scheme_fee) * Number(qty1)
  state.tot_doctor_fee = Number(state.doctor_fee) *  Number(qty1)

}
if (code1 == 8105) {
  state.procedure1 = 'Appointment not kept';
  state.patient_fee1 = '0.00';
  state.scheme_fee1= '0.00';
  state.doctor_fee1= '236.30';
  state.tot_patient_fee1 = Number(state.patient_fee1) * Number(qty1)
  state.tot_scheme_fee1 = Number(state.scheme_fee1) * Number(qty1)
  state.tot_doctor_fee1 = Number(state.doctor_fee1) *  Number(qty1)

}



const handleChange = (e) => {
  const {id , value} = e.target   
  setState(prevState => ({
      ...prevState,
      [id] : value
  }))
  
} 
  


      return (
<div>
        <div className="header-container fixed-top">
        <Header/>
        </div>
        <div className="main-container" id="container">
        <Sidebar/>



          <div id="content" className="main-content">
        <div className="layout-px-spacing">
          <div className="page-header">
            <div className="page-title">
              <h3>Invoice</h3>
              <button type="submit" onClick={goBack} className="btn btn-danger mt-3" >Go Back</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <button type="submit" onClick={getcodeinfo} className="btn btn-warning mt-3" >COMPUTE</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <button type="submit" onClick={generatePDF} className="btn btn-info mt-3" >DOWNLOAD</button>&nbsp;
              {/* <button type="submit" className="btn btn-success mt-3" >Download as csv</button>&nbsp; */}
              {/* <button type="submit" className="btn btn-info mt-3" >Download as xml</button>&nbsp; */}
              {/* <button type="submit" className="btn btn-danger mt-3" >Send to Medical Scheme</button> */}

            </div>
          </div>
          <div className="row invoice layout-top-spacing">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="app-hamburger-container">
                <div className="hamburger"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-menu chat-menu d-xl-none"><line x1={3} y1={12} x2={21} y2={12} /><line x1={3} y1={6} x2={21} y2={6} /><line x1={3} y1={18} x2={21} y2={18} /></svg></div>
              </div>

              <div className="doc-container">
                <div className="invoice-container">
                  <div className="invoice-inbox">
                    <div className="invoice-header-section">
                      <h4 className="inv-number" />
                      <div className="invoice-action">
                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-printer action-print" data-toggle="tooltip" data-placement="top" data-original-title="Reply"><polyline points="6 9 6 2 18 2 18 9" /><path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2" /><rect x={6} y={14} width={12} height={8} /></svg>
                      </div>
                    </div>

                    <div id="printable-content">
                      <div className="invoice-00001" id='mycontent'>
                        <div className="content-section ">
                        <br/>
                          <br/>
                          <br/>
                          <br/>
                          <div className="row inv--head-section">
                            <div className="col-sm-3 col-12">
                              <h3 className="in-heading">QUOTE</h3>
                            </div>
                            <div className="col-sm-9 col-12 text-sm-right">
                              <div className="company-info">
                                <div className="col-sm-6 align-self-center  text-sm-right order-2">
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row inv--detail-section">
                            <div className="col-sm-7 align-self-center">
                            <br/>
                              <br/>
                              <br/>
                              {/* {imageUrl && <img src={imageUrl} style={{width:'20%'}} alt="Downloaded Image" />} */}
                              {/* <img src={dr_info.logo} style={{width:'20%'}} alt="Doctor Logo"/> */}
                              <br/>
                              <br/>
                              {/* <p className="inv-to">Patient information</p> */}
                            </div>
                            <div className="col-sm-5 align-self-center  text-sm-right order-sm-0 order-1">
                                  <p className="inv-street-addr"><b>Clinic Name: </b>{business_name}</p>
                                  <p className="inv-street-addr"><b>Physical Address: </b>{business_address}</p>
                                  <p className="inv-street-addr"><b>Postal Address: </b>{mypostal_address}</p>
                                  <p className="inv-street-addr"><b>Tel:</b> {phone}</p>
                                  <p className="inv-street-addr"><b>Fax: </b>{myfax}</p>
                                  <p className="inv-street-addr"><b>Email: </b>{email}</p>
                                  <p className="inv-street-addr"><b>VAT: </b>{myvat_id}</p>
                            </div>
                            <div className="col-sm-7 align-self-center">
                            <br/>
                              <br/>
                              <br/>
                              <br/>
                              {/* <p className="inv-to">Patient information</p>
                              <p className="inv-customer-name">Patient File No: {}</p>
                              <p className="inv-street-addr">Patient Name: {}</p>
                              <p className="inv-email-address">Date Of Birth: {}</p>
                              <p className="inv-email-address">Cell:{}</p>
                              <p className="inv-email-address">Service Date:: {}</p> */}
                              <br/>
                              <p className="inv-customer-name">Diagnosis</p>
                              <p className="inv-street-addr">{icd}</p>
                              <p className="inv-street-addr">{icd1}</p>
                              <p className="inv-street-addr">{icd2}</p> 
                              <p className="inv-street-addr">{icd3}</p>
                              <p className="inv-street-addr">{icd4}</p>
                              <p className="inv-street-addr">{icd5}</p>
                              <br/>
                              <br/>
                              <br/>
                            </div>
                            <div className="col-sm-5 align-self-center  text-sm-right order-2">
                              {/* <p className="inv-customer-name">Principal Member Name :  {patient_info.principal_member_name}</p> */}
                              <p className="inv-street-addr">Medical Aid Scheme: {provider}</p>
                              {/* <p className="inv-street-addr">Membership No: {patient_info.member_number}</p> */}
                              {/* <p className="inv-street-addr">Relationship To Member: {patient_info.relationsip}</p> */}
                              {/* <p className="inv-street-addr">Tel:</p> */}
                            </div>
                          </div>
                          <div className="row inv--product-table-section">
                            <div className="col-12">
                              <div className="table-responsive">
                             
                              <table className="table">
                                  <thead className>
                                    <tr>
                                      <th scope="col">Code</th>
                                      <th scope="col">ICD - 10</th>
                                      <th scope="col">Service</th>
                                      <th className="" scope="col">Teeth</th>
                                      <th className="" scope="col">Qty</th> 
                                      <th className="" scope="col">Scheme Fee</th>
                                      <th className="" scope="col">Doctor Fee</th>
                                      <th className="" scope="col">Patient Due</th>
                                      <th className="" scope="col">Vat</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                  {Array.isArray(code_info) && code_info.length > 0 ? (
                                    code_info.map((info, index) => {
                                      const myscheme_fee = (Number(dr_fee[index]?.fee) * Number(qty)) * 0.9;
                                      const mydoctor_fee = (Number(dentist_fee[index]?.total_fee) - (Number(dr_fee[index]?.fee)));
                                      const mypatient_fee = ((Number(dr_fee[index]?.fee) * Number(qty)) * 0.1);
                                      const myvat_fee = (((Number(dr_fee[index]?.fee) * Number(qty)) * 0.9) + ((Number(dr_fee[index]?.fee) * Number(qty)) * 0.1) + (Number(dentist_fee[index]?.total_fee) - (Number(dr_fee[index]?.fee)))) * 0.14;

                                      totalDoctorFee += mydoctor_fee;
                                      totalPatientFee += mypatient_fee;
                                      totalVatFee += myvat_fee;
                                      totalSchemefee += myscheme_fee;

                                      return (
                                        <tr key={index}>
                                          <td>{info.coder}</td>
                                          <td>{icdcodes[index]}</td>
                                          <td className="">{info.description}</td>
                                          <td className="">{teethcodes[index]}</td>
                                          <td className="">{qtycodes[index]}</td>
                                          <td className=""> {myscheme_fee.toFixed(2)}</td>
                                          <td className=""> {mydoctor_fee.toFixed(2)}</td>
                                          <td className="">{mypatient_fee.toFixed(2)}</td>
                                          <td className="">  {myvat_fee.toFixed(2)}</td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr>
                                      <td colSpan="8">No data available, click the "COMPUTE" button.</td>
                                    </tr>
                                  )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col-sm-5 col-12 order-sm-0 order-1">
                            </div>
                            <div className="col-sm-7 col-12 order-sm-1 order-0">
                              <div className="inv--total-amounts text-sm-right">
                                <div className="row">
                                <div className="col-sm-8 col-7">
                                    <p className>Discount (%): </p>
                                  </div>
                                  <div className="col-sm-4 col-5">
                                    <input type="number"  value={state.discount} id="discount" name="discount" onChange={handleChange} className="form-control"  />
                                  </div>
                                  <div className="col-sm-8 col-7">
                                    <p className>Total Amount: </p>
                                  </div>
                                  <div className="col-sm-4 col-5">
                                    <p className> {(Number(totalDoctorFee)+Number(totalPatientFee)+Number(totalSchemefee)).toFixed(2)}</p>
                                  </div>
                                  <div className="col-sm-8 col-7">
                                    <p className>Vat Amount: </p>
                                  </div>
                                  <div className="col-sm-4 col-5">
                                    <p className>{(totalVatFee).toFixed(2)}</p>
                                  </div>
                                  <div className="col-sm-8 col-7">
                                    <p className=" discount-rate">Total (Vat Inclusive): </p>
                                  </div>
                                  <div className="col-sm-4 col-5">
                                    <p className> {(Number(totalDoctorFee)+Number(totalPatientFee)+Number(totalSchemefee) + Number(totalVatFee)).toFixed(2)}</p>
                                  </div>
                                  <div className="col-sm-8 col-7">
                                    <p className>Total (Due From Patient): </p>
                                  </div>
                                  <div className="col-sm-4 col-5">
                                    <p className> {(Number(totalPatientFee)+Number(totalDoctorFee)+Number(totalVatFee)).toFixed(2)}</p>
                                  </div>
                                  <div className="col-sm-8 col-7">
                                    <p className>Total (Due From Patient Discounted): </p>
                                  </div>
                                  <div className="col-sm-4 col-5">
                                    <p className> {((Number(totalPatientFee)+Number(totalDoctorFee)+Number(totalVatFee)) - (Number(totalPatientFee)+Number(totalDoctorFee)+Number(totalVatFee)) * Number(state.discount)/100).toFixed(2)}</p>
                                  </div>
                                  
                                  <div className="col-sm-8 col-7">
                                    <p className>Amount (Claimed From Medical Aid): </p>
                                  </div>
                                  <div className="col-sm-4 col-5">
                                    <p className> {(totalSchemefee).toFixed(2)}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <br/>
                          <br/>
                          <br/> <br/>
                          <br/>
                          <br/>
                          <div className="row">
                            <div className="col-6">
                              <div className="col-sm-7 align-self-center">
                                <p className="inv-to">Patient's Signature: _________</p>
                              </div>
                              <div className="col-sm-8 col-7">
                                <p className>Date: ____________ </p>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="col-sm-7 align-self-center">
                                <p className="inv-to">Doctor's Signature: ___________</p>
                              </div>
                              <div className="col-sm-8 col-7">
                                <p className>Date: ____________ </p>
                              </div>
                            </div>
                          </div>
                          <br />
                          <br />
                          <b>Dr. {fname} Practise No: {practise_number}</b>
                          <br/>
                          <br/>
                          <br/>
                          <br />
                          <br />
                          <br />
                          <br />
                          <small style={{ position: "absolute", bottom: "10", width: "100%", textAlign: "left" }}>
                              Powered by Starlight Software Solutions (PTY) LTD - info@starlightsoftware.co.bw
                            </small>
                            <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                        </div>
                      </div> 
                    </div>
                  </div>
                  <div className="inv--thankYou">
                    <div className="row">
                      <div className="col-sm-12 col-12">
                        <p className>Developed by Starlight Software Solutions (Pty) Ltd.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       <Footer/>
      </div> 
        </div>
        </div>
        
      
               
        );
 
  };

export default Viewquote;
