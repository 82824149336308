import React from "react";
import Layout from './layout';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import SignIn1 from './component/Auth/signin1';
import SignIn2 from './component/Auth/signin2';
import Forgot from './component/Auth/forgot';
import Register from './component/Auth/register';
import Newpatient from './component/patients/newpatient';
import Editpatient from './component/patients/editpatient';
import Consultation from './component/patients/consultations';
import Viewconsultation from './component/patients/viewconsultation';
import logout from './component/dashboard/logout';
import Newinvoice from './component/Invoice/newinvoice';
import Dashboard from './component/dashboard/dashboard';
import Newquote from './component/Invoice/newquote';
import Verify from './component/Auth/verify';
import Viewquote from './component/Invoice/viewquote';
import Newinvoice2 from './component/Invoice/newinvoice2';
import Allinvoice from './component/Invoice/allinvoice';
import Allpatient from './component/patients/allpatient';
import Newpassword from './component/users/newpassword';
import Reporttable from './component/dashboard/reporttable';
import Updatebusiness from './component/patients/updatebusiness';
import Updateprofile from './component/users/updateprofile';
import Newsubuser from './component/users/newsubuser';
import Viewinvoice from './component/Invoice/viewinvoice';
import Report from './component/dashboard/report';
import Viewinvoice2 from './component/Invoice/viewinvoice2';
import Uploadpatient from './component/patients/uploadpatient';
import Changepassword from './component/users/changepassword';
import Profile from './component/users/profile';

export default function BasicExample() {
  return (
    <Router>
      <div>
       

        <Switch>
        <Layout>
          <Route exact path="/" component={SignIn1}/>
          <Route path="/signin" component={SignIn1}/>
          <Route path="/verify" component={Verify}/>
          <Route path="/signin2" component={SignIn2}/>
          <Route path="/forgot" component={Forgot}/>
          <Route path="/registerfromadmin" component={Register}/>
          <Route path="/Profile" component={Profile}/>
          <Route path="/Editpatient" component={Editpatient}/>
          <Route path="/newinvoice" component={Newinvoice}/>
          <Route path="/consultation" component={Consultation}/>
          <Route path="/viewconsultation" component={Viewconsultation}/>
          <Route path="/dashboard" component={Dashboard}/>
          <Route path="/newquote" component={Newquote}/>
          <Route path="/viewquote" component={Viewquote}/>
          <Route path="/allpatient" component={Allpatient}/>
          <Route path="/newpassword" component={Newpassword}/>
          <Route path="/viewinvoice2" component={Viewinvoice2}/>
          <Route path="/report" component={Report}/>
          <Route path="/reporttable" component={Reporttable}/>
          <Route path="/newinvoice2" component={Newinvoice2}/>
          <Route path="/allinvoice" component={Allinvoice}/>
          <Route path="/viewinvoice" component={Viewinvoice}/>
          <Route path="/uploadpatient" component={Uploadpatient}/>
          <Route path="/updatebusiness" component={Updatebusiness}/>
          <Route path="/newsubuser" component={Newsubuser}/>
          <Route path="/updateprofile" component={Updateprofile}/>
          <Route path="/changepassword" component={Changepassword}/>
          <Route path="/newpatient" component={Newpatient}/>
          <Route path="/logout" component={logout}/>
         </Layout>
            
          
        </Switch>
      </div>
    </Router>
  );
}

// You can think of these components as "pages"
// in your app.

function Home() {
  return (
    <div>
      <h2>Home</h2>
    </div>
  );
}

function About() {
  return (
    <div>
      <h2>About</h2>
    </div>
  );
}


