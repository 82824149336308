import React, {useState,useEffect} from 'react';
import axios from "axios";
import dayjs from "dayjs";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom';
import Header from '../../component/layout/header';
import Sidebar from '../../component/layout/sidebar';
import Footer from '../../component/layout/footer';


function Newsubuser (){
  if (!localStorage.getItem('sl_med_tokenize')) {
    console.log('no auth token set');
    window.location.href="/";
  } else {
        console.log('good token. Log in.')
    }
  const history = useHistory();
  const MySwal = withReactContent(Swal);
  const business_name = localStorage.getItem('sl_med_businessname');
  const fname = localStorage.getItem('sl_med_fname');
  const [state , setState] = useState({
    name: '',
    email: '',
    amount: '',
    file: null,
    base64URL: ''
})

      return (
<div>
<div className="header-container fixed-top">
        <Header/>
        </div>
        <div className="main-container" id="container">
        <Sidebar/>



          <div id="content" className="main-content">
        <div className="layout-px-spacing">
          <div className="page-header">
            <div className="page-title">
              <h3>New subuser</h3>
            </div>
          </div>
          <div className="card row layout-top-spacing">
            <div id="flFormsGrid" className="col-lg-12 layout-spacing">
              <div className="widget-content widget-content-area">
                <div className="row">
                  <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                    <h4>Create New subuser.</h4>
                  </div>                                                                        
                </div>
                <p style={{color: 'red'}}>This feature is currently unavailable.</p>
                <form>
                  <div className="form-row mb-12">
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Name</label>
                      <input type="text" disabled className="form-control" />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Email</label>
                      <input type="text" disabled  className="form-control" />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Phone</label>
                      <input type="number" disabled  className="form-control" id="inputPassword4" />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputState">Select Role</label>
                      <select id="inputState" disabled className="form-control">
                        <option>Choose</option>
                        <option selected>Admin</option>
                        <option value='Sub-admin'>Sub-admin</option>
                        <option value='Account'>Accounts</option>
                      </select>
                    </div>
                  </div></form>
                <a type="submit" className="btn btn-primary mt-3" style={{float: 'right'}} >Submit</a>
              </div>
            </div>
          </div>
         <Footer/>
        </div>
      </div>
        </div>
        </div>
        
      
               
        );
 
  };

export default Newsubuser;
