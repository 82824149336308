const Codes  = [
{value: '8101', label:  '8101'},
{value: '8102', label:  '8102'},
{value: '8104', label:  '8104'},
{value: '8105', label:  '8105'},
{value: '8106', label:  '8106'},
{value: '8107', label:  '8107'},
{value: '8108', label:  '8108'},
{value: '8109', label:  '8109'},
{value: '8110', label:  '8110'},
{value: '8113', label:  '8113'},
{value: '8114', label:  '8114'},
{value: '8115', label:  '8115'},
{value: '8117', label:  '8117'},
{value: '8119', label:  '8119'},
{value: '8121', label:  '8121'},
{value: '8122', label:  '8122'},
{value: '8123', label:  '8123'},
{value: '8124', label:  '8124'},
{value: '8129', label:  '8129'},
{value: '8131', label:  '8131'},
{value: '8132', label:  '8132'},
{value: '8133', label:  '8133'},
{value: '8135', label:  '8135'},
{value: '8135.' , label: '8135.'},
{value: '8136' , label: '8136'},
{value: '8137' , label: '8137'},
{value: '8140' , label: '8140'},
{value: '8140.', value: '8140.'},
{value: '8140..', value: '8140..'},
{value: '8142' , label: '8142'},
{value: '8143' , label: '8143'},
{value: '8144' , label: '8144'},
{value: '8145' , label: '8145'},
{value: '8147' , label: '8147'},
{value: '8151' , label: '8151'},
{value: '8153' , label: '8153'},
{value: '8155' , label: '8155'},
{value: '8157' , label: '8157'},
{value: '8158' , label: '8158'},
{value: '8159' , label: '8159'},
{value: '8159.' , label: '8159.'},
{value: '8161' , label: '8161'},
{value: '8163' , label: '8163'},
{value: '8165' , label: '8165'},
{value: '8167' , label: '8167'},
{value: '8169' , label: '8169'},
{value: '8170' , label: '8170'},
{value: '8171' , label: '8171'},
{value: '8173' , label: '8173'},
{value: '8175' , label: '8175'},
{value: '8176' , label: '8176'},
{value: '8177' , label: '8177'},
{value: '8178' , label: '8178'},
{value: '8179' , label: '8179'},
{value: '8180' , label: '8180'},
{value: '8182' , label: '8182'},
{value: '8185' , label: '8185'},
{value: '8188' , label: '8188'},
{value: '8192' , label: '8192'},
{value: '8193' , label: '8193'},
{value: '8194' , label: '8194'},
{value: '8195' , label: '8195'},
{value: '8196' , label: '8196'},
{value: '8197' , label: '8197'},
{value: '8198' , label: '8198'},
{value: '8198.', label: '8198.'},
{value: '8199', label: '8199'}, 
{value: '8199.', label: '8199.'}, 
{value: '8200', label: '8200'}, 
{value: '8200.', label: '8200.'},
{value: '8201', label: '8201'}, 
{value: '8201.', label: '8201.'}, 
{value: '8201..', label: '8201..'},
{value: '8202', label: '8202'},
{value: '8209', label: '8209'},
{value: '8210', label: '8210'},
{value: '8211', label: '8211'},
{value: '8212', label: '8212'},
{value: '8213', label: '8213'},
{value: '8214', label: '8214'},
{value: '8215', label: '8215'},
{value: '8220', label: '8220'},
{value: '8221', label: '8221'},
{value: '8222', label: '8222'},
{value: '8223', label: '8223'},
{value: '8225', label: '8225'},
{value: '8227', label: '8227'},
{value: '8229', label: '8229'},
{value: '8231', label: '8231'},
{value: '8232', label: '8232'},
{value: '8233', label: '8233'},
{value: '8234', label: '8234'},
{value: '8235', label: '8235'},
{value: '8236', label: '8236'},
{value: '8237', label: '8237'},
{value: '8238', label: '8238'},
{value: '8239', label: '8239'},
{value: '8240', label: '8240'},
{value: '8241', label: '8241'},
{value: '8243', label: '8243'},
{value: '8251', label: '8251'},
{value: '8253', label: '8253'},
{value: '8255', label: '8255'},
{value: '8257', label: '8257'},
{value: '8259', label: '8259'},
{value: '8261', label: '8261'},
{value: '8263', label: '8263'},
{value: '8265', label: '8265'},
{value: '8267', label: '8267'},
{value: '8269', label: '8269'},
{value: '8270', label: '8270'},
{value: '8271', label: '8271'},
{value: '8273', label: '8273'},
{value: '8275', label: '8275'},
{value: '8277', label: '8277'},
{value: '8279', label: '8279'},
{value: '8281', label: '8281'},
{value: '8301', label: '8301'},
{value: '8303', label: '8303'},
{value: '8304', label: '8304'},
{value: '8305', label: '8305'},
{value: '8306', label: '8306'},
{value: '8306.', label: '8306.'},
{value: '8307' , label: '8307'},
{value: '8308' , label: '8308'},
{value: '8309' , label: '8309'},
{value: '8310' , label: '8310'},
{value: '8311' , label: '8311'},
{value: '8325' , label: '8325'},
{value: '8327' , label: '8327'},
{value: '8328' , label: '8328'},
{value: '8329' , label: '8329'},
{value: '8330' , label: '8330'},
{value: '8332' , label: '8332'},
{value: '8333' , label: '8333'},
{value: '8334' , label: '8334'},
{value: '8335' , label: '8335'},
{value: '8336' , label: '8336'},
{value: '8337' , label: '8337'},
{value: '8338' , label: '8338'},
{value: '8339' , label: '8339'},
{value: '8340' , label: '8340'},
{value: '8341' , label: '8341'},
{value: '8342' , label: '8342'},
{value: '8343' , label: '8343'},
{value: '8344' , label: '8344'},
{value: '8345' , label: '8345'},
{value: '8346' , label: '8346'},
{value: '8347' , label: '8347'},
{value: '8348' , label: '8348'},
{value: '8349' , label: '8349'},
{value: '8351' , label: '8351'},
{value: '8352' , label: '8352'},
{value: '8353' , label: '8353'},
{value: '8354' , label: '8354'},
{value: '8355' , label: '8355'},
{value: '8356' , label: '8356'},
{value: '8357' , label: '8357'},
{value: '8358' , label: '8358'},
{value: '8359' , label: '8359'},
{value: '8360' , label: '8360'},
{value: '8361' , label: '8361'},
{value: '8362' , label: '8362'},
{value: '8363' , label: '8363'},
{value: '8364' , label: '8364'},
{value: '8365' , label: '8365'},
{value: '8366' , label: '8366'},
{value: '8367' , label: '8367'},
{value: '8368' , label: '8368'},
{value: '8369' , label: '8369'},
{value: '8370' , label: '8370'},
{value: '8371' , label: '8371'},
{value: '8372' , label: '8372'},
{value: '8373' , label: '8373'},
{value: '8374' , label: '8374'},
{value: '8376' , label: '8376'},
{value: '8391' , label: '8391'},
{value: '8391.', label: '8391.'},
{value: '8393', label: '8393'}, 
{value: '8395', label: '8395'}, 
{value: '8396', label: '8396'}, 
{value: '8397', label: '8397'}, 
{value: '8398', label: '8398'}, 
{value: '8401', label: '8401'}, 
{value: '8403', label: '8403'}, 
{value: '8405', label: '8405'}, 
{value: '8407', label: '8407'}, 
{value: '8409', label: '8409'}, 
{value: '8410', label: '8410'}, 
{value: '8411', label: '8411'}, 
{value: '8413', label: '8413'}, 
{value: '8414', label: '8414'}, 
{value: '8420', label: '8420'}, 
{value: '8422', label: '8422'}, 
{value: '8424', label: '8424'}, 
{value: '8523', label: '8523'}, 
{value: '8560', label: '8560'}, 
{value: '8594', label: '8594'}, 
{value: '8597', label: '8597'}, 
{value: '8599', label: '8599'}, 
{value: '8811', label: '8811'}, 
{value: '8849', label: '8849'}, 
{value: '8862', label: '8862'}, 
{value: '9011', label: '9011'}, 
{value: '9099', label: '9099'}, 
{value: '80000', label: '80000'},
{value: '80000.', label: '80000.'},
{value: '8007', label: '8007'},
{value: '8000', label: '8000'},
]
export default Codes;




 
 


